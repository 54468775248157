import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'rd-base-template',
	templateUrl: './base-template.component.html'
})
export class BaseTemplateComponent implements OnInit {
	constructor() {}

	@Input()
	title: string;

	@Input()
	breadcrumbs: any;

	ngOnInit() {}
}
