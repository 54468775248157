<section class="body">
	<div class="body__title" *ngIf="title">
		<div class="container">
			<div class="breadcrumbs">
				<a routerLink="/">Home</a>
				<ng-container *ngFor="let item of breadcrumbs; last as isLast">
					<a *ngIf="!isLast" [routerLink]="item.url" >{{ item.nav_title ? item.nav_title : item.title }}</a>
					<span *ngIf="isLast">{{ item.nav_title ? item.nav_title : item.title }}</span>
				</ng-container>
			</div>
			<h1>{{ title }}</h1>
		</div>
	</div>
	<div class="container content">
		<ng-content></ng-content>
	</div>
</section>