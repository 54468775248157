import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Observable } from 'rxjs';
import { Deserialize } from 'cerialize';
import { get } from 'lodash';

import { CmsEntryResult } from '@app/cms/results/cms-entry-result';
import { CmsEntryResultHomepage } from '@app/cms/results/cms-entry-result-homepage';
import { CmsEntryResultAbsLanding } from '@app/cms/results/cms-entry-result-abs-landing';
import { CmsEntryResultAbsList } from '@app/cms/results/cms-entry-result-abs-list';
import { CmsEntryResultAbsSummary } from '@app/cms/results/cms-entry-result-abs-summary';
import { CmsEntryResultAbsSubmit } from '@app/cms/results/cms-entry-result-abs-submit';
import { CmsEntryResultFunnelLanding } from '@app/cms/results/cms-entry-result-funnel-landing';
import { CmsEntryResultFunnelSummary } from '@app/cms/results/cms-entry-result-funnel-summary';
import { CmsEntryResultFunnelReport } from '@app/cms/results/cms-entry-result-funnel-report';
import { CmsEntryResultReportHistory } from '@app/cms/results/cms-entry-result-report-history';
import { CmsEntryResultAdminAbsTool } from '@app/cms/results/cms-entry-result-admin-abs-tool';
import { CmsEntryResult404 } from '@app/cms/results/cms-entry-result-404';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class CmsEntryService {
	constructor(private http: HttpClient, private sanitizer: DomSanitizer, private router: Router) {
		this.navigationSource.next([]);
		this.navigationSourceIncludingHidden.next([]);
	}

	// private member that triggers events to the public variable
	private navigationSource = new Subject<any>();
	private navigationSourceIncludingHidden = new Subject<any>();

	// Observable navigation stream that can be subscribed to
	public navigation$: Observable<any> = this.navigationSource.asObservable();
	public navigationIncludingHidden$: Observable<any> = this.navigationSourceIncludingHidden.asObservable();

	getEntryByUrl(url: string, queryParams: Params = {}): Promise<CmsEntryResult> {
		return this.http
			.get(`${environment.apiUrl}/crud/content/entry/by/url`, {
				params: {
					url,
					...queryParams
				}
			})
			.toPromise()
			.then(res => {
				return this.deserializeByResponse(res);
			})
			.catch(res => {
				const errorType = get(res, 'error.error.name');
				console.log('CMS entry error', errorType);
				if (res.status === 404) {
					return Deserialize(res, CmsEntryResult404);
				} else {
					switch (errorType) {
						case 'NoAccessError':
							this.router.navigate(['login'], {
								queryParams: { back_location: window.location.pathname }
							});
							break;

						default:
							// TODO: we need to handle other status codes better
							return this.deserializeByResponse(res);
					}
				}
			})
			.then((entry: CmsEntryResult) => {
				return entry.sanitize(this.sanitizer);
			});
	}

	deserializeByResponse(res: any): CmsEntryResult {
		const template = get(res, 'cms_template.template');
		switch (template) {
			case 'index':
				return Deserialize(res, CmsEntryResultHomepage);
			case 'subpage':
			case 'my-account':
				return Deserialize(res, CmsEntryResult);
			case 'abs-landing':
				return Deserialize(res, CmsEntryResultAbsLanding);
			case 'abs-list':
				return Deserialize(res, CmsEntryResultAbsList);
			case 'abs-summary':
				return Deserialize(res, CmsEntryResultAbsSummary);
			case 'abs-submit':
			case 'abs-submit-part-a':
			case 'abs-submit-part-b':
			case 'abs-submit-part-c':
			case 'abs-submit-part-d':
				return Deserialize(res, CmsEntryResultAbsSubmit);
			case 'funnel-landing':
			case 'watchlist':
				return Deserialize(res, CmsEntryResultFunnelLanding);
			case 'funnel-summary':
				return Deserialize(res, CmsEntryResultFunnelSummary);
			case 'funnel-report':
				return Deserialize(res, CmsEntryResultFunnelReport);
			case 'report-history':
				return Deserialize(res, CmsEntryResultReportHistory);
			case 'admin-abs-tool':
				return Deserialize(res, CmsEntryResultAdminAbsTool);
			default:
				return Deserialize(res, CmsEntryResult);
		}
	}

	getSiteNavigation(): Promise<any> {
		return this.http
			.get<any>(`${environment.apiUrl}/crud/content/entry/list/site-navigation`)
			.toPromise()
			.then(items => {
				this.navigationSource.next(items);
				return items;
			});
	}

	getSiteNavigationIncludingHidden(): Promise<any> {
		return this.http
			.get<any>(`${environment.apiUrl}/crud/content/entry/list/site-navigation-including-hidden`)
			.toPromise()
			.then(items => {
				this.navigationSourceIncludingHidden.next(items);
				return items;
			});
	}

	getSiteUrlMap(): Promise<string[]> {
		// @ts-ignore
		return this.http.get<string[]>(`${environment.apiUrl}/crud/cms/url-map`).toPromise();
	}
}
