import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { get } from 'lodash';

import { CmsEntryResultAbsSubmit } from '@app/cms/results/cms-entry-result-abs-submit';
import { Account } from '@app/account/account';
import { AbsSubmitPartHelper } from './abs-submit-part-helper';
import * as moment from "moment/moment";

@Component({
	selector: 'rd-cms-entry-abs-submit-part-c',
	templateUrl: './cms-entry-abs-submit-part-c.component.html'
})
export class CmsEntryAbsSubmitPartCComponent extends AbsSubmitPartHelper implements OnInit {
	@Input()
	cmsResult: CmsEntryResultAbsSubmit;

	@Input()
	sessionUser: Account;

	ngOnInit() {
		this.orgId = this.cmsResult.orgId || this.sessionUser.orgId;

		const numberValidators = [Validators.max(8000000), Validators.maxLength(5), Validators.pattern(/^[0-9]*$/)];
		const decimalValidators = [
			Validators.max(99.99),
			Validators.maxLength(5),
			Validators.pattern(/^\d+(?:\.\d{1,2})?$/)
		];
		this.form = this.formBuilder.group({
			c_pro_staff: [this.cmsResult.c_pro_staff, decimalValidators],
			c_pro_staff_avg_sal: { value: this.cmsResult.c_pro_staff_avg_sal, disabled: true },
			c_pro_staff_direct_recruiting: [this.cmsResult.c_pro_staff_direct_recruiting, decimalValidators],
			c_support_staff: [this.cmsResult.c_support_staff, decimalValidators],
			c_support_staff_avg_sal: { value: this.cmsResult.c_support_staff_avg_sal, disabled: true },
			c_stud_labor: [this.cmsResult.c_stud_labor, numberValidators],
			c_stud_labor_avg_wage: { value: '', disabled: true },
			c_adm_cnslr_start_sal: [this.cmsResult.c_adm_cnslr_start_sal, numberValidators],
			c_sr_cnslr_pos: [this.cmsResult.c_sr_cnslr_pos, numberValidators],
			c_sr_cnslr_sal: [this.cmsResult.c_sr_cnslr_sal, numberValidators],
			c_asst_dir_sal: [this.cmsResult.c_asst_dir_sal, numberValidators],
			c_asst_dir_yrs_adm: [this.cmsResult.c_asst_dir_yrs_adm, numberValidators],
			c_asst_dir_yrs_pos: [this.cmsResult.c_asst_dir_yrs_pos, numberValidators],
			c_assoc_dir_sal: [this.cmsResult.c_assoc_dir_sal, numberValidators],
			c_assoc_dir_yrs_adm: [this.cmsResult.c_assoc_dir_yrs_adm, numberValidators],
			c_assoc_dir_yrs_pos: [this.cmsResult.c_assoc_dir_yrs_pos, numberValidators],
			c_dir_adm_sal: [this.cmsResult.c_dir_adm_sal, numberValidators],
			c_dir_adm_yrs_adm: [this.cmsResult.c_dir_adm_yrs_adm, numberValidators],
			c_dir_adm_yrs_pos: [this.cmsResult.c_dir_adm_yrs_pos, numberValidators],
			c_vpdean_title: [this.cmsResult.c_vpdean_title],
			c_vpdean_sal: [this.cmsResult.c_vpdean_sal, numberValidators],
			c_vpdean_yrs_adm: [this.cmsResult.c_vpdean_yrs_adm, numberValidators],
			c_vpdean_yrs_pos: [this.cmsResult.c_vpdean_yrs_pos, numberValidators],
			c_min_edu_adm_cnslr: [this.cmsResult.c_min_edu_adm_cnslr],
			c_min_edu_asst_dir: [this.cmsResult.c_min_edu_asst_dir],
			c_min_edu_dir: [this.cmsResult.c_min_edu_dir],
			c_min_edu_vpdean: [this.cmsResult.c_min_edu_vpdean]
		});

		if (!this.hasWritePermission()) {
			this.form.disable();
		}

		this.updateTotals('c_pro_staff_avg_sal', ['c_pro_staff'], (totals: number) => {
			if (!this.cmsResult.a_prof_salaries) {
				return 0;
			}
			return Math.round(+this.cmsResult.a_prof_salaries / parseFloat(this.form.controls.c_pro_staff.value));
		});
		this.updateTotals('c_support_staff_avg_sal', ['c_support_staff'], (totals: number) => {
			if (!this.cmsResult.a_support_salaries) {
				return 0;
			}
			totals = Math.round(+this.cmsResult.a_support_salaries / parseFloat(this.form.controls.c_support_staff.value));
			return isNaN(totals) ? 0 : totals;
		});
		this.updateTotals('c_stud_labor_avg_wage', ['c_stud_labor'], (totals: number) => {
			if (!this.cmsResult.a_student_wages) {
				return 0;
			}
			totals = +this.cmsResult.a_student_wages / (30 * parseFloat(this.form.controls.c_stud_labor.value));
			return isNaN(totals) ? 0 : totals;
		});

		this.listenForFormChanges();
	}

	updateTotals(updateField: string, fields: string[], tweakTotal: Function | null = null): void {
		const _updateTotals = () => {
			let total = 0;
			fields.forEach((field: string) => {
				total += parseInt(this.form.controls[field].value || 0);
			});
			if (tweakTotal) {
				total = tweakTotal(total);
			}
			this.form.controls[updateField].setValue(total);
		};
		fields.forEach((field: string) => {
			this.form.controls[field].valueChanges.subscribe(_updateTotals);
		});
		_updateTotals(); // trigger an update just in case legacy data wasn't saved properly
	}

	async save(): Promise<any> {
		if (this.form!.invalid) {
			this.error = { message: 'Please fill out all required fields, fix any errors and try again.' };
			return;
		}
		if (this.isSubmitting) {
			return;
		}

		const data = this.form.getRawValue();
		if (!this.sessionUser.isAdmin) {
			data.status = 0; // status should always be 0 unless an admin is saving, in which case we keep the status unchanged
		}
		data.naccap_abs_surveys_id = this.cmsResult.report.id;
		data.naccap_orgs_id = this.orgId;
		data.user_id = this.sessionUser.userId;
		data.c_last_user = this.sessionUser.userId;
		data.c_last_update = moment().format('YYYY-MM-DD HH:mm:ss');
		const entryId = this.cmsResult.entryId;
		if (entryId) {
			data.id = entryId;
		}
		this.isSubmitting = true;
		this.error = {};
		try {
			if (data.id) {
				await this.absEntryService.update(data);
			} else {
				const result = await this.absEntryService.create(data);
				this.form.patchValue({ id: result.insertId });
			}
			this.isSaved = true;
			this.onSaveSuccess();
		} catch (err) {
			if (err && typeof err === 'object' && 'error' in err) {
				const errorObject = err as { error: { error: string } };
				this.error = errorObject.error.error;
			}
		}
		this.isSubmitting = false;
		this.cmsResult.existingStatus = data.status || this.cmsResult.existingStatus || 0;
		setTimeout(() => {
			const url = get(this.cmsResult.urls, 'abs-submit-part-d');
			this.router.navigate([url], { queryParamsHandling: 'merge' });
		}, 2000);
	}

	hasWritePermission() {
		return (
			this.sessionUser.isAdmin ||
			(this.cmsResult.existingStatus === 0 &&
				this.sessionUser.isOrgSuperuser &&
				this.sessionUser.orgId === this.getOrgId())
		);
	}

	hasReadPermission() {
		return this.cmsResult.isVerifiedToViewPartC(this.sessionUser, this.getOrgId());
	}
}
