<hr class="hr hr--shadow">
<div class="grid__row">
	<div class="grid__col grid__col--6">
		<h3>{{ title }}</h3>
		<div class="table--responsive">
			<table class="table table--striped" id="data-{{ field }}">
				<thead>
					<tr>
						<th>Result</th>
						<th><abbr title="Number of schools reporting this result compared to the previous year-to-date">#</abbr></th>
						<th><abbr title="Percentage of schools reporting this result">% of Total</abbr></th>
						<th><abbr title="Minimum and maximum discrete values for this result">Range</abbr></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th><abbr title="Schools reporting more {{ type }} to-date than the previous year">More</abbr></th>
						<td>{{ cmsResult.summaryData[field+'_more'] }}</td>
						<td>{{ (cmsResult.summaryData[field+'_more'] / cmsResult.summaryData.responses * 100)|number:'0.2-2' }}%</td>
						<td>{{ cmsResult.summaryData[field+'_more_min'] }} to {{ cmsResult.summaryData[field+'_more_max'] }}</td>
					</tr>
					<tr>
						<th><abbr title="Schools reporting the same number of {{ type }} to-date than the previous year">Even</abbr></th>
						<td>{{ cmsResult.summaryData[field+'_even'] }}</td>
						<td>{{ (cmsResult.summaryData[field+'_even'] / cmsResult.summaryData.responses * 100)|number:'0.2-2' }}%</td>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<th><abbr title="Schools reporting fewer {{ type }} to-date than the previous year">Fewer</abbr></th>
						<td>{{ cmsResult.summaryData[field+'_fewer'] }}</td>
						<td>{{ (cmsResult.summaryData[field+'_fewer'] / cmsResult.summaryData.responses * 100)|number:'0.2-2' }}%</td>
						<td>{{ cmsResult.summaryData[field+'_fewer_min'] }} to {{ cmsResult.summaryData[field+'_fewer_max'] }}</td>
					</tr>
					<tr>
						<th><abbr title="Schools that did not report previous year data">No Comp.</abbr></th>
						<td>{{ cmsResult.summaryData[field+'_nc'] }}</td>
						<td>{{ (cmsResult.summaryData[field+'_nc'] / cmsResult.summaryData.responses * 100)|number:'0.2-2' }}%</td>
						<td>&nbsp;</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="grid__col grid__col--5 grid__col-offset--1">
		<rd-chart [id]="cmsResult.report.id" [base]="cmsResult.getChartDataByField(field)" type="pie"></rd-chart>
	</div>
</div>


<div class="extremeStats" *ngIf="cmsResult.report.isFinalReport">
	<p>
		<b>Largest % Increase:</b> <span>{{ (cmsResult.summaryData[field+'_max_inc'] * 100)|number:'0.2-2' }}%</span>
	</p>
	<p>
		<b>Largest % Decrease:</b> <span>{{ (cmsResult.summaryData[field+'_max_dec'] * 100)|number:'0.2-2' }}%</span>
	</p>
</div>