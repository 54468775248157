<rd-alert type="warning" *ngIf="show && status == 0">
	<h4>Your Data Has Been Updated</h4>
	<p>Please be sure to return before the submission window closes to submit your final data.</p>
</rd-alert>
<rd-alert type="success" *ngIf="show && status == 1">
	<h4>Final Data Has Been Submitted</h4>
	<p>You are no longer able to update your data for this report. You will be notified via e-mail when your submission has been validated and you are granted access to view data for this report.</p>
</rd-alert>
<rd-alert type="success" *ngIf="show && status == 2">
	<h4>Your Data Has Been Validated</h4>
	<p>Other verified data is available for viewing. <a [routerLink]="url">View ABS Data</a></p>
</rd-alert>