<div class="grid__row">
	<div class="grid__col--5 grid__col-offset--1">
		<div [innerHtml]="cmsResult.mainContent"></div>
	</div>
	<div class="grid__col--4 grid__col-offset--1">
		<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
			<div class="form__element">
				<label for="contact-name">Your Full Name</label>
				<input type="text" id="contact-name" formControlName="fullname">
				<rd-alert *ngIf="doesControlHaveError(form.controls.fullname)" type="danger">This field is required.</rd-alert>
			</div>
			<div class="form__element">
				<label for="contact-work-email">Your Work Email</label>
				<input type="email" id="contact-work-email" formControlName="email">
				<rd-alert *ngIf="doesControlHaveError(form.controls.email)" type="danger">This field is required and needs to be an e-mail.</rd-alert>
			</div>
			<div class="form__element">
				<label for="contact-question">Your Question</label>
				<select id="contact-question" formControlName="question">
					<option value="">Please select...</option>
					<option [value]="option" *ngFor="let option of questionTypes">{{ option }}</option>
				</select>
				<rd-alert *ngIf="doesControlHaveError(form.controls.question)" type="danger">This field is required.</rd-alert>
			</div>
			<ng-container *ngIf="form.controls.question.value == 'I need to create a new account'">
				<div class="contact__add-delete">
					<p>Please provide the information for the new account holder:</p>
					<div class="form__element">
						<label for="contact-new-name">Full Name</label>
						<input type="text" id="contact-new-name" formControlName="new_fullname">
						<rd-alert *ngIf="doesControlHaveError(form.controls.new_fullname)" type="danger">This field is required.</rd-alert>
					</div>
					<div class="form__element">
						<label for="contact-new-title">Title</label>
						<input type="text" id="contact-new-title" formControlName="new_title">
						<rd-alert *ngIf="doesControlHaveError(form.controls.new_title)" type="danger">This field is required.</rd-alert>
					</div>
					<div class="form__element">
						<label for="contact-new-institution">Institution</label>
						<input type="text" id="contact-new-institution" formControlName="new_institution">
						<rd-alert *ngIf="doesControlHaveError(form.controls.new_institution)" type="danger">This field is required.</rd-alert>
					</div>
					<div class="form__element">
						<label for="contact-new-work-email">Email Address</label>
						<input type="email" id="contact-new-work-email" formControlName="new_email">
						<rd-alert *ngIf="doesControlHaveError(form.controls.new_email)" type="danger">This field is required.</rd-alert>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="form.controls.question.value == 'I need to delete an account'">
				<div class="contact__add-delete">
					<p>Please provide the information account holder that you need to delete:</p>
					<div class="form__element">
						<label for="contact-delete-name">Full Name</label>
						<input type="text" id="contact-delete-name" formControlName="delete_fullname">
						<rd-alert *ngIf="doesControlHaveError(form.controls.delete_fullname)" type="danger">This field is required.</rd-alert>
					</div>
					<div class="form__element">
						<label for="contact-delete-institution">Institution</label>
						<input type="text" id="contact-delete-institution" formControlName="delete_institution">
						<rd-alert *ngIf="doesControlHaveError(form.controls.delete_institution)" type="danger">This field is required.</rd-alert>
					</div>
				</div>
			</ng-container>
			<div class="form__element">
				<label for="contact-name">Details</label>
				<textarea id="contact-details" formControlName="details" rows="8" cols="100"></textarea>
				<rd-alert *ngIf="doesControlHaveError(form.controls.details)" type="danger">This field is required.</rd-alert>
			</div>

			<input type="submit" class="btn btn--gold submit" value="Submit" [disabled]="isSending"/>
			<rd-alert type="danger" *ngIf="error">
				<h4>Error</h4>
				{{ error.message }}
			</rd-alert>
			<rd-alert type="success" *ngIf="saved">
				<h4>Sent</h4>
				Thank you, your message has been sent.
			</rd-alert>
		</form>
	</div>
</div>