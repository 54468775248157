import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { deserialize, deserializeAs } from 'cerialize';
import { get, set } from 'lodash';

export class CmsEntryResult {
	@deserializeAs('cms_template')
	public template: any;

	@deserializeAs('cms_entry')
	public entry: any;

	@deserializeAs('cms_entry_navigation')
	public navigation: any;

	@deserializeAs('cms_entry_navigation_levels_deep')
	public navigationLevelsDeep: any;

	@deserialize
	public urls: any;

	get type(): string {
		return get(this.template, 'template');
	}

	get title(): string {
		return get(this.entry, ['title']);
	}

	get headerImage(): string | null {
		return get(this.entry, 'entries.main_image.0.image.value');
	}

	get mainContent(): SafeHtml {
		return get(this.entry, 'entries.main.0.value');
	}

	/**
	 * This function is helper function to further sanitize any part of the class that needs it.
	 *
	 *  i.e. using DomSanitizer to output more html such as iFrames that angular would otherwise
	 *  complain about.
	 * @param {DomSanitizer} sanitizer [description]
	 */
	public sanitize(sanitizer: DomSanitizer): CmsEntryResult {
		const content: string = get(this.entry, 'entries.main.0.value');
		set(this.entry, 'entries.main.0.value', sanitizer.bypassSecurityTrustHtml(content));
		return this;
	}
}
