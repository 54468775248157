import { Component, OnInit, Input } from '@angular/core';
import { get, find } from 'lodash';

import { CmsEntryResultAdminAbsTool } from '@app/cms/results/cms-entry-result-admin-abs-tool';

@Component({
	selector: 'rd-admin-abs-table',
	templateUrl: './admin-abs-table.component.html',
	styleUrls: ['./admin-abs-table.component.scss']
})
export class AdminAbsTableComponent implements OnInit {
	@Input()
	type: Type;

	@Input()
	averages: any;

	@Input()
	field: string;

	@Input()
	fields?: string[];

	@Input()
	fieldFormat = 'currency';

	@Input()
	headers?: string[];

	@Input()
	label?: string;

	@Input()
	totalLabel = 'Total Increase';

	@Input()
	filename: string;

	@Input()
	cmsResult: CmsEntryResultAdminAbsTool;

	@Input()
	removeMargin = false;

	@Input()
	isSmall = false;

	constructor() {}

	ngOnInit() {}

	getIncreaseOverPreviousYear(field: string, year: number) {
		const previousEntry = find(this.cmsResult.averages, result => result.year == year - 1);
		if (!previousEntry) {
			return undefined;
		}
		const currentEntry = find(this.cmsResult.averages, result => result.year == year);
		if (!currentEntry) {
			return undefined;
		}
		return currentEntry.data[0][field] / previousEntry.data[0][field] - 1;
	}

	getIncreaseTotal(field: string) {
		const mostRecentValue = get(this.cmsResult.averages, `0.data[0].${field}`);
		const firstValue = get(this.cmsResult.averages, `${this.cmsResult.averages.length - 1}.data[0].${field}`);
		return mostRecentValue / firstValue - 1;
	}
}

enum Type {
	split = 'split',
	singleYearSplit = 'single-year-split',
	range = 'range',
	fields = 'fields'
}
