import { CmsEntryResult } from './cms-entry-result';

export class CmsEntryResult404 extends CmsEntryResult {
	get type(): string {
		return '404';
	}

	get title(): string {
		return 'Page Not Found';
	}

	get headerImage(): string | null {
		// TODO: return an image for 404's
		return null;
	}
}
