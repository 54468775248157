<rd-save-screenshot [filename]="filename">
	<div *ngIf="type == 'split'">
		<div class="grid__row">
			<div class="grid__col--6" *ngFor="let data of averages; last as isLastGroup">
				<table class="table table--striped table--admin-split" [class.table--no-top-margin]="removeMargin" [class.table--small]="isSmall">
					<thead>
						<tr *ngIf="label">
							<th colspan="3">{{ label }}</th>
						</tr>
						<tr>
							<th *ngFor="let header of headers">{{ header }}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let result of data; index as i; last as isLastOfGroup">
							<th>
								{{ result.year }}
								<span *ngIf="result.year == 2018">***</span>
								<span *ngIf="result.year == 2007">**</span>
								<span *ngIf="result.year == 2006">*</span>
							</th>
							<td><ng-container *ngTemplateOutlet="outputField; context: {value: result.data[0][field], format: fieldFormat}"></ng-container></td>
							<td class="text--right">
								<span *ngIf="!isLastGroup || !isLastOfGroup">
									{{ getIncreaseOverPreviousYear(field, result.year) * 100 | number:'0.2-2' }}%
								</span>
							</td>
						</tr>
						<tr *ngIf="isLastGroup" class="subtotal">
							<th>{{ totalLabel }}</th>
							<td colspan="2" class="text--right">{{ getIncreaseTotal(field) * 100 | number:'0.2-2' }}%</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<rd-alert type="info">
			<h4>Note</h4>
			<p>* spring new students included for first time</p>
			<p>** survey oversight switches to NACCAP from CCCU</p>
			<p *ngIf="cmsResult.report.year >= 2018">*** budgets for Athletics Recruitment and Outside Assistance included separately for the first time</p>
		</rd-alert>
	</div>

	<div *ngIf="type == 'single-year-split'" class="grid__row">
		<div class="grid__col--6" *ngFor="let data of averages">
			<table class="table table--striped" [class.table--small]="isSmall">
				<thead *ngIf="label">
					<tr>
						<th colspan="2">{{ label }}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let result of data">
						<th>{{ result.grouping }}</th>
						<td><ng-container *ngTemplateOutlet="outputField; context: {value: result[field], format: fieldFormat}"></ng-container></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>



	<table *ngIf="type == 'range'" class="table table--striped" [class.table--small]="isSmall">
		<thead>
			<tr *ngIf="label">
				<th colspan="7">{{ label }}</th>
			</tr>
			<tr>
				<th>Category</th>
				<th *ngFor="let result of averages">{{ result.year }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let headers of averages[0].data; index as i;">
				<th>{{ headers.grouping }}</th>
				<td *ngFor="let result of averages">
					<ng-container *ngTemplateOutlet="outputField; context: {value: result.data[i][field], format: fieldFormat}"></ng-container>
				</td>
			</tr>
		</tbody>
	</table>

	<table *ngIf="type == 'fields'" class="table table--striped">
		<thead>
			<tr *ngIf="label">
				<th colspan="7">{{ label }}</th>
			</tr>
			<tr>
				<th>Category</th>
				<th *ngFor="let field of fields">{{ field.label }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let headers of averages; index as i;">
				<th>{{ headers.grouping }}</th>
				<td *ngFor="let field of fields">
					<ng-container *ngTemplateOutlet="outputField; context: {value: averages[i][field.field], format: fieldFormat}"></ng-container>
				</td>
			</tr>
		</tbody>
	</table>
</rd-save-screenshot>

<ng-template #outputField let-value="value" let-format="format">
	<span *ngIf="format == 'currency'">${{ value | number:'0.0-0' }}</span>
	<span *ngIf="format == 'decimal'">{{ value | number:'0.1-1' }}</span>
	<span *ngIf="format == 'percent'">{{ value * 100 | number:'0.2-2' }}%</span>
	<span *ngIf="format == 'percent1'">{{ value * 100 | number:'0.1-1' }}%</span>
</ng-template>