import { Component, Input } from '@angular/core';

import { CmsEntryResult } from '@app/cms/results/cms-entry-result';
import { Account } from '@app/account/account';

@Component({
	selector: 'rd-cms-entry-login',
	templateUrl: './cms-entry-login.component.html'
})
export class CmsEntryLoginComponent {
	@Input()
	cmsResult: CmsEntryResult;

	@Input()
	sessionUser: Account;

	@Input()
	urls: any;
}
