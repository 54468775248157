import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class AbsEntryService {
	constructor(private http: HttpClient) {}

	create(data: any): Promise<any> {
		return this.http.post<any>(`${environment.apiUrl}/crud/abs-entry`, data).toPromise();
	}

	update(data: any): Promise<any> {
		return this.http.patch<any>(`${environment.apiUrl}/crud/abs-entry`, data).toPromise();
	}

	getOpenReport(): Promise<any> {
		return this.http.get<any>(`${environment.apiUrl}/crud/abs/open`).toPromise();
	}

	getLatestOpenReport(): Promise<any> {
		return this.http.get<any>(`${environment.apiUrl}/crud/abs/open-latest`).toPromise();
	}

	sendConfirmationEmail(user_id: number, report_id: number): Promise<any> {
		return this.http.post<any>(`${environment.apiUrl}/crud/email/abs-confirmation`, { user_id, report_id }).toPromise();
	}

	sendAdminEmail(user_id: number, report_id: number): Promise<any> {
		return this.http.post<any>(`${environment.apiUrl}/crud/email/abs-completed`, { user_id, report_id }).toPromise();
	}

	sendRejectedEmail(org_id: number, report_id: number, reason: string): Promise<any> {
		return this.http
			.post<any>(`${environment.apiUrl}/crud/email/abs-rejected`, { org_id, report_id, reason })
			.toPromise();
	}

	sendAcceptedEmail(org_id: number, report_id: number): Promise<any> {
		return this.http.post<any>(`${environment.apiUrl}/crud/email/abs-accepted`, { org_id, report_id }).toPromise();
	}

	sendVerifiedEmail(user_id: number, report_id: number, org_id: number): Promise<any> {
		return this.http
			.post<any>(`${environment.apiUrl}/crud/email/abs-verified`, { user_id, report_id, org_id })
			.toPromise();
	}

	sendCommentToAdmin(user_id: number, report_id: number, part: string, comment: string): Promise<any> {
		return this.http
			.post<any>(`${environment.apiUrl}/crud/email/abs-comment-to-admin/${part}`, { user_id, report_id, comment })
			.toPromise();
	}

	sendCommentToOrg(org_id: number, report_id: number, part: string, comment: string): Promise<any> {
		return this.http
			.post<any>(`${environment.apiUrl}/crud/email/abs-comment-to-org/${part}`, { org_id, report_id, comment })
			.toPromise();
	}

	addComment(part: string, name: string, comment: string, user_id: number, naccap_abs_data_id: number): Promise<any> {
		return this.http
			.post<any>(`${environment.apiUrl}/crud/abs-comment`, { part, name, comment, user_id, naccap_abs_data_id })
			.toPromise();
	}

	getComments(part: string, naccap_abs_data_id: number): Promise<any> {
		return this.http.get<any>(`${environment.apiUrl}/crud/abs-comment/list/${naccap_abs_data_id}/${part}`).toPromise();
	}
}
