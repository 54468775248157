<p><a *ngIf="isVerifiedToViewResults" (click)="export()" class="btn btn--gold float--right" style="float: right;">Export report</a></p>
<h4>Open for submission: {{ cmsResult.report.open|date:'mediumDate':'utc' }} to {{ cmsResult.report.close|date:'mediumDate':'utc' }}</h4>

<rd-funnel-report-filter
	[isAbs]="true"
	[reports]="cmsResult.completedAbsReports"
	[includeSearch]="true"
	[defaults]="defaults"
	(update)="onFilterUpdate($event)"
></rd-funnel-report-filter>
<ng-container *ngIf="isVerifiedToViewResults">
	<table class="funnel--report table--striped">
		<thead class="funnel--report__header">
			<tr>
				<th class="funnel--report__leftcolumn">
					School
				</th>
				<th class="funnel--report__column--category" [attr.colspan]="4">
					Survey
					<table>
						<tr>
							<td class="funnel--report__column--funnel">Part A</td>
							<td class="funnel--report__column--funnel">Part B</td>
							<td class="funnel--report__column--funnel">Part C</td>
							<td class="funnel--report__column--funnel">Part D</td>
						</tr>
					</table>
				</th>
				<th class="funnel--report__column--last">
					Report Status
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let report of cmsResult.paginatedReports.items">
				<td class="funnel--report__leftcolumn">{{ report.org }}</td>
				<td><a [routerLink]="cmsResult.urls['abs-submit-part-a']" [queryParams]="{ id: report.naccap_abs_surveys_id, org_id: report.naccap_orgs_id }">Part A</a></td>
				<td><a [routerLink]="cmsResult.urls['abs-submit-part-b']" [queryParams]="{ id: report.naccap_abs_surveys_id, org_id: report.naccap_orgs_id }">Part B</a></td>
				<td>
					<a *ngIf="isVerifiedToViewPartC(report.naccap_orgs_id)" [routerLink]="cmsResult.urls['abs-submit-part-c']" [queryParams]="{ id: report.naccap_abs_surveys_id, org_id: report.naccap_orgs_id }">Part C</a>
					<span *ngIf="!isVerifiedToViewPartC(report.naccap_orgs_id)">Not Authorized</span>
				</td>
				<td><a [routerLink]="cmsResult.urls['abs-submit-part-d']" [queryParams]="{ id: report.naccap_abs_surveys_id, org_id: report.naccap_orgs_id }">Part D</a></td>
				<td>{{ report.statusLabel }}</td>
			</tr>
		</tbody>
	</table>

	<rd-pagination *ngIf="cmsResult.paginatedReports" [perPage]="cmsResult.paginatedReports.per_page" [page]="cmsResult.paginatedReports.page" [numItems]="cmsResult.paginatedReports.count[0].total" (onPageChanged)="onPageChanged($event)"></rd-pagination>
</ng-container>

<rd-alert type="warning" *ngIf="!isVerifiedToViewResults">
	<h2>Not Authorized</h2>
	<p>You must have your organization’s data verified for this report, or be logged in as an administrator, to view this page.</p>
	<p>You may view previous reports for which you provided verified data using the filters above.</p>
</rd-alert>