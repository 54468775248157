import { deserialize, inheritSerialization } from 'cerialize';
import { get } from 'lodash';

import { CmsEntryResult } from './cms-entry-result';

@inheritSerialization(CmsEntryResult)
export class CmsEntryResultAbsSummary extends CmsEntryResult {
	@deserialize
	public report: any;

	@deserialize
	public reportEntry: any;

	@deserialize
	public org: any;

	@deserialize
	public completedAbsReports: any;

	@deserialize
	public watchlist: any = {};

	@deserialize
	public orgAverages: any;

	@deserialize
	public watchlistAverages: any = {};

	@deserialize
	public regionAverages: any;

	@deserialize
	public enrollmentAverages: any;

	@deserialize
	public typeAverages: any;

	@deserialize
	public nationalAverages: any;

	@deserialize
	public orgPercentages: any;

	@deserialize
	public watchlistPercentages: any = {};

	@deserialize
	public regionPercentages: any;

	@deserialize
	public enrollmentPercentages: any;

	@deserialize
	public typePercentages: any;

	@deserialize
	public nationalPercentages: any;

	@deserialize
	public orgAdmits: any;

	@deserialize
	public watchlistAdmits: any = {};

	@deserialize
	public regionAdmits: any;

	@deserialize
	public enrollmentAdmits: any;

	@deserialize
	public typeAdmits: any;

	@deserialize
	public nationalAdmits: any;

	@deserialize
	costToRecruitData: any;

	get isEntryVerified(): boolean {
		return get(this.reportEntry, 'status') == 2;
	}
}
