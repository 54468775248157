import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { get, groupBy, toArray } from 'lodash';

import { CmsEntryResultHomepage } from '@app/cms/results/cms-entry-result-homepage';
import { Account } from '@app/account/account';
import { AccountService } from '@app/account/account.service';

@Component({
	selector: 'rd-cms-entry-homepage',
	templateUrl: './cms-entry-homepage.component.html'
})
export class CmsEntryHomepageComponent implements OnInit {
	constructor(public accountService: AccountService, private formBuilder: FormBuilder) {
		this.createForm();
	}

	@Input()
	cmsResult: CmsEntryResultHomepage;

	@Input()
	sessionUser: Account;

	filters: any = {
		absReport: null,
		memberReport: null
	};

	funnelReportGroups: any;
	form?: FormGroup;
	error: { message: string } | null = null;
	attemptedSubmit = false;
	saved = false;
	isSending = false;

	createForm() {
		this.form = this.formBuilder.group({
			fullname: ['Self Request'],
			email: ['Self Request'],
			question: ['I need to create a new account'],
			details: ['No additional details.', Validators.required],
			new_fullname: ['', Validators.required],
			new_title: ['', Validators.required],
			new_institution: ['', Validators.required],
			new_email: ['', Validators.required]
		});
	}

	doesControlHaveError(control: AbstractControl): boolean {
		return (control.touched || this.attemptedSubmit) && control.invalid;
	}

	ngOnInit(): void {
		const absReport = get(this.cmsResult.completedAbsReports, '0');
		this.filters.absReport = absReport;
		this.setReportGroupMap(this.cmsResult.completedFunnelReports);
		this.filters.funnelReport = get(this.funnelReportGroups, '0');
	}

	setReportGroupMap(reports: any) {
		this.funnelReportGroups = toArray(groupBy(reports, 'reportYear')).reverse();
	}

	onContactSubmit() {
		this.attemptedSubmit = true;
		this.saved = false;
		if (this.form!.invalid) {
			this.error = { message: 'Fix errors and try again.' };
			return;
		}
		this.isSending = true;
		this.accountService
			.sendContactRequest(this.form!.value)
			.then(() => {
				this.saved = true;
				this.isSending = false;
				this.form!.reset();
				this.attemptedSubmit = false;
			})
			.catch((data: any) => {
				this.error = { message: data.error.error.message };
				this.isSending = false;
			});
	}
}
