<div class="grid__row">
	<div class="grid__col--7 grid__col-offset--2">
		<h3>Participants</h3>
		<rd-funnel-report-filter
			[isAbs]="true"
			[reports]="cmsResult.allAbsReports"
			[includeSearch]="false"
			[defaults]="{ reportId: pageFilters.id || cmsResult.report.id }"
			(update)="onReportUpdate($event)"
		></rd-funnel-report-filter>
		<rd-save-screenshot filename="Participation {{ cmsResult.report.year }}.png">
			<table class="table table--no-top-margin table--vertical-baseline">
				<thead>
					<tr>
						<th colspan="6">{{ cmsResult.report.year }} Participant Breakdown ({{ cmsResult.participants.length }} Total)</th>
					</tr>
					<tr>
						<th colspan="2">NACCAP Region</th>
						<th colspan="2">Enrollment *</th>
						<th colspan="2">Institution Type</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td colspan="2" class="no--padding">
							<table class="table table--no-margin">
								<tr *ngFor="let key of participantsBreakdown.regionSort">
									<th>{{ key }}</th>
									<td>{{ participantsBreakdown.region[key].length }}</td>
								</tr>
							</table>
						</td>
						<td colspan="2" class="no--padding">
							<table class="table table--no-margin">
								<tr *ngFor="let item of cmsResult.enrollmentSort">
									<th>{{ item.prettyLabel }}</th>
									<td>{{ participantsBreakdown.enrollment[item.prettyLabel].length }}</td>
								</tr>
							</table>
						</td>
						<td colspan="2" class="no--padding">
							<table class="table table--no-margin">
								<tr *ngFor="let key of participantsBreakdown.typeSort">
									<th>{{ key }}</th>
									<td>{{ participantsBreakdown.type[key].length }}</td>
								</tr>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<rd-alert type="info">
				<p>* defined as full-time enrollment in traditional undergraduate programs</p>
			</rd-alert>
		</rd-save-screenshot>

		<h3>Cost to Recruit</h3>
		<rd-admin-abs-table 
			type="split" 
			[averages]="averagesSplit" 
			[cmsResult]="cmsResult"
			[headers]="['Fall of', 'Cost to Recruit a Student', 'Increase Over Previous Year']"
			field="ratio_total_budget_per_new_student"
			filename="Cost to Recruit From 1993-{{ cmsResult.report.year }}.png"
		>
		</rd-admin-abs-table>

		<rd-admin-abs-table 
			type="single-year-split" 
			[averages]="averagesForCurrentYear" 
			[cmsResult]="cmsResult"
			field="ratio_total_budget_per_new_student"
			label="Cost to Recruit a Student — {{ cmsResult.report.year }}"
			filename="Cost to Recruit {{ cmsResult.report.year }}.png"
		>
		</rd-admin-abs-table>

		<rd-admin-abs-table 
			type="range" 
			[averages]="averageRange" 
			[cmsResult]="cmsResult"
			field="ratio_total_budget_per_new_student"
			label="Cost to Recruit a Student — {{ averageRange[0].year }}-{{ cmsResult.report.year }}"
			filename="Cost to Recruit a Student {{ averageRange[0].year }}-{{ cmsResult.report.year }}.png"
		>
		</rd-admin-abs-table>

		<h3>Staff</h3>
		<rd-save-screenshot filename="Staff Size — Professional & Recruiting FTE.png">
			<div class="grid__row">
				<div class="grid__col--6">
					<rd-admin-abs-table 
						type="range" 
						[averages]="averageRange" 
						[cmsResult]="cmsResult"
						field="c_pro_staff"
						label="Staff Size — Professional Staff FTE"
						fieldFormat="decimal"
						[isSmall]="true"
					>
					</rd-admin-abs-table>
				</div>

				<div class="grid__col--6">
					<rd-admin-abs-table 
						type="range" 
						[averages]="averageRange" 
						[cmsResult]="cmsResult"
						field="c_pro_staff_direct_recruiting"
						label="Staff Size — Recruiting FTE"
						fieldFormat="decimal"
						[isSmall]="true"
					>
					</rd-admin-abs-table>
				</div>
			</div>
		</rd-save-screenshot>

		<rd-save-screenshot filename="Professional Staff Overhead.png">
			<rd-admin-abs-table 
				type="range" 
				[averages]="averageRange" 
				[cmsResult]="cmsResult"
				field="_professional_staff_overhead"
				label="Professional Staff Overhead*"
				fieldFormat="decimal"
			>
			</rd-admin-abs-table>
			<rd-alert type="info">
				* defined as the difference between professional staff and recruiting staff FTE
			</rd-alert>
		</rd-save-screenshot>

		<rd-admin-abs-table 
			type="range" 
			[averages]="averageRange" 
			[cmsResult]="cmsResult"
			field="c_support_staff"
			label="Staff Size — Support Staff FTE"
			filename="Staff Size — Support Staff FTE.png"
			fieldFormat="decimal"
		>
		</rd-admin-abs-table>

		<rd-admin-abs-table 
			type="split" 
			[averages]="averagesSplit" 
			[cmsResult]="cmsResult"
			label="NACCAP — Ratio of New Students to Professional Staff FTE: {{ cmsResult.averages[cmsResult.averages.length-1].year }}-{{ cmsResult.report.year }}"
			[headers]="['Fall of', 'New Students Per Professional Staff FTE', 'Change From Previous Year']"
			field="ratio_new_students_per_staff"
			filename="Ratio of New Students to Professional Staff FTE: {{ cmsResult.averages[cmsResult.averages.length-1].year }}-{{ cmsResult.report.year }}.png"
			fieldFormat="decimal"
			totalLabel="Total Efficiency Change"
		>
		</rd-admin-abs-table>

		<rd-admin-abs-table 
			type="range" 
			[averages]="averageRange" 
			[cmsResult]="cmsResult"
			field="ratio_new_students_per_recruiting"
			label="NACCAP — Ratio of New Students to Professional Staff Recruiting FTE"
			filename="Ratio of New Students to Professional Staff Recruiting FTE.png"
			fieldFormat="decimal"
		>
		</rd-admin-abs-table>

		<rd-save-screenshot filename="Admission Professional Salary Survey {{ averageRangeShort[0].year }}-{{ cmsResult.report.year }}.png">
			<table class="table table--striped table--small">
				<thead>
					<tr>
						<th colspan="8">Admission Professional Salary Survey {{ averageRangeShort[0].year }}-{{ cmsResult.report.year }}</th>
					</tr>
					<tr>
						<th>Category</th>
						<ng-container *ngFor="let result of averageRangeShort; first as isFirst;">
							<th *ngIf="!isFirst">Change</th>
							<th>{{ result.year }}-{{ result.year + 1 }} Salary</th>
						</ng-container>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let field of salaryFields">
						<th>{{ field.label }}</th>
						<ng-container *ngFor="let result of averageRangeShort; first as isFirst; index as i;">
							<td *ngIf="!isFirst">{{ getIncreaseOverPreviousYear(field.field, result.year) * 100 | number:'0.2-2' }}%</td>
							<td>${{ result.data[0][field.field] | number:'0.0-0' }}</td>
						</ng-container>
					</tr>
				</tbody>
			</table>
		</rd-save-screenshot>

		<rd-admin-abs-table 
			type="fields" 
			[averages]="cmsResult.averages[0].data" 
			[cmsResult]="cmsResult"
			[fields]="salaryFields"
			label="{{ cmsResult.report.year }} Average Salaries by Position and Category"
			filename="{{ cmsResult.report.year }} Average Salaries by Position and Category.png"
			fieldFormat="currency"
		>
		</rd-admin-abs-table>

		<rd-admin-abs-table 
			type="fields" 
			[averages]="cmsResult.averages[0].data" 
			[cmsResult]="cmsResult"
			[fields]="carFields"
			label="{{ cmsResult.report.year }} Average Percentage of Schools Including Car by Position and Category"
			filename="{{ cmsResult.report.year }} Average Percentage of Schools Including Car by Position and Category.png"
			fieldFormat="percent"
		>
		</rd-admin-abs-table>

		<h3>Funnel Data</h3>

		<rd-admin-abs-table 
			type="fields" 
			[averages]="cmsResult.funnelAverages" 
			[cmsResult]="cmsResult"
			[fields]="funnelFields"
			label="Average Yield Rates — {{ cmsResult.report.year }} Study"
			filename="Average Yield Rates — {{ cmsResult.report.year }} Study.png"
			fieldFormat="percent1"
		>
		</rd-admin-abs-table>

		<h3>Examples</h3>
		<rd-save-screenshot filename="Example Funnel Breakdown.png">
			<table class="table table--striped">
				<thead>
					<tr>
						<th colspan="6">The Funnel Analogy Breakdown</th>
					</tr>
					<tr>
						<th>Category</th>
						<th>Initial Funnel Numbers</th>
						<th>Yield Rates</th>
						<th>Top of the Funnel Investment Numbers</th>
						<th>Reality Check Yield Rates</th>
						<th>Reality Check Funnel Numbers</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Applications</td>
						<td>1,000</td>
						<td></td>
						<td>2,000</td>
						<td></td>
						<td>2,000</td>
					</tr>
					<tr>
						<td>Completed Applications</td>
						<td>800</td>
						<td>80.0%</td>
						<td>1,600</td>
						<td>60%</td>
						<td>1,200</td>
					</tr>
					<tr>
						<td>Admits</td>
						<td>780</td>
						<td>97.5%</td>
						<td>1,560</td>
						<td>97.6%</td>
						<td>1,171</td>
					</tr>
					<tr>
						<td>Matriculants</td>
						<td>300</td>
						<td>38.5%</td>
						<td>601</td>
						<td>28.0%</td>
						<td>328</td>
					</tr>
				</tbody>
			</table>
		</rd-save-screenshot>

		<rd-save-screenshot filename="Example Completion Rate Calculation.png">
			<table class="table table--striped">
				<thead>
					<tr>
						<th colspan="2">Calculating a Completion Rate</th>
					</tr>
					<tr>
						<th>Category</th>
						<th>Number</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Applications</td>
						<td>1,566</td>
					</tr>
					<tr>
						<td>Denials</td>
						<td>51</td>
					</tr>
					<tr>
						<td>Admits</td>
						<td>871</td>
					</tr>
					<tr>
						<td>Completed Applications</td>
						<td>922</td>
					</tr>
					<tr>
						<td>Completion Rate</td>
						<td>58.9%</td>
					</tr>
					<tr>
						<td>Admission Percentage</td>
						<td>94.5%</td>
					</tr>
					<tr>
						<td>Denial Percentage</td>
						<td>5.5%</td>
					</tr>
				</tbody>
			</table>
		</rd-save-screenshot>
	</div>
</div>