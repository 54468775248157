import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import * as html2canvas from 'html2canvas';

@Component({
	selector: 'rd-save-screenshot',
	templateUrl: './save-screenshot.component.html',
	styleUrls: ['./save-screenshot.component.scss']
})
export class SaveScreenshotComponent implements OnInit {
	constructor() {}
	@Input()
	filename: string;

	@ViewChild('download') download: ElementRef;

	ngOnInit() {}

	save() {
		const saveData = (function() {
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.style.display = 'none';
			return function(blob: Blob, fileName: string) {
				const url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = fileName;
				a.click();
				window.URL.revokeObjectURL(url);
			};
		})();
		const nativeElement = this.download instanceof ElementRef ? this.download.nativeElement : this.download;
		html2canvas(nativeElement).then((canvas: any) => {
			canvas.toBlob((blob: Blob) => {
				saveData(blob, this.filename);
			});
		});
	}
}
