import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash';

import { CmsEntryResultFunnelReport } from '@app/cms/results/cms-entry-result-funnel-report';
import { FunnelEntryService } from '@app/funnel/funnel-entry.service';
import { Account } from '@app/account/account';

@Component({
	selector: 'rd-cms-entry-funnel-report',
	templateUrl: './cms-entry-funnel-report.component.html'
})
export class CmsEntryFunnelReportComponent implements OnInit {
	@Input()
	cmsResult: CmsEntryResultFunnelReport;

	@Input()
	sessionUser: Account;

	@Input()
	urls: any;

	form: FormGroup;
	hasIncompleteData = false;
	isSubmitting = false;
	isSaved = false;
	error: any;
	isSpecificReportLookup = false;
	updateGoal = false;
	updateOriginalGoal = false;

	useOriginalGoalFeature = true; // TODO
	editor = null;
	config = {
		toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo']
	};

	constructor(
		private formBuilder: FormBuilder,
		private funnelEntryService: FunnelEntryService,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		const finalFormValidators = this.cmsResult.report.isFinalReport ? [Validators.required] : [];
		this.useOriginalGoalFeature = this.cmsResult.report.reportYear >= 2021;
		this.form = this.formBuilder.group(
			{
				id: this.cmsResult.entryId,
				fr_app_curr: [this.cmsResult.fr_app_curr, Validators.required],
				fr_app_prev: [this.cmsResult.fr_app_prev, Validators.required],
				fr_adm_curr: [this.cmsResult.fr_adm_curr, Validators.required],
				fr_adm_prev: [this.cmsResult.fr_adm_prev, Validators.required],
				fr_dep_curr: [this.cmsResult.fr_dep_curr, Validators.required],
				fr_dep_prev: [this.cmsResult.fr_dep_prev, Validators.required],
				fr_dep_canceled_curr: [this.cmsResult.fr_dep_canceled_curr],
				fr_dep_canceled_prev: [this.cmsResult.fr_dep_canceled_prev],
				tr_app_curr: [this.cmsResult.tr_app_curr, Validators.required],
				tr_app_prev: [this.cmsResult.tr_app_prev, Validators.required],
				tr_adm_curr: [this.cmsResult.tr_adm_curr, Validators.required],
				tr_adm_prev: [this.cmsResult.tr_adm_prev, Validators.required],
				tr_dep_curr: [this.cmsResult.tr_dep_curr, Validators.required],
				tr_dep_prev: [this.cmsResult.tr_dep_prev, Validators.required],
				tr_dep_canceled_curr: [this.cmsResult.tr_dep_canceled_curr],
				tr_dep_canceled_prev: [this.cmsResult.tr_dep_canceled_prev],
				tns_app_curr: { value: this.cmsResult.tns_app_curr, disabled: true },
				tns_app_prev: { value: this.cmsResult.tns_app_prev, disabled: true },
				tns_adm_curr: { value: this.cmsResult.tns_adm_curr, disabled: true },
				tns_adm_prev: { value: this.cmsResult.tns_adm_prev, disabled: true },
				tns_dep_curr: { value: this.cmsResult.tns_dep_curr, disabled: true },
				tns_dep_prev: { value: this.cmsResult.tns_dep_prev, disabled: true },
				tns_dep_canceled_curr: { value: this.cmsResult.tns_dep_canceled_curr, disabled: true },
				tns_dep_canceled_prev: { value: this.cmsResult.tns_dep_canceled_prev, disabled: true },
				incomplete_explanation: this.cmsResult.incomplete_explanation,
				curr_goal: this.cmsResult.curr_goal,
				new_goal: '',
				original_goal: get(this.cmsResult.original_goal, 'goal'),
				modified_goal: get(this.cmsResult.original_goal, 'modified.goal'),
				prev_actual: this.cmsResult.prev_actual,
				fr_enr_curr: [this.cmsResult.fr_enr_curr, finalFormValidators],
				fr_enr_prev: [this.cmsResult.fr_enr_prev, finalFormValidators],
				tr_enr_curr: [this.cmsResult.tr_enr_curr, finalFormValidators],
				tr_enr_prev: [this.cmsResult.tr_enr_prev, finalFormValidators],
				tns_enr_curr: { value: this.cmsResult.tns_enr_curr, disabled: true },
				tns_enr_prev: { value: this.cmsResult.tns_enr_prev, disabled: true },
				reject_reason: '',
				original_goal_edit_enabled: false,
				original_goal_edit: ''
			},
			{ validator: formBaseValidator }
		);

		this.hasIncompleteData =
			this.form.controls.incomplete_explanation.value && this.form.controls.incomplete_explanation.value.length;

		const totals = [
			'app_curr',
			'app_prev',
			'adm_curr',
			'adm_prev',
			'dep_curr',
			'dep_prev',
			'dep_canceled_curr',
			'dep_canceled_prev',
			'enr_curr',
			'enr_prev'
		];
		totals.forEach(field => {
			const _updateTotals = () => {
				this.form.controls['tns_' + field].setValue(
					+this.form.controls['fr_' + field].value + +this.form.controls['tr_' + field].value
				);
			};
			this.form.controls['fr_' + field].valueChanges.subscribe(_updateTotals);
			this.form.controls['tr_' + field].valueChanges.subscribe(_updateTotals);
		});

		if (!this.canSubmit()) {
			this.form.disable();
		}

		this.isSpecificReportLookup = this.route.snapshot.queryParams.id;
	}

	canSubmit(): boolean {
		return (
			(this.hasWritePermission() && this.cmsResult.isOpen && this.cmsResult.existingStatus === 0) ||
			this.sessionUser.isAdmin === true
		);
	}

	hasWritePermission() {
		return (
			this.sessionUser.isAdmin || (this.cmsResult.existingStatus === 0 && this.sessionUser.orgId === this.getOrgId())
		);
	}

	hasReadPermission() {
		return (
			this.sessionUser.isAdmin || this.sessionUser.orgId === this.getOrgId() || this.cmsResult.isUserReportValidated()
		);
	}

	getOrgId() {
		return this.cmsResult.org.id;
	}

	updateGoalState() {
		this.updateGoal = true;
		this.form.patchValue({ new_goal: this.form.value.modified_goal || this.form.value.original_goal });
	}

	updateOriginalGoalState() {
		this.updateOriginalGoal = true;
		this.form.patchValue({
			original_goal_edit: this.form.value.original_goal,
			original_goal_edit_enabled: true
		});
	}

	/**
	 * Does not care about form validation.
	 * Does not update form status.
	 */
	submitProgress(): void {
		const data = this.form.getRawValue();
		this.save(data).catch(err => {
			this.isSubmitting = false;
			this.error = err.error.error;
		});
	}

	/**
	 * Checks form status to make sure it passes validation.
	 * Updates form status to 1, or leaves at 2 (if admin).
	 */
	async submitFinal(): Promise<void> {
		if (this.form!.invalid && !this.form.controls.incomplete_explanation.value) {
			this.hasIncompleteData = true;
			this.error = { message: 'Please fill out all required fields, fix any errors and try again.' };
			return;
		}
		const data = this.form.getRawValue();
		const previousStatus = this.cmsResult.existingStatus;
		if (this.cmsResult.existingStatus == 2 && this.sessionUser.isAdmin) {
			data.status = 2; // keep status at 2 if admin is updating data, and report was already verified
		} else {
			data.status = 1; // mark entry as complete
		}
		try {
			await this.save(data);
			// if the user saving data is part of this org (non-admin)
			// and the status has been updated, then we'll send emails.
			if (this.sessionUser.orgId == this.getOrgId() && previousStatus != data.status) {
				this.funnelEntryService.sendConfirmationEmail(this.sessionUser.userId, this.cmsResult.reportId);
				this.funnelEntryService.sendAdminEmail(this.sessionUser.userId, this.cmsResult.reportId);
			}
		} catch (err) {
			if (err && typeof err === 'object' && 'error' in err) {
				this.isSubmitting = false;
				const errorObject = err as { error: { error: string } };
				this.error = errorObject.error.error;
			}
		}
	}

	async save(data: any): Promise<any> {
		data.naccap_app_reports_id = this.cmsResult.report.id;
		data.naccap_orgs_id = this.getOrgId();
		data.user_id = this.sessionUser.userId;
		if (this.useOriginalGoalFeature) {
			data.curr_goal = data.new_goal || data.original_goal_edit || data.original_goal;
		}
		if (this.cmsResult.report.isFinalReport) {
			data.curr_goal = data.tns_enr_curr;
			if (this.useOriginalGoalFeature) {
				data.prev_actual = data.new_goal || data.original_goal_edit || data.original_goal;
			}
		}
		if (data.original_goal_edit_enabled) {
			data.new_goal = data.original_goal_edit;
		}
		const entryId = get(this.cmsResult, 'reportEntry.id');
		if (entryId) {
			data.id = entryId;
		}
		this.isSubmitting = true;
		this.error = {};
		if (data.id) {
			await this.funnelEntryService.update(data);
		} else {
			const result = await this.funnelEntryService.create(data);
			this.form.patchValue({ id: result.insertId });
		}
		this.isSubmitting = false;
		this.isSaved = true;
		this.cmsResult.existingStatus = data.status;
	}

	// Event emitted from admin actions
	onAdminReportActions(status: number) {}

	hasBaseFormError(field: string): boolean {
		return get(this.form, `errors.baseErrors.${field}`);
	}

	hasControlError(field: string): boolean {
		const control = this.form.controls[field];
		return get(control, 'touched') && get(control, 'errors.required');
	}
}

function formBaseValidator(formGroup: FormGroup): ValidationErrors | null {
	const errors: any = {};
	let hasErrors = false;
	if (+formGroup.controls['fr_app_curr'].value < +formGroup.controls['fr_adm_curr'].value) {
		errors['fr_app_curr'] = true;
		hasErrors = true;
	}
	if (+formGroup.controls['fr_adm_curr'].value < +formGroup.controls['fr_dep_curr'].value) {
		errors['fr_adm_curr'] = true;
		hasErrors = true;
	}
	if (+formGroup.controls['fr_app_prev'].value < +formGroup.controls['fr_adm_prev'].value) {
		errors['fr_app_prev'] = true;
		hasErrors = true;
	}
	if (+formGroup.controls['fr_adm_prev'].value < +formGroup.controls['fr_dep_prev'].value) {
		errors['fr_adm_prev'] = true;
		hasErrors = true;
	}
	if (+formGroup.controls['fr_adm_prev'].value < +formGroup.controls['fr_enr_prev'].value) {
		errors['fr_enr_prev'] = true;
		hasErrors = true;
	}
	if (+formGroup.controls['fr_adm_curr'].value < +formGroup.controls['fr_enr_curr'].value) {
		errors['fr_enr_curr'] = true;
		hasErrors = true;
	}
	if (+formGroup.controls['tr_app_curr'].value < +formGroup.controls['tr_adm_curr'].value) {
		errors['tr_app_curr'] = true;
		hasErrors = true;
	}
	if (+formGroup.controls['tr_adm_curr'].value < +formGroup.controls['tr_dep_curr'].value) {
		errors['tr_adm_curr'] = true;
		hasErrors = true;
	}
	if (+formGroup.controls['tr_app_prev'].value < +formGroup.controls['tr_adm_prev'].value) {
		errors['tr_app_prev'] = true;
		hasErrors = true;
	}
	if (+formGroup.controls['tr_adm_prev'].value < +formGroup.controls['tr_dep_prev'].value) {
		errors['tr_adm_prev'] = true;
		hasErrors = true;
	}
	if (+formGroup.controls['tr_adm_prev'].value < +formGroup.controls['tr_enr_prev'].value) {
		errors['tr_enr_prev'] = true;
		hasErrors = true;
	}
	if (+formGroup.controls['tr_adm_curr'].value < +formGroup.controls['tr_enr_curr'].value) {
		errors['tr_enr_curr'] = true;
		hasErrors = true;
	}
	return hasErrors ? { baseErrors: errors } : null;
}
