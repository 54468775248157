<rd-abs-submit-wrapper
	[cmsResult]="cmsResult"
	subtitle="Part D Funnel Numbers"
>
	<rd-abs-submit-save-messages [show]="(hasReadPermission() && isSavedFinal) || cmsResult.existingStatus > 0" [status]="cmsResult.existingStatus" [url]="cmsResult.urls['abs-landing']"></rd-abs-submit-save-messages>
	<div class="before__grid" *ngIf="hasReadPermission()">
		<rd-alert type="success" *ngIf="isSavedTemporary && cmsResult.existingStatus == 0">
			<h4>Your Data Has Been Updated</h4>
			<p>You may return to any of the parts to update data before final submission.</p>
		</rd-alert>
		<rd-alert type="success" *ngIf="isSavedAndReturning">
			<h4>Your Data Has Been Updated</h4>
			<p>You are now being directed back to Part C.</p>
		</rd-alert>
		<div *ngIf="!isSaved">
			<!-- Intro -->
			<div [innerHtml]="cmsResult.introduction"></div>
			<!-- <hr class="hr--thick"> -->
		</div>
	</div>
	<ng-container *ngIf="hasReadPermission() && !isSaved" [formGroup]="form">

		<!-- Inquiries -->
		<!-- <div [innerHtml]="cmsResult.inquiries"></div> -->
		<div class="grid__row column--labels">
			<div class="column--labels__last--year grid__col grid__col--3 grid__col-offset--8">
				Last Year
			</div>
		</div>
		<rd-abs-submit-field
			[class.error]="form.errors?.moreApplicantsThanInquiries"
			field="d_inquired"
			[showLastYear]="true"
			[control]="form.controls.d_inquired"
			[cmsResult]="cmsResult">
			<input rdNumberInput type="text" name="d_inquired" formControlName="d_inquired" (focus)="cmsResult.activeInput = 'd_inquired'" (blur)="cmsResult.activeInput = ''" maxlength="5" tabindex="1" />
		</rd-abs-submit-field>
		<!-- <hr class="hr--thick"> -->

		<!-- Applications -->
		<!-- <div [innerHtml]="cmsResult.applications"></div> -->
		<rd-abs-submit-field
			[class.error]="form.errors?.moreApplicantsThanInquiries || form.errors?.moreCompletedAppsThanApplications"
			field="d_applied"
			[showLastYear]="true"
			[control]="form.controls.d_applied"
			[cmsResult]="cmsResult">
			<input rdNumberInput type="text" name="d_applied" formControlName="d_applied" (focus)="cmsResult.activeInput = 'd_applied'" (blur)="cmsResult.activeInput = ''" maxlength="5" tabindex="2" />
		</rd-abs-submit-field>
		<!-- <hr class="hr--thick"> -->

		<!-- Completed Applications -->
		<!-- <div [innerHtml]="cmsResult.completedApplications"></div> -->
		<rd-abs-submit-field
			[class.error]="form.errors?.moreCompletedAppsThanApplications || form.errors?.moreAcceptedThanCompleted"
			field="d_completed_app"
			[showLastYear]="true"
			[control]="form.controls.d_completed_app"
			[cmsResult]="cmsResult">
			<input rdNumberInput type="text" name="d_completed_app" formControlName="d_completed_app" (focus)="cmsResult.activeInput = 'd_completed_app'" (blur)="cmsResult.activeInput = ''" maxlength="5" tabindex="3" />
		</rd-abs-submit-field>
		<!-- <hr class="hr--thick"> -->

		<!-- Admitted -->
		<!-- <div [innerHtml]="cmsResult.admitted"></div> -->
		<rd-abs-submit-field
			[class.error]="form.errors?.moreAcceptedThanCompleted || form.errors?.moreEnrolledThanAccepted"
			field="d_accepted"
			[showLastYear]="true"
			[control]="form.controls.d_accepted"
			[cmsResult]="cmsResult">
			<input rdNumberInput type="text" name="d_accepted" formControlName="d_accepted" (focus)="cmsResult.activeInput = 'd_accepted'" (blur)="cmsResult.activeInput = ''" maxlength="5" tabindex="4" />
		</rd-abs-submit-field>
		<rd-abs-submit-field
			[isReadonly]="true"
			field="d_acceptance_rate"
			[showLastYear]="false"
			[control]="form.controls.d_acceptance_rate"
			[cmsResult]="cmsResult">
			{{ form.controls.d_acceptance_rate.value | number:'0.2-2' }}%
		</rd-abs-submit-field>
		<!-- <hr class="hr--thick"> -->

		<!-- Enrolled -->
		<!-- <div [innerHtml]="cmsResult.enrolled"></div> -->
		<rd-abs-submit-field
			[class.error]="form.errors?.moreEnrolledThanAccepted"
			field="d_enrolled"
			[showLastYear]="true"
			[control]="form.controls.d_enrolled"
			[cmsResult]="cmsResult">
			<input rdNumberInput type="text" name="d_enrolled" formControlName="d_enrolled" (focus)="cmsResult.activeInput = 'd_enrolled'" (blur)="cmsResult.activeInput = ''" maxlength="5" tabindex="5" />
		</rd-abs-submit-field>

		<rd-alert type="warning" *ngIf="form.errors">
			<h4>Caution</h4>
			<p *ngIf="form.errors?.moreApplicantsThanInquiries">You cannot have more applicants than the number of inquiries.</p>
			<p *ngIf="form.errors?.moreCompletedAppsThanApplications">You cannot have more completed applications than the total number of applicants.</p>
			<p *ngIf="form.errors?.moreAcceptedThanCompleted">You cannot have more acceptances than the number of completed applications.</p>
			<p *ngIf="form.errors?.moreEnrolledThanAccepted">You cannot have enrolled more students than you accepted.</p>
		</rd-alert>

		<ng-container *ngIf="canSubmit()">
			<p>&nbsp;</p>
			<p>If you're ready, You may now submit your <strong>final data</strong> for review. Click the "Submit Final Data" button below to submit your data for validation. If you need to revisit other parts of the form before submitting final data, use the "Save and Go Back" button below to step backward through the part of the form. <span class="note">You will not be able to alter your data for this study after final submission.</span></p>
			<p class="text-right">
				<a class="btn btn--ghost btn--ghost--gold" (click)="saveAndGoBack()" >Save and Go Back</a> 
				<a class="btn btn--ghost btn--ghost--gold" (click)="saveTemporary()" >Save without Submitting</a>
				<a class="btn btn--gold" (click)="saveFinal()">Submit Final Data</a>
			</p>
		</ng-container>

		<rd-alert type="warning" *ngIf="empties.a">
			<h4>Warning: Empty Fields in Part A</h4>
			<p>You left some fields empty or zero in Part A. Before final submission, please <a [routerLink]="cmsResult.urls['abs-submit-part-a']" queryParamsHandling="merge">take one more look</a>, fill out as much as you can, and be sure to give an explanation for any missing data in the comments box on that part of the form.</p>
			<p>If you've done that and are ready to continue with final submission, please scroll down and hit the "Submit Final Data" button once more.</p>
		</rd-alert>

		<rd-alert type="warning" *ngIf="empties.b">
			<h4>Warning: Empty Fields in Part B</h4>
			<p>You left some fields empty or zero in Part B. Before final submission, please <a [routerLink]="cmsResult.urls['abs-submit-part-b']" queryParamsHandling="merge">take one more look</a>, fill out as much as you can, and be sure to give an explanation for any missing data in the comments box on that part of the form.</p>
			<p>If you've done that and are ready to continue with final submission, please scroll down and hit the "Submit Final Data" button once more.</p>
		</rd-alert>

		<rd-alert type="warning" *ngIf="empties.c && sectionCRequirementMet">
			<h4>Warning: Empty Fields in Part C</h4>
			<p>You left some fields empty or zero in Part C. Before final submission, please <a [routerLink]="cmsResult.urls['abs-submit-part-c']" queryParamsHandling="merge">take one more look</a>, fill out as much as you can, and be sure to give an explanation for any missing data in the comments box on that part of the form.</p>
			<p>If you've done that and are ready to continue with final submission, please scroll down and hit the "Submit Final Data" button once more.</p>
		</rd-alert>

		<rd-alert type="danger" *ngIf="empties.c && !sectionCRequirementMet">
			<h4>Error: Minimum Completion of Part C Not Met</h4>
			<p>At least half of Part C must be completed before final submission will be allowed. Please <a [routerLink]="cmsResult.urls['abs-submit-part-c']" queryParamsHandling="merge">return to Part C</a>, fill out as much as you can, and be sure to give an explanation for any missing data in the comments box on that part of the form.</p>
		</rd-alert>

		<rd-alert type="warning" *ngIf="empties.d">
			<h4>Warning: Empty Fields in Part D</h4>
			<p>You left some fields empty or zero in Part B. Before final submission, please take one more look below, fill out as much as you can, and be sure to give an explanation for any missing data in the comments box on that part of the form.</p>
			<p>If you've done that and are ready to continue with final submission, please scroll down and hit the "Submit Final Data" button once more.</p>
		</rd-alert>

		<rd-alert type="danger" *ngIf="error && error.message">
			<h4>Error</h4>
			<p>{{ error.message }}</p>
		</rd-alert>
		
		<ng-container *ngIf="sessionUser.isAdmin && cmsResult.onHoldMessages && cmsResult.onHoldMessages.length">
			<h3>On Hold Messages (only visible to Admin)</h3>
			<rd-alert type="info" *ngFor="let message of cmsResult.onHoldMessages">
				<h4>{{ message.created_at|date:'medium' }}</h4>
				<div [innerHTML]="message.message"></div>
			</rd-alert>
		</ng-container>
		
		<rd-abs-submit-comment part="d" [entryId]="cmsResult.entryId" [user]="sessionUser" [cmsResult]="cmsResult"></rd-abs-submit-comment>

		<ng-container *ngIf="sessionUser.isAdmin && cmsResult.existingStatus > 0">
			<rd-admin-report-actions type="abs" [user]="sessionUser" [entryId]="cmsResult.entryId" [orgId]="cmsResult.orgId" [reportId]="cmsResult.surveyId" (onSave)="onAdminReportActions($event)"></rd-admin-report-actions>
		</ng-container>
	</ng-container>
	<rd-alert type="warning" *ngIf="!hasReadPermission()">
		<h4>Your Data for this Study Has Not Been Validated</h4>
		<p>You cannot view other schools' data unless your own data has been validated.</p>
	</rd-alert>
</rd-abs-submit-wrapper>