<rd-abs-submit-wrapper
	[cmsResult]="cmsResult"
	subtitle="Part C Staffing & Salary Data"
>
	<rd-abs-submit-save-messages [show]="hasReadPermission() && (isSaved || cmsResult.existingStatus > 0)" [status]="cmsResult.existingStatus" [url]="cmsResult.urls['abs-landing']"></rd-abs-submit-save-messages>
	<div class="before__grid" *ngIf="hasReadPermission() && !isSaved">
		<!-- Intro -->
		<div [innerHtml]="cmsResult.introduction"></div>
		<hr class="hr--thick">
	</div>
	<ng-container *ngIf="hasReadPermission() && !isSaved" [formGroup]="form">
		<!-- Staff -->
		<div [innerHtml]="cmsResult.staff1"></div>
		<div class="grid__row column--labels">
			<div class="column--labels__last--year grid__col grid__col--3 grid__col-offset--8">
				Last Year
			</div>
		</div>
		<rd-abs-submit-field
			field="c_pro_staff"
			[showLastYear]="true"
			[control]="form.controls.c_pro_staff"
			[cmsResult]="cmsResult">
			<input [rdNumberInput]="{ allowDecimals: true }" type="text" name="c_pro_staff" formControlName="c_pro_staff" (focus)="cmsResult.activeInput = 'c_pro_staff'" (blur)="cmsResult.activeInput = ''" maxlength="5" tabindex="1">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			[isReadonly]="true"
			field="c_pro_staff_avg_sal"
			[showLastYear]="true"
			fieldType="currency"
			[control]="form.controls.c_pro_staff_avg_sal"
			[cmsResult]="cmsResult">
			$ {{ form.controls.c_pro_staff_avg_sal.value|currency:0 }}
		</rd-abs-submit-field>
		<hr class="hr--thick">

		<!-- Staff Part 2 -->
		<div [innerHtml]="cmsResult.staff2"></div>
		<div class="grid__row column--labels">
			<div class="column--labels__last--year grid__col grid__col--3 grid__col-offset--8">
				Last Year
			</div>
		</div>
		<rd-abs-submit-field
			field="c_pro_staff_direct_recruiting"
			[showLastYear]="true"
			[control]="form.controls.c_pro_staff_direct_recruiting"
			[cmsResult]="cmsResult">
			<input [rdNumberInput]="{ allowDecimals: true }" type="text" name="c_pro_staff_direct_recruiting" formControlName="c_pro_staff_direct_recruiting" (focus)="cmsResult.activeInput = 'c_pro_staff_direct_recruiting'" (blur)="cmsResult.activeInput = ''" maxlength="5" tabindex="2">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			field="c_support_staff"
			[showLastYear]="true"
			[control]="form.controls.c_support_staff"
			[cmsResult]="cmsResult">
			<input [rdNumberInput]="{ allowDecimals: true }" type="text" name="c_support_staff" formControlName="c_support_staff" (focus)="cmsResult.activeInput = 'c_support_staff'" (blur)="cmsResult.activeInput = ''" maxlength="5" tabindex="3">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			[isReadonly]="true"
			field="c_support_staff_avg_sal"
			[showLastYear]="true"
			fieldType="currency"
			[control]="form.controls.c_support_staff_avg_sal"
			[cmsResult]="cmsResult">
			$ {{ form.controls.c_support_staff_avg_sal.value|currency:0 }}
		</rd-abs-submit-field>
		<rd-abs-submit-field
			field="c_stud_labor"
			[showLastYear]="true"
			[control]="form.controls.c_stud_labor"
			[cmsResult]="cmsResult">
			<div class="label__footer">
				<ng-container *ngIf="cmsResult.a_student_wages">
					<span class="note">Reminder: In Part A, you reported an annual budget of <span style="font-style: normal; text-decoration: underline;">${{ cmsResult.a_student_wages|currency:0 }}</span> for student wages.</span>
				</ng-container>
				<ng-container *ngIf="!cmsResult.a_student_wages">
					<span class="note">Note: You have not yet reported an annual budget for student wages in part A.</span>
				</ng-container>
			</div>
			<input rdNumberInput type="text" name="c_stud_labor" formControlName="c_stud_labor" (focus)="cmsResult.activeInput = 'c_stud_labor'" (blur)="cmsResult.activeInput = ''" maxlength="3" tabindex="4">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			[isReadonly]="true"
			field="c_stud_labor_avg_wage"
			[showLastYear]="true"
			[control]="form.controls.c_stud_labor_avg_wage"
			[cmsResult]="cmsResult">
			$ {{ form.controls.c_stud_labor_avg_wage.value|number:'0.2-2' }}
		</rd-abs-submit-field>
		<hr class="hr--thick">

		<!-- Salary -->
		<div [innerHtml]="cmsResult.salary"></div>
		<div class="grid__row column--labels">
			<div class="column--labels__last--year grid__col grid__col--3 grid__col-offset--8">
				Last Year
			</div>
		</div>
		<rd-abs-submit-field
			class="input--currency"
			field="c_adm_cnslr_start_sal"
			[showLastYear]="true"
			fieldType="currency"
			[control]="form.controls.c_adm_cnslr_start_sal"
			[cmsResult]="cmsResult">
			<input rdCurrencyInput type="text" name="c_adm_cnslr_start_sal" formControlName="c_adm_cnslr_start_sal" (focus)="cmsResult.activeInput = 'c_adm_cnslr_start_sal'" (blur)="cmsResult.activeInput = ''" maxlength="6" tabindex="5">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			[class.has-sibling-underneath]="form.controls.c_sr_cnslr_pos.value == 1"
			field="c_sr_cnslr_pos"
			[showLastYear]="true"
			[control]="form.controls.c_sr_cnslr_pos"
			[cmsResult]="cmsResult">
			<label class="label label--radio"><input type="radio" name="c_sr_cnslr_pos" formControlName="c_sr_cnslr_pos" (focus)="cmsResult.activeInput = 'c_sr_cnslr_pos'" (blur)="cmsResult.activeInput = ''" [value]="1" tabindex="6" class="radio"><span class="radio"></span> Yes</label>
			<label class="label label--radio"><input type="radio" class="radio" name="c_sr_cnslr_pos" formControlName="c_sr_cnslr_pos" (focus)="cmsResult.activeInput = 'c_sr_cnslr_pos'" (blur)="cmsResult.activeInput = ''" [value]="0" tabindex="7"><span class="radio"></span> No</label>
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			*ngIf="form.controls.c_sr_cnslr_pos.value == 1"
			field="c_sr_cnslr_sal"
			[showLastYear]="true"
			fieldType="currency"
			[control]="form.controls.c_sr_cnslr_sal"
			[cmsResult]="cmsResult">
			<input rdCurrencyInput type="text" name="c_sr_cnslr_sal" formControlName="c_sr_cnslr_sal" (focus)="cmsResult.activeInput = 'c_sr_cnslr_sal'" (blur)="cmsResult.activeInput = ''" maxlength="6" tabindex="8">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="has-sibling-underneath input--currency"
			field="c_asst_dir_sal"
			[showLastYear]="true"
			fieldType="currency"
			[control]="form.controls.c_asst_dir_sal"
			[cmsResult]="cmsResult">
			<input rdCurrencyInput type="text" name="c_asst_dir_sal" formControlName="c_asst_dir_sal" (focus)="cmsResult.activeInput = 'c_asst_dir_sal'" (blur)="cmsResult.activeInput = ''" maxlength="6" tabindex="9">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="has-sibling-underneath"
			field="c_asst_dir_yrs_adm"
			[showLastYear]="true"
			[control]="form.controls.c_asst_dir_yrs_adm"
			[cmsResult]="cmsResult">
			<input type="text" name="c_asst_dir_yrs_adm" formControlName="c_asst_dir_yrs_adm" (focus)="cmsResult.activeInput = 'c_asst_dir_yrs_adm'" (blur)="cmsResult.activeInput = ''" maxlength="2" tabindex="10">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			field="c_asst_dir_yrs_pos"
			[showLastYear]="true"
			[control]="form.controls.c_asst_dir_yrs_pos"
			[cmsResult]="cmsResult">
			<input type="text" name="c_asst_dir_yrs_pos" formControlName="c_asst_dir_yrs_pos" (focus)="cmsResult.activeInput = 'c_asst_dir_yrs_pos'" (blur)="cmsResult.activeInput = ''" maxlength="2" tabindex="11">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="has-sibling-underneath input--currency"
			field="c_assoc_dir_sal"
			[showLastYear]="true"
			fieldType="currency"
			[control]="form.controls.c_assoc_dir_sal"
			[cmsResult]="cmsResult">
			<input rdCurrencyInput type="text" name="c_assoc_dir_sal" formControlName="c_assoc_dir_sal" (focus)="cmsResult.activeInput = 'c_assoc_dir_sal'" (blur)="cmsResult.activeInput = ''" maxlength="6" tabindex="12">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="has-sibling-underneath"
			field="c_assoc_dir_yrs_adm"
			[showLastYear]="true"
			[control]="form.controls.c_assoc_dir_yrs_adm"
			[cmsResult]="cmsResult">
			<input type="text" name="c_assoc_dir_yrs_adm" formControlName="c_assoc_dir_yrs_adm" (focus)="cmsResult.activeInput = 'c_assoc_dir_yrs_adm'" (blur)="cmsResult.activeInput = ''" maxlength="2" tabindex="13">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			field="c_assoc_dir_yrs_pos"
			[showLastYear]="true"
			[control]="form.controls.c_assoc_dir_yrs_pos"
			[cmsResult]="cmsResult">
			<input type="text" name="c_assoc_dir_yrs_pos" formControlName="c_assoc_dir_yrs_pos" (focus)="cmsResult.activeInput = 'c_assoc_dir_yrs_pos'" (blur)="cmsResult.activeInput = ''" maxlength="2" tabindex="14">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="has-sibling-underneath input--currency"
			field="c_dir_adm_sal"
			[showLastYear]="true"
			fieldType="currency"
			[control]="form.controls.c_dir_adm_sal"
			[cmsResult]="cmsResult">
			<input rdCurrencyInput type="text" name="c_dir_adm_sal" formControlName="c_dir_adm_sal" (focus)="cmsResult.activeInput = 'c_dir_adm_sal'" (blur)="cmsResult.activeInput = ''" maxlength="7" tabindex="15">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="has-sibling-underneath"
			field="c_dir_adm_yrs_adm"
			[showLastYear]="true"
			[control]="form.controls.c_dir_adm_yrs_adm"
			[cmsResult]="cmsResult">
			<input type="text" name="c_dir_adm_yrs_adm" formControlName="c_dir_adm_yrs_adm" (focus)="cmsResult.activeInput = 'c_dir_adm_yrs_adm'" (blur)="cmsResult.activeInput = ''" maxlength="2" tabindex="16">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			field="c_dir_adm_yrs_pos"
			[showLastYear]="true"
			[control]="form.controls.c_dir_adm_yrs_pos"
			[cmsResult]="cmsResult">
			<input type="text" name="c_dir_adm_yrs_pos" formControlName="c_dir_adm_yrs_pos" (focus)="cmsResult.activeInput = 'c_dir_adm_yrs_pos'" (blur)="cmsResult.activeInput = ''" maxlength="2" tabindex="17">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="has-sibling-underneath"
			field="c_vpdean_title"
			[showLastYear]="true"
			[control]="form.controls.c_vpdean_title"
			[cmsResult]="cmsResult">
			<input type="text" name="c_vpdean_title" formControlName="c_vpdean_title" (focus)="cmsResult.activeInput = 'c_vpdean_title'" (blur)="cmsResult.activeInput = ''" maxlength="64" tabindex="18">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="has-sibling-underneath input--currency"
			field="c_vpdean_sal"
			[showLastYear]="true"
			fieldType="currency"
			[control]="form.controls.c_vpdean_sal"
			[cmsResult]="cmsResult">
			<input rdCurrencyInput type="text" name="c_vpdean_sal" formControlName="c_vpdean_sal" (focus)="cmsResult.activeInput = 'c_vpdean_sal'" (blur)="cmsResult.activeInput = ''" maxlength="7" tabindex="19">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="has-sibling-underneath"
			field="c_vpdean_yrs_adm"
			[showLastYear]="true"
			[control]="form.controls.c_vpdean_yrs_adm"
			[cmsResult]="cmsResult">
			<input type="text" name="c_vpdean_yrs_adm" formControlName="c_vpdean_yrs_adm" (focus)="cmsResult.activeInput = 'c_vpdean_yrs_adm'" (blur)="cmsResult.activeInput = ''" maxlength="2" tabindex="20">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			field="c_vpdean_yrs_pos"
			[showLastYear]="true"
			[control]="form.controls.c_vpdean_yrs_pos"
			[cmsResult]="cmsResult">
			<input type="text" name="c_vpdean_yrs_pos" formControlName="c_vpdean_yrs_pos" (focus)="cmsResult.activeInput = 'c_vpdean_yrs_pos'" (blur)="cmsResult.activeInput = ''" maxlength="2" tabindex="21">
		</rd-abs-submit-field>
		<hr class="hr--thick">

		<!-- Qualifications -->
		<div [innerHtml]="cmsResult.qualifications"></div>
		<div class="grid__row column--labels">
			<div class="column--labels__last--year grid__col grid__col--3 grid__col-offset--8">
				Last Year
			</div>
		</div>
		<rd-abs-submit-field
			field="c_min_edu_adm_cnslr"
			[showLastYear]="true"
			[lastYearValue]="cmsResult.getPreviousDegreeLabel('c_min_edu_adm_cnslr')"
			[control]="form.controls.c_min_edu_adm_cnslr"
			[cmsResult]="cmsResult">
			<select name="c_min_edu_adm_cnslr" formControlName="c_min_edu_adm_cnslr" (focus)="cmsResult.activeInput = 'c_min_edu_adm_cnslr'" (blur)="cmsResult.activeInput = ''" tabindex="26">
				<option>Select:</option>
				<option value="1">Bachelor's</option>
				<option value="2">Master's</option>
				<option value="3">Doctorate</option>
				<option value="4">Other</option>
			</select>
		</rd-abs-submit-field>
		<rd-abs-submit-field
				field="c_min_edu_asst_dir"
				[showLastYear]="true"
				[lastYearValue]="cmsResult.getPreviousDegreeLabel('c_min_edu_asst_dir')"
				[control]="form.controls.c_min_edu_asst_dir"
				[cmsResult]="cmsResult">
			<select name="c_min_edu_asst_dir" formControlName="c_min_edu_asst_dir" (focus)="cmsResult.activeInput = 'c_min_edu_asst_dir'" (blur)="cmsResult.activeInput = ''" tabindex="27">
				<option>Select:</option>
				<option value="1">Bachelor's</option>
				<option value="2">Master's</option>
				<option value="3">Doctorate</option>
				<option value="4">Other</option>
			</select>
		</rd-abs-submit-field>
		<rd-abs-submit-field
				field="c_min_edu_dir"
				[showLastYear]="true"
				[lastYearValue]="cmsResult.getPreviousDegreeLabel('c_min_edu_dir')"
				[control]="form.controls.c_min_edu_dir"
				[cmsResult]="cmsResult">
			<select name="c_min_edu_dir" formControlName="c_min_edu_dir" (focus)="cmsResult.activeInput = 'c_min_edu_dir'" (blur)="cmsResult.activeInput = ''" tabindex="28">
				<option>Select:</option>
				<option value="1">Bachelor's</option>
				<option value="2">Master's</option>
				<option value="3">Doctorate</option>
				<option value="4">Other</option>
			</select>
		</rd-abs-submit-field>
		<rd-abs-submit-field
				field="c_min_edu_vpdean"
				[showLastYear]="true"
				[lastYearValue]="cmsResult.getPreviousDegreeLabel('c_min_edu_vpdean')"
				[control]="form.controls.c_min_edu_vpdean"
				[cmsResult]="cmsResult">
			<select name="c_min_edu_vpdean" formControlName="c_min_edu_vpdean" (focus)="cmsResult.activeInput = 'c_min_edu_vpdean'" (blur)="cmsResult.activeInput = ''" tabindex="29" placeholder="Select:">
				<option>Select:</option>
				<option value="1">Bachelor's</option>
				<option value="2">Master's</option>
				<option value="3">Doctorate</option>
				<option value="4">Other</option>
			</select>
		</rd-abs-submit-field>

		<rd-alert type="danger" *ngIf="error && error.message">
			<h4>Error</h4>
			<p>{{ error.message }}</p>
		</rd-alert>

		<ng-container *ngIf="canSubmit()">
			<p>&nbsp;</p>
			<p>Click the button below to save this part of the form and move on to Part D. You can always return to this part of the form to make adjustments before submitting your final data. When you are satisfied with all data fields and ready to submit to NACCAP for validation, click through to Part D, where you’ll find the Submit Final Data button. You will not be able to alter your data for this study after final submission.</p>
			<p><input class="btn btn--gold" type="submit" name="submit" tabindex="16" [disabled]="isSubmitting" value="Save and Continue" (click)="save()" /></p>
		</ng-container>

		<rd-abs-submit-comment part="c" [entryId]="cmsResult.entryId" [user]="sessionUser" [cmsResult]="cmsResult"></rd-abs-submit-comment>
	</ng-container>

	<rd-alert type="warning" *ngIf="!hasReadPermission()">
		<h4>Inadequate Access Level</h4>
		<p>Your user account does not have the permissions required to view or input the data on this page, which is of a sensitive nature. If you feel this is an error and would like to request a permissions modification on your account, please e-mail Tim Fuller, NACCAP Research Project Leader, at <a href="mailto:tim@fullerhes.com">tim@fullerhes.com</a>.
		<p>You may return to <a [routerLink]="cmsResult.urls['abs-submit-part-a']" queryParamsHandling="merge">Part A</a> or <a [routerLink]="cmsResult.urls['abs-submit-part-b']" queryParamsHandling="merge">Part B</a>, or continue on to <a [routerLink]="cmsResult.urls['abs-submit-part-d']" queryParamsHandling="merge">Part D</a>, if you like.
	</rd-alert>
</rd-abs-submit-wrapper>
