<h2>{{ cmsResult.org.org }}</h2>
<p *ngIf="cmsResult.reportEntryUser && cmsResult.reportEntryUser.fullname">Last edit made by {{ cmsResult.reportEntryUser.fullname }}</p>
<p>Open for submission: {{ cmsResult.report.open|date:'mediumDate':'utc' }} to {{ cmsResult.report.close|date:'mediumDate':'utc' }}</p>

<!-- tabs/links -->
<div class="link--tabs">
	<a class="link--tab" routerLinkActive="link--tab-active" [routerLink]="cmsResult.urls['abs-submit-part-a']" queryParamsHandling="merge">Part A <div class="link--tab__subtitle">{{ cmsResult.report.year - 1 }}-{{ cmsResult.report.year }} Budget</div></a>
	<a class="link--tab" routerLinkActive="link--tab-active" [routerLink]="cmsResult.urls['abs-submit-part-b']" queryParamsHandling="merge">Part B <div class="link--tab__subtitle">Enrollment Data</div></a>
	<a class="link--tab" routerLinkActive="link--tab-active" [routerLink]="cmsResult.urls['abs-submit-part-c']" queryParamsHandling="merge">Part C <div class="link--tab__subtitle">Staffing & Salary Data</div></a>
	<a class="link--tab" routerLinkActive="link--tab-active" [routerLink]="cmsResult.urls['abs-submit-part-d']" queryParamsHandling="merge">Part D <div class="link--tab__subtitle">Funnel Numbers</div></a>

	<a onclick="window.print();" class="btn btn--gold link--tabs__button">Print Form with Instructions</a>


	<div class="card">
		<div class="card__content">
			<h2>{{ subtitle }}</h2>
			<ng-content select="rd-abs-submit-save-messages"></ng-content>
			<ng-content select=".before__grid"></ng-content>
	
			<form class="form form--abs">
				<div class="grid">
					<div class="grid__row">
						<div class="grid__col grid__col-xl--8">
							<ng-content></ng-content>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>