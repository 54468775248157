import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CmsEntryResultFunnelLanding } from '@app/cms/results/cms-entry-result-funnel-landing';
import { AccountService } from '@app/account/account.service';
import { Account } from '@app/account/account';
import { ReportFilters } from '@app/cms/funnel-report-filter/funnel-report-filter.component';

@Component({
	selector: 'rd-cms-entry-funnel-landing',
	templateUrl: './cms-entry-funnel-landing.component.html'
})
export class CmsEntryFunnelLandingComponent implements OnInit, OnChanges {
	@Input()
	cmsResult: CmsEntryResultFunnelLanding;

	@Input()
	sessionUser: Account;

	page = 1;

	filters: ReportFilters = {};
	defaults: ReportFilters = {};

	constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService) {}

	ngOnInit() {
		this.defaults = {
			reportId: this.route.snapshot.queryParams.id || this.cmsResult.mostRecentReport.id,
			search: this.route.snapshot.queryParams.search
		};
		this.page = this.route.snapshot.queryParams.page;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.cmsResult) {
			this.cmsResult.paginatedReports.items.map((item: any) => {
				item._is_on_watchlist = this.cmsResult.watchlist.indexOf(item.naccap_orgs_id) > -1;
				return item;
			});
		}
	}

	get reportId(): number {
		return this.filters.reportId || this.defaults.reportId!;
	}

	get search(): string {
		return this.filters.search || '';
	}

	get isVerifiedToViewResults(): boolean {
		return this.sessionUser.isAdmin || this.cmsResult.isEntryVerified;
	}

	export() {
		this.accountService.exportFunnel(this.cmsResult.report.id);
	}

	exportFinal() {
		this.accountService.exportFunnelFinal(this.cmsResult.report.id);
	}

	onPageChanged(page: number) {
		this.page = page;
		this.updatePage();
	}

	onFilterUpdate(filters: ReportFilters) {
		this.filters = filters;
		this.page = 1;
		this.updatePage();
	}

	updatePage() {
		const page = this.page;
		const search = this.search;
		const id = this.reportId;
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { page, search, id }
		});
	}

	onWatchlistChanged(report: any) {
		if (report._is_on_watchlist) {
			return this.accountService.addOrgToWatchlist(this.sessionUser.userId, report.naccap_orgs_id);
		}
		return this.accountService.removeOrgFromWatchlist(this.sessionUser.userId, report.naccap_orgs_id);
	}
}
