<div class="grid__row funnel--submit">
	<div class="grid__col--12">
		<h2>
			<span *ngIf="!cmsResult.report.isFinalReport">{{ cmsResult.report.monthLabel }} {{ cmsResult.report.reportYear }}
			vs.
			{{ cmsResult.report.monthLabel }} {{ cmsResult.report.reportYear - 1 }}</span>
			<span *ngIf="cmsResult.report.isFinalReport">Final Report, Fall {{ cmsResult.report.reportYear }}</span>
		</h2>
		<h4 *ngIf="!cmsResult.report.isFinalReport">Fall {{ cmsResult.report.reportYear }} Enrollment Year, {{ cmsResult.report.periodLabel }} Report</h4>
		<h4 *ngIf="cmsResult.existingStatus == 0">Open for submission: {{ cmsResult.report.open|date:'mediumDate':'utc' }} to {{ cmsResult.report.close|date:'mediumDate':'utc' }}</h4>

		<ng-container *ngIf="hasReadPermission(); else noAccess">
			<rd-alert type="warning" *ngIf="!cmsResult.isOpen && !isSpecificReportLookup">
				<h4>No Report Open for Submission</h4>
				<p>There is no application report open for submission at this time. Please return when a report is open to enter your data.</p>
			</rd-alert>
			
			<rd-alert type="warning" *ngIf="isSaved && cmsResult.existingStatus == 0">
				<h4>Your Data Has Been Updated</h4>
				<p>Please be sure to return before the submission window closes to submit your final data.</p>
			</rd-alert>
			<rd-alert type="success" *ngIf="cmsResult.existingStatus == 1">
				<h4>Final Data Has Been Submitted</h4>
				<p>You are no longer able to update your data for this report. You will be notified via e-mail when your submission has been validated and you are granted access to view data for this report.</p>
			</rd-alert>
			<rd-alert type="success" *ngIf="cmsResult.existingStatus == 2">
				<h4>Your Data Has Been Validated</h4>
				<p>You may now <a [routerLink]="urls['funnel-landing']" [queryParams]="{id: cmsResult.report.id}">view data for this report</a></p>
			</rd-alert>
			
			
			<ng-container *ngIf="!isSaved">
				<div class="card">
					<div class="card__content">
						<h2>{{ cmsResult.orgName }}</h2>
						<p>Please see the definitions at the bottom of this page to ensure you are entering data correctly for each stage of the funnel.  As a reminder, each subsequent level of the funnel is a subset of the previous one (as in every application is also an inquiry, every completed application was an application, every deposit was an admit, etc.) and remember to submit gross application, admit, and deposit numbers (not net).</p>
						<rd-alert *ngIf="canSubmit()" type="info"><em>Please note:</em> traditional students only, new freshman and transfers only (no readmits), and please include your Fall {{ cmsResult.report.reportYear }} new student enrollment goal for new freshman and transfers only.</rd-alert>
				
						<rd-alert type="warning" *ngIf="cmsResult.isPrepopulated">
							<h4>Previous Year's Data Found</h4>
							<p>We have located data that you submitted for last year's applications report and prepopulated it into the relevant fields. If any of these numbers have been corrected since you entered that data, feel free to edit the fields.</p> 
							<p>After you have saved the form once, this message will go away.</p>
						</rd-alert>
				
						<form [formGroup]="form">
							<div class="table--responsive">
								<table class="table">
									<thead>
										<tr class="th--centered">
											<th rowspan="2">&nbsp;</th>
											<th colspan="2">Applicants</th>
											<th colspan="2">Admits</th>
											<th *ngIf="!cmsResult.report.isFinalReport" colspan="2">Deposits</th>
											<th *ngIf="cmsResult.report.isFinalReport" colspan="2">Total Deposits Received</th>
											<th *ngIf="cmsResult.report.isFinalReport" colspan="2">Deposits Canceled After May 1</th>
											<th *ngIf="cmsResult.report.isFinalReport" colspan="2">Enrollments</th>
										</tr>
										<tr class="th--centered">
											<th>Fall {{ cmsResult.report.reportYear }}</th>
											<th>Fall {{ cmsResult.report.reportYear - 1 }}</th>
											<th>Fall {{ cmsResult.report.reportYear }}</th>
											<th>Fall {{ cmsResult.report.reportYear - 1 }}</th>
											<th>Fall {{ cmsResult.report.reportYear }}</th>
											<th>Fall {{ cmsResult.report.reportYear - 1 }}</th>
											<th *ngIf="cmsResult.report.isFinalReport">Fall {{ cmsResult.report.reportYear }}</th>
											<th *ngIf="cmsResult.report.isFinalReport">Fall {{ cmsResult.report.reportYear - 1 }}</th>
											<th *ngIf="cmsResult.report.isFinalReport">Fall {{ cmsResult.report.reportYear }}</th>
											<th *ngIf="cmsResult.report.isFinalReport">Fall {{ cmsResult.report.reportYear - 1 }}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th>Freshmen</th>
											<td> <input type="text" name="fr_app_curr" maxlength="5" formControlName="fr_app_curr" [class.error]="hasControlError('fr_app_curr') || hasBaseFormError('fr_app_curr')"/> </td>
											<td> <input type="text" name="fr_app_prev" maxlength="5" formControlName="fr_app_prev" [class.error]="hasControlError('fr_app_prev') || hasBaseFormError('fr_app_prev')"/> </td>
											<td> <input type="text" name="fr_adm_curr" maxlength="5" formControlName="fr_adm_curr" [class.error]="hasControlError('fr_adm_curr') || hasBaseFormError('fr_app_curr') || hasBaseFormError('fr_adm_curr') || hasBaseFormError('fr_enr_curr')"/> </td>
											<td> <input type="text" name="fr_adm_prev" maxlength="5" formControlName="fr_adm_prev" [class.error]="hasControlError('fr_adm_prev') || hasBaseFormError('fr_app_prev') || hasBaseFormError('fr_adm_prev') || hasBaseFormError('fr_enr_prev')"/> </td>
											<td> <input type="text" name="fr_dep_curr" maxlength="5" formControlName="fr_dep_curr" [class.error]="hasControlError('fr_dep_curr') || hasBaseFormError('fr_adm_curr')"/> </td>
											<td> <input type="text" name="fr_dep_prev" maxlength="5" formControlName="fr_dep_prev" [class.error]="hasControlError('fr_dep_prev') || hasBaseFormError('fr_adm_prev')"/> </td>
											<td *ngIf="cmsResult.report.isFinalReport"> <input type="text" name="fr_dep_canceled_curr" maxlength="5" formControlName="fr_dep_canceled_curr" [class.error]="hasControlError('fr_dep_canceled_curr') || hasBaseFormError('fr_dep_canceled_curr')"/> </td>
											<td *ngIf="cmsResult.report.isFinalReport"> <input type="text" name="fr_dep_canceled_prev" maxlength="5" formControlName="fr_dep_canceled_prev" [class.error]="hasControlError('fr_dep_canceled_prev') || hasBaseFormError('fr_dep_canceled_prev')"/> </td>
											<td *ngIf="cmsResult.report.isFinalReport"> <input type="text" name="fr_enr_curr" maxlength="5" formControlName="fr_enr_curr" [class.error]="hasControlError('fr_enr_curr') || hasBaseFormError('fr_enr_curr')"/> </td>
											<td *ngIf="cmsResult.report.isFinalReport"> <input type="text" name="fr_enr_prev" maxlength="5" formControlName="fr_enr_prev" [class.error]="hasControlError('fr_enr_prev') || hasBaseFormError('fr_enr_prev')"/> </td>
										</tr>
										<tr *ngIf="hasBaseFormError('fr_app_curr') || hasBaseFormError('fr_adm_curr') || hasBaseFormError('fr_app_prev') || hasBaseFormError('fr_adm_prev') || hasBaseFormError('fr_enr_curr') || hasBaseFormError('fr_enr_prev')">
											<td [attr.colspan]="cmsResult.report.isFinalReport ? 11 : 7">
												<rd-alert type="danger" *ngIf="hasBaseFormError('fr_app_curr')">
													You cannot have more admits than applicants for Fall {{ cmsResult.report.reportYear }}
												</rd-alert>
												<rd-alert type="danger" *ngIf="hasBaseFormError('fr_app_prev')">
													You cannot have more admits than applicants for Fall {{ cmsResult.report.reportYear - 1 }}
												</rd-alert>
												<rd-alert type="danger" *ngIf="hasBaseFormError('fr_adm_curr')">
													You cannot have more deposits than admits for Fall {{ cmsResult.report.reportYear }}
												</rd-alert>
												<rd-alert type="danger" *ngIf="hasBaseFormError('fr_adm_prev')">
													You cannot have more deposits than admits for Fall {{ cmsResult.report.reportYear - 1 }}
												</rd-alert>
												<rd-alert type="danger" *ngIf="hasBaseFormError('fr_enr_curr')">
													You cannot have more enrollments than admits for Fall {{ cmsResult.report.reportYear }}
												</rd-alert>
												<rd-alert type="danger" *ngIf="hasBaseFormError('fr_enr_prev')">
													You cannot have more enrollments than admits for Fall {{ cmsResult.report.reportYear - 1 }}
												</rd-alert>
											</td>
										</tr>
										<tr>
											<th>Transfers</th>
											<td> <input type="text" name="fr_app_curr" maxlength="5" formControlName="tr_app_curr" [class.error]="hasControlError('tr_app_curr') || hasBaseFormError('tr_app_curr')"/> </td>
											<td> <input type="text" name="tr_app_prev" maxlength="5" formControlName="tr_app_prev" [class.error]="hasControlError('tr_app_prev') || hasBaseFormError('tr_app_prev')"/> </td>
											<td> <input type="text" name="tr_adm_curr" maxlength="5" formControlName="tr_adm_curr" [class.error]="hasControlError('tr_adm_curr') || hasBaseFormError('tr_app_curr') || hasBaseFormError('tr_adm_curr') || hasBaseFormError('tr_enr_curr')"/> </td>
											<td> <input type="text" name="tr_adm_prev" maxlength="5" formControlName="tr_adm_prev" [class.error]="hasControlError('tr_adm_prev') || hasBaseFormError('tr_app_prev') || hasBaseFormError('tr_adm_prev') || hasBaseFormError('tr_enr_prev')"/> </td>
											<td> <input type="text" name="tr_dep_curr" maxlength="5" formControlName="tr_dep_curr" [class.error]="hasControlError('tr_dep_curr') || hasBaseFormError('tr_adm_curr')"/> </td>
											<td> <input type="text" name="tr_dep_prev" maxlength="5" formControlName="tr_dep_prev" [class.error]="hasControlError('tr_dep_prev') || hasBaseFormError('tr_adm_prev')"/> </td>
											<td *ngIf="cmsResult.report.isFinalReport"> <input type="text" name="tr_dep_canceled_curr" maxlength="5" formControlName="tr_dep_canceled_curr" [class.error]="hasControlError('tr_dep_canceled_curr') || hasBaseFormError('tr_dep_canceled_curr')"/> </td>
											<td *ngIf="cmsResult.report.isFinalReport"> <input type="text" name="tr_dep_canceled_prev" maxlength="5" formControlName="tr_dep_canceled_prev" [class.error]="hasControlError('tr_dep_canceled_prev') || hasBaseFormError('tr_dep_canceled_prev')"/> </td>
											<td *ngIf="cmsResult.report.isFinalReport"> <input type="text" name="tr_enr_curr" maxlength="5" formControlName="tr_enr_curr" [class.error]="hasControlError('tr_enr_curr') || hasBaseFormError('tr_enr_curr')"/> </td>
											<td *ngIf="cmsResult.report.isFinalReport"> <input type="text" name="tr_enr_prev" maxlength="5" formControlName="tr_enr_prev" [class.error]="hasControlError('tr_enr_prev') || hasBaseFormError('tr_enr_prev')"/> </td>
										</tr>
										<tr *ngIf="hasBaseFormError('tr_app_curr') || hasBaseFormError('tr_adm_curr') || hasBaseFormError('tr_app_prev') || hasBaseFormError('tr_adm_prev') || hasBaseFormError('tr_enr_curr') || hasBaseFormError('tr_enr_prev')">
											<td [attr.colspan]="cmsResult.report.isFinalReport ? 11 : 7">
												<rd-alert type="danger" *ngIf="hasBaseFormError('tr_app_curr')">
													You cannot have more admits than applicants for Fall {{ cmsResult.report.reportYear }}
												</rd-alert>
												<rd-alert type="danger" *ngIf="hasBaseFormError('tr_app_prev')">
													You cannot have more admits than applicants for Fall {{ cmsResult.report.reportYear - 1 }}
												</rd-alert>
												<rd-alert type="danger" *ngIf="hasBaseFormError('tr_adm_curr')">
													You cannot have more deposits than admits for Fall {{ cmsResult.report.reportYear }}
												</rd-alert>
												<rd-alert type="danger" *ngIf="hasBaseFormError('tr_adm_prev')">
													You cannot have more deposits than admits for Fall {{ cmsResult.report.reportYear - 1 }}
												</rd-alert>
												<rd-alert type="danger" *ngIf="hasBaseFormError('tr_enr_curr')">
													You cannot have more enrollments than admits for Fall {{ cmsResult.report.reportYear }}
												</rd-alert>
												<rd-alert type="danger" *ngIf="hasBaseFormError('tr_enr_prev')">
													You cannot have more enrollments than admits for Fall {{ cmsResult.report.reportYear - 1 }}
												</rd-alert>
											</td>
										</tr>
										<tr class="tr--last">
											<th>Totals</th>
											<td> <input type="text" name="tns_app_curr" maxlength="6" formControlName="tns_app_curr" /> </td>
											<td> <input type="text" name="tns_app_prev" maxlength="6" formControlName="tns_app_prev" /> </td>
											<td> <input type="text" name="tns_adm_curr" maxlength="6" formControlName="tns_adm_curr" /> </td>
											<td> <input type="text" name="tns_adm_prev" maxlength="6" formControlName="tns_adm_prev" /> </td>
											<td> <input type="text" name="tns_dep_curr" maxlength="6" formControlName="tns_dep_curr" /> </td>
											<td> <input type="text" name="tns_dep_prev" maxlength="6" formControlName="tns_dep_prev" /> </td>
											<td *ngIf="cmsResult.report.isFinalReport"> <input type="text" name="tns_dep_canceled_curr" maxlength="6" formControlName="tns_dep_canceled_curr" /> </td>
											<td *ngIf="cmsResult.report.isFinalReport"> <input type="text" name="tns_dep_canceled_prev" maxlength="6" formControlName="tns_dep_canceled_prev" /> </td>
											<td *ngIf="cmsResult.report.isFinalReport"> <input type="text" name="tns_dep_canceled_curr" maxlength="6" formControlName="tns_enr_curr" /> </td>
											<td *ngIf="cmsResult.report.isFinalReport"> <input type="text" name="tns_enr_prev" maxlength="6" formControlName="tns_enr_prev" /> </td>
										</tr>
									</tbody>
								</table>
							</div>
				
							<h2>Enrollment</h2>
							<fieldset *ngIf="useOriginalGoalFeature" class="input--inline">
								<ng-container *ngIf="cmsResult.original_goal">
									<p>
										<label *ngIf="!updateOriginalGoal"><input type="text" name="original_goal" size="4" maxlength="4" formControlName="original_goal" readonly />
											<span *ngIf="cmsResult.original_goal.modified" class="original_goal_mini_header"> (Original goal) </span>
											Your goal for the number of new freshman and transfer students you <em>plan to</em> enroll for <strong>Fall {{ cmsResult.report.reportYear }}</strong>.
											<button *ngIf="sessionUser.isAdmin && !updateOriginalGoal" class="btn btn-default" (click)="updateOriginalGoalState()">Change Original goal</button>
										</label>

										<label class="field-curr_goal" *ngIf="updateOriginalGoal">
											<input type="hidden" name="original_goal_edit_enabled" formControlName="original_goal_edit_enabled" />
											<input type="text" name="original_goal_edit" maxlength="4" formControlName="original_goal_edit" class="ms-0" />Modify original goal for <strong>Fall {{ cmsResult.report.reportYear }}</strong>.
										</label>
									</p>
									<button *ngIf="!updateGoal && (!cmsResult.original_goal || !cmsResult.original_goal.modified)" class="btn btn-default" (click)="updateGoalState()">Change this goal</button>
									<p *ngIf="cmsResult.original_goal && cmsResult.original_goal.modified && !updateGoal">
										<label><input type="text" name="modified_goal" size="4" maxlength="4" formControlName="modified_goal" readonly />
											Modified goal on {{ cmsResult.original_goal.modified.created_at | date:'longDate' }}.
											<button *ngIf="!updateGoal" class="btn btn-default" (click)="updateGoalState()">Change this goal</button>
										</label>
									</p>
								</ng-container>
								<label *ngIf="!cmsResult.original_goal"><input type="text" name="new_goal" maxlength="4" formControlName="new_goal" />Number of new freshman and transfer students you <em>plan to</em> enroll for <strong>Fall {{ cmsResult.report.reportYear }}</strong>.</label>
								<label class="field-curr_goal" *ngIf="updateGoal">
									<input type="text" name="new_goal" maxlength="4" formControlName="new_goal" />Modify your goal for <strong>Fall {{ cmsResult.report.reportYear }}</strong>.
								</label>


								<hr>
								<br>
								<label *ngIf="!cmsResult.report.isFinalReport" class="field-prev_actual">
									<input type="text" name="prev_actual" maxlength="4" formControlName="prev_actual" />
									<strong>Actual number</strong> of new freshman and transfer students you <em>enrolled</em> in <strong>Fall {{ cmsResult.report.reportYear - 1 }}</strong>.
								</label>
							</fieldset>

							<fieldset *ngIf="!useOriginalGoalFeature" class="input--inline">
								<ng-container *ngIf="!cmsResult.report.isFinalReport">
									<label class="field-curr_goal">
										<input type="text" name="curr_goal" maxlength="4" formControlName="curr_goal" />Number of new freshman and transfer students you <em>plan to</em> enroll for <strong>Fall {{ cmsResult.report.reportYear }}</strong>.
									</label>
									<br>
								</ng-container>
								<label class="field-prev_actual">
									<input type="text" name="prev_actual" maxlength="4" formControlName="prev_actual" />
									<ng-container *ngIf="cmsResult.report.isFinalReport">
										<strong>Original goal</strong> for Fall {{ cmsResult.report.reportYear }} new student enrollment.
									</ng-container>
									<ng-container *ngIf="!cmsResult.report.isFinalReport">
										<strong>Actual number</strong> of new freshman and transfer students you <em>enrolled</em> in <strong>Fall {{ cmsResult.report.reportYear - 1 }}</strong>.
									</ng-container>
								</label>
							</fieldset>
							
							<rd-alert type="warning" *ngIf="hasIncompleteData">
								<h4>Incomplete Data</h4>
								<p>You have attempted to submit final data with some fields left empty. Please fill out as many fields as you possibly can. If you still wish to submit your final data with empty fields, please enter a short explanation below and then submit again.</p>
								<input type="text" name="incomplete_explanation" formControlName="incomplete_explanation" maxlength="256" />
							</rd-alert>
								
							<ng-container *ngIf="canSubmit()">
								<h2>Submit</h2>
								
								<p class="important">You can save a partially completed form and return to this page any time within the submission window to complete and submit. When you are satisfied with all data fields and ready to submit to NACCAP for validation, click the <b>Submit Final Data</b> button.</p>
								<rd-alert type="info"><p>You will not be able to alter your data for this report after final submission.</p></rd-alert>
								<input name="submit_final" class="btn btn--gold" type="submit" (click)="submitFinal()" [disabled]="isSubmitting" value="Submit Final Data" />
								&nbsp;
								<input name="submit_progress" class="btn btn--ghost btn--ghost--gold" type="submit" (click)="submitProgress()" [disabled]="isSubmitting" value="Save Form in Progress" /> 				 
							</ng-container>
				
							<rd-alert type="danger" *ngIf="error && error.message">
								<h4>Error</h4>
								<p>{{ error.message }}</p>
							</rd-alert>
			
							
							<ng-container *ngIf="sessionUser.isAdmin && cmsResult.onHoldMessages && cmsResult.onHoldMessages.length">
								<h3>On Hold Messages (only visible to Admin)</h3>
								<rd-alert type="info" *ngFor="let message of cmsResult.onHoldMessages">
									<h4>{{ message.created_at|date:'medium' }}</h4>
									<div [innerHTML]="message.message"></div>
								</rd-alert>
							</ng-container>

							<ng-container *ngIf="sessionUser.isAdmin && cmsResult.existingStatus > 0">
								<rd-admin-report-actions type="funnel" [user]="sessionUser" [entryId]="cmsResult.entryId" [orgId]="cmsResult.orgId" [reportId]="cmsResult.reportId" (onSave)="onAdminReportActions($event)"></rd-admin-report-actions>
							</ng-container>
						</form>
					</div> <!-- end of card__content -->
				</div> <!-- end of card -->
			</ng-container>
			<div class="card--tan" style="margin-top: 40px;">
				<div class="card__content">
					<h2>Definitions</h2>
					<div class="grid__row">
						<div class="grid__col--3">
							<h3>Applicant</h3>
							<p>Has submitted an application for admission (doesn’t count if they’ve submitted a piece of the application process and not the app itself) regardless of the outcome of that application. This should be a cumulative total of all your applications for each category (first-year or transfer).</p>
						</div>
						<div class="grid__col--3 grid__col-offset--1">
							<h3>Admit</h3>
							<p>Has been granted admission regardless of whether or not they have accepted that offer. This should be the cumulative total of all your admitted students for each category (first-year or transfer).</p>
						</div>
						<div *ngIf="!cmsResult.report.isFinalReport" class="grid__col--3 grid__col-offset--1">
							<h3>Deposit</h3>
							<p>Has paid their enrollment deposit regardless of whether or not they are still planning to enroll. This should be the cumulative total of all of your deposited students for each category (first-year or transfer).</p>
						</div>
						<div *ngIf="cmsResult.report.isFinalReport" class="grid__col--3 grid__col-offset--1">
							<h3>Total Deposits Received</h3>
							<p>Please include all deposits received throughout the recruitment cycle including those canceled before and after May 1st.</p>
							<h3>Deposits Canceled After May 1</h3>
							<p>Please include all deposits canceled after May 1 for students who ultimately did not enroll. The date on which they paid their deposit is irrelevant to this data point.</p>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-template #noAccess>
			<rd-alert type="warning">
				<h4>Your Data for this Study Has Not Been Validated</h4>
				<p>You cannot view other schools' data unless your own data has been validated.</p>
			</rd-alert>
		</ng-template>
	</div>
</div> <!-- end of grid__row -->
