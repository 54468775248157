import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AccountService } from '@app/account/account.service';
import { CmsEntryResult } from '@app/cms/results/cms-entry-result';
import { Account } from '@app/account/account';

@Component({
	selector: 'rd-cms-entry-my-account',
	templateUrl: './cms-entry-my-account.component.html'
})
export class CmsEntryMyAccountComponent implements OnInit {
	@Input()
	cmsResult: CmsEntryResult;

	@Input()
	sessionUser: Account;

	form?: FormGroup;
	passwordForm?: FormGroup;
	error: { message: string } | null = null;
	passwordError: { message: string } | null = null;
	saved = false;
	passwordSaved = false;

	constructor(private accountService: AccountService, private formBuilder: FormBuilder) {}

	createForm() {
		this.form = this.formBuilder.group({
			username: [this.sessionUser.username, [Validators.required]],
			email: [this.sessionUser.email, [Validators.required, Validators.email]]
		});

		this.passwordForm = this.formBuilder.group({
			old_password: ['', Validators.required],
			new_password: ['', Validators.required],
			confirm_new_password: ['', Validators.required]
		});
	}

	ngOnInit(): void {
		this.createForm();
	}

	onSubmit(): void {
		this.error = null;
		this.saved = false;
		if (this.form!.invalid) {
			this.error = { message: 'Fix errors and try again.' };
			return;
		}
		this.sessionUser.username = this.form!.value.username;
		this.sessionUser.email = this.form!.value.email;
		this.accountService
			.saveAccount(this.sessionUser, false)
			.then(() => {
				this.saved = true;
			})
			.catch((data: any) => {
				this.error = { message: data.error.error.message };
			});
	}

	updatePassword(): void {
		this.passwordError = null;
		this.passwordSaved = false;
		if (this.passwordForm!.invalid) {
			this.passwordError = { message: 'Fix errors and try again.' };
			return;
		}
		const values = this.passwordForm!.value;
		this.accountService
			.updatePassword(this.sessionUser.userId, values.old_password, values.new_password, values.confirm_new_password)
			.then(() => {
				this.passwordSaved = true;
			})
			.catch((data: any) => {
				if (data.message) {
					this.passwordError = { message: data.message };
					return;
				}
				this.passwordError = { message: data.error.error.message };
			});
	}
}
