import { Component, Input } from '@angular/core';

import { CmsEntryResult404 } from '@app/cms/results/cms-entry-result-404';
import { Account } from '@app/account/account';

@Component({
	selector: 'rd-cms-entry-404',
	templateUrl: './cms-entry-404.component.html'
})
export class CmsEntry404Component {
	constructor() {}

	@Input()
	cmsResult: CmsEntryResult404;

	@Input()
	sessionUser: Account;
}
