import { deserialize, inheritSerialization } from 'cerialize';

import { CmsEntryResult } from './cms-entry-result';

@inheritSerialization(CmsEntryResult)
export class CmsEntryResultAdminAbsTool extends CmsEntryResult {
	@deserialize
	report: any;

	@deserialize
	participants: any;

	@deserialize
	enrollmentSort: any;

	@deserialize
	completedAbsReports: any;

	@deserialize
	allAbsReports: any;

	@deserialize
	averages: any;

	@deserialize
	funnelAverages: any;
}
