import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class FunnelEntryService {
	constructor(private http: HttpClient) {}

	create(data: any): Promise<any> {
		return this.http.post<any>(`${environment.apiUrl}/crud/funnel-entry`, data).toPromise();
	}

	update(data: any): Promise<any> {
		return this.http.patch<any>(`${environment.apiUrl}/crud/funnel-entry`, data).toPromise();
	}

	getLatestOpenReport(): Promise<any> {
		return this.http.get<any>(`${environment.apiUrl}/crud/funnel/latest-open`).toPromise();
	}

	sendConfirmationEmail(user_id: number, report_id: number): Promise<any> {
		return this.http
			.post<any>(`${environment.apiUrl}/crud/email/funnel-confirmation`, { user_id, report_id })
			.toPromise();
	}

	sendAdminEmail(user_id: number, report_id: number): Promise<any> {
		return this.http.post<any>(`${environment.apiUrl}/crud/email/funnel-completed`, { user_id, report_id }).toPromise();
	}

	sendRejectedEmail(org_id: number, report_id: number, reason: string): Promise<any> {
		return this.http
			.post<any>(`${environment.apiUrl}/crud/email/funnel-rejected`, { org_id, report_id, reason })
			.toPromise();
	}

	sendAcceptedEmail(org_id: number, report_id: number): Promise<any> {
		return this.http.post<any>(`${environment.apiUrl}/crud/email/funnel-accepted`, { org_id, report_id }).toPromise();
	}

	sendVerifiedEmail(user_id: number, report_id: number, org_id: number): Promise<any> {
		return this.http
			.post<any>(`${environment.apiUrl}/crud/email/funnel-verified`, { user_id, report_id, org_id })
			.toPromise();
	}
}
