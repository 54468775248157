<rd-base-template *ngIf="cmsResult && cmsResult.type != 'index'" [title]="cmsResult.type == 'index' ? null : cmsResult.title" [breadcrumbs]="breadcrumbs">
	<ng-container [ngSwitch]="cmsResult.type">
		<rd-cms-entry-homepage *ngSwitchCase="'index'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-homepage>
		<rd-cms-entry-subpage *ngSwitchCase="'subpage'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-subpage>
		<rd-cms-entry-login *ngSwitchCase="'login'" [sessionUser]="sessionUser" [cmsResult]="cmsResult" [urls]="urls"></rd-cms-entry-login>
		<rd-cms-entry-contact-us *ngSwitchCase="'contact-us'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-contact-us>
		<rd-cms-entry-forgot-password *ngSwitchCase="'forgot-password'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-forgot-password>
		<rd-cms-entry-abs-landing *ngSwitchCase="'abs-landing'" [sessionUser]="sessionUser" [cmsResult]="cmsResult" [urls]="urls"></rd-cms-entry-abs-landing>
		<rd-cms-entry-abs-list *ngSwitchCase="'abs-list'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-abs-list>
		<rd-cms-entry-abs-summary *ngSwitchCase="'abs-summary'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-abs-summary>
		<rd-cms-entry-abs-submit *ngSwitchCase="'abs-submit'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-abs-submit>
		<rd-cms-entry-abs-submit-part-a *ngSwitchCase="'abs-submit-part-a'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-abs-submit-part-a>
		<rd-cms-entry-abs-submit-part-b *ngSwitchCase="'abs-submit-part-b'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-abs-submit-part-b>
		<rd-cms-entry-abs-submit-part-c *ngSwitchCase="'abs-submit-part-c'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-abs-submit-part-c>
		<rd-cms-entry-abs-submit-part-d *ngSwitchCase="'abs-submit-part-d'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-abs-submit-part-d>
		<rd-cms-entry-funnel-landing *ngSwitchCase="'funnel-landing'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-funnel-landing>
		<rd-cms-entry-funnel-summary *ngSwitchCase="'funnel-summary'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-funnel-summary>
		<rd-cms-entry-funnel-report *ngSwitchCase="'funnel-report'" [sessionUser]="sessionUser" [cmsResult]="cmsResult" [urls]="urls"></rd-cms-entry-funnel-report>
		<rd-cms-entry-watchlist *ngSwitchCase="'watchlist'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-watchlist>
		<rd-cms-entry-report-history *ngSwitchCase="'report-history'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-report-history>
		<rd-cms-entry-my-account *ngSwitchCase="'my-account'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-my-account>
		<rd-cms-entry-admin-abs-tool *ngSwitchCase="'admin-abs-tool'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-admin-abs-tool>
		<rd-cms-entry-404 *ngSwitchCase="'404'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-404>
		<div *ngSwitchDefault>No Template Setup for type {{ cmsResult.type }}</div>
	</ng-container>
</rd-base-template>
<rd-cms-entry-homepage *ngIf="cmsResult && cmsResult.type == 'index'" [sessionUser]="sessionUser" [cmsResult]="cmsResult"></rd-cms-entry-homepage>