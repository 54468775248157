import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PendingChangesGuard } from './app-routing.guard';
import { CmsEntryComponent } from '@app/cms/cms-entry.component';

const routes: Routes = [
	{
		path: '**',
		component: CmsEntryComponent,
		canDeactivate: [PendingChangesGuard]
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled'
		})
	],
	exports: [RouterModule],
	declarations: [],
	providers: [PendingChangesGuard]
})
export class AppRoutingModule {}
