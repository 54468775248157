import { deserialize, inheritSerialization } from 'cerialize';
import { set, get } from 'lodash';

import { CmsEntryResult } from './cms-entry-result';

@inheritSerialization(CmsEntryResult)
export class CmsEntryResultFunnelReport extends CmsEntryResult {
	@deserialize
	public org: any;

	@deserialize
	public report: any;

	@deserialize
	public reportEntry: any = {};

	@deserialize
	public sessionUserReportEntry: any = {};

	@deserialize
	public isPrepopulated: boolean;

	@deserialize
	public hasWritePermission: boolean;

	isUserReportValidated(): boolean {
		return get(this.sessionUserReportEntry, 'status') == 2;
	}

	get existingStatus(): number {
		return get(this.reportEntry, 'status', 0);
	}

	set existingStatus(status: number) {
		set(this.reportEntry, 'status', status);
	}

	get isOpen(): boolean {
		return get(this.report, 'isOpen');
	}

	get entryId(): number {
		return get(this.reportEntry, 'id', '');
	}

	get reportId(): number {
		return get(this.report, 'id');
	}

	get orgName(): number {
		return get(this.org, 'org');
	}

	get orgId(): number {
		return get(this.reportEntry, 'naccap_orgs_id');
	}

	get fr_app_curr(): string {
		return get(this.reportEntry, 'fr_app_curr', '');
	}

	get fr_app_prev(): string {
		return get(this.reportEntry, 'fr_app_prev', '');
	}

	get fr_adm_curr(): string {
		return get(this.reportEntry, 'fr_adm_curr', '');
	}

	get fr_adm_prev(): string {
		return get(this.reportEntry, 'fr_adm_prev', '');
	}

	get fr_dep_curr(): string {
		return get(this.reportEntry, 'fr_dep_curr', '');
	}

	get fr_dep_prev(): string {
		return get(this.reportEntry, 'fr_dep_prev', '');
	}

	get fr_dep_canceled_curr(): string {
		return get(this.reportEntry, 'fr_dep_canceled_curr', '');
	}

	get fr_dep_canceled_prev(): string {
		return get(this.reportEntry, 'fr_dep_canceled_prev', '');
	}

	get tr_app_curr(): string {
		return get(this.reportEntry, 'tr_app_curr', '');
	}

	get tr_app_prev(): string {
		return get(this.reportEntry, 'tr_app_prev', '');
	}

	get tr_adm_curr(): string {
		return get(this.reportEntry, 'tr_adm_curr', '');
	}

	get tr_adm_prev(): string {
		return get(this.reportEntry, 'tr_adm_prev', '');
	}

	get tr_dep_curr(): string {
		return get(this.reportEntry, 'tr_dep_curr', '');
	}

	get tr_dep_prev(): string {
		return get(this.reportEntry, 'tr_dep_prev', '');
	}

	get tr_dep_canceled_curr(): string {
		return get(this.reportEntry, 'tr_dep_canceled_curr', '');
	}

	get tr_dep_canceled_prev(): string {
		return get(this.reportEntry, 'tr_dep_canceled_prev', '');
	}

	get tns_app_curr(): string {
		return get(this.reportEntry, 'tns_app_curr', '');
	}

	get tns_app_prev(): string {
		return get(this.reportEntry, 'tns_app_prev', '');
	}

	get tns_adm_curr(): string {
		return get(this.reportEntry, 'tns_adm_curr', '');
	}

	get tns_adm_prev(): string {
		return get(this.reportEntry, 'tns_adm_prev', '');
	}

	get tns_dep_curr(): string {
		return get(this.reportEntry, 'tns_dep_curr', '');
	}

	get tns_dep_prev(): string {
		return get(this.reportEntry, 'tns_dep_prev', '');
	}

	get tns_dep_canceled_curr(): string {
		return get(this.reportEntry, 'tns_dep_canceled_curr', '');
	}

	get tns_dep_canceled_prev(): string {
		return get(this.reportEntry, 'tns_dep_canceled_prev', '');
	}

	get incomplete_explanation(): string {
		return get(this.reportEntry, 'incomplete_explanation', '');
	}

	get curr_goal(): string {
		return get(this.reportEntry, 'curr_goal', '');
	}

	get prev_actual(): string {
		return get(this.reportEntry, 'prev_actual', '');
	}

	get fr_enr_curr(): string {
		return get(this.reportEntry, 'fr_enr_curr', '');
	}

	get fr_enr_prev(): string {
		return get(this.reportEntry, 'fr_enr_prev', '');
	}

	get tr_enr_curr(): string {
		return get(this.reportEntry, 'tr_enr_curr', '');
	}

	get tr_enr_prev(): string {
		return get(this.reportEntry, 'tr_enr_prev', '');
	}

	get tns_enr_curr(): string {
		return get(this.reportEntry, 'tns_enr_curr', '');
	}

	get tns_enr_prev(): string {
		return get(this.reportEntry, 'tns_enr_prev', '');
	}

	get original_goal(): string {
		return get(this.reportEntry, 'original_goal', '');
	}

	get onHoldMessages(): any {
		return get(this.reportEntry, 'on_hold_messages', []);
	}
}
