import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { CmsEntryResultAbsSubmit } from '@app/cms/results/cms-entry-result-abs-submit';

@Component({
	selector: 'rd-abs-submit-wrapper',
	templateUrl: './abs-submit-wrapper.component.html',
	styleUrls: ['./abs-submit-wrapper.component.css']
})
export class AbsSubmitWrapperComponent implements OnInit {
	@Input()
	cmsResult: CmsEntryResultAbsSubmit;

	@Input()
	subtitle: string;

	@Input()
	form: FormGroup;

	constructor() {}

	ngOnInit() {}
}
