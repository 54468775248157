import { deserialize, inheritSerialization } from 'cerialize';

import { CmsEntryResult } from './cms-entry-result';

@inheritSerialization(CmsEntryResult)
export class CmsEntryResultAbsLanding extends CmsEntryResult {
	@deserialize
	public completedAbsReports: any;

	@deserialize
	public verifiedAbsReportsForUser: any;

	@deserialize
	public report: any;
}
