import { Component, OnChanges, SimpleChanges, Input, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeRight, get, find, groupBy } from 'lodash';
import * as html2canvas from 'html2canvas';

import { CmsEntryResultAdminAbsTool } from '@app/cms/results/cms-entry-result-admin-abs-tool';
import { Account } from '@app/account/account';
import { ReportFilters } from '@app/cms/funnel-report-filter/funnel-report-filter.component';

@Component({
	selector: 'rd-cms-entry-admin-abs-tool',
	templateUrl: './cms-entry-admin-abs-tool.component.html'
})
export class CmsEntryAdminAbsToolComponent implements OnChanges {
	constructor(private route: ActivatedRoute, private router: Router) {
		this.route.queryParams.subscribe((params: any) => {
			this.pageFilters = {
				id: +params.id || undefined
			};
		});
	}

	get averagesFirstHalf() {
		return take(this.cmsResult.averages, Math.ceil(this.cmsResult.averages.length / 2));
	}

	get averagesLastHalf() {
		return takeRight(this.cmsResult.averages, this.cmsResult.averages.length / 2);
	}

	get averagesCurrentYearFirstHalf() {
		return take(this.cmsResult.averages[0].data, Math.ceil(this.cmsResult.averages[0].data.length / 2));
	}

	get averagesCurrentYearLastHalf() {
		return takeRight(this.cmsResult.averages[0].data, this.cmsResult.averages[0].data.length / 2);
	}
	@Input()
	cmsResult: CmsEntryResultAdminAbsTool;

	@Input()
	sessionUser: Account;

	readonly RANGE_LENGTH_DEFAULT = 5;
	readonly RANGE_LENGTH_SHORT_DEFAULT = 4;

	rangeLength = this.RANGE_LENGTH_DEFAULT;
	rangeLengthShort = this.RANGE_LENGTH_SHORT_DEFAULT;
	pageFilters: Filters = {};
	averagesSplit: any;
	averagesForCurrentYear: any;
	averageRange: any;
	averageRangeShort: any;
	salaryFields = [
		{
			label: 'Starting Counselor',
			field: 'c_adm_cnslr_start_sal'
		},
		{
			label: 'Senior Counselor',
			field: 'c_sr_cnslr_sal'
		},
		{
			label: 'Assistant Director',
			field: 'c_asst_dir_sal'
		},
		{
			label: 'Associate Director',
			field: 'c_assoc_dir_sal'
		},
		{
			label: 'Director of Admission',
			field: 'c_dir_adm_sal'
		},
		{
			label: 'VP/Dean of Enrollment',
			field: 'c_vpdean_sal'
		}
	];
	carFields = [
		{
			label: 'Admission Counselors',
			field: 'c_car_adm_cnslr'
		},
		{
			label: 'Other Staff Members',
			field: 'c_car_other_staff'
		}
	];
	funnelFields = [
		{
			label: 'Inquiry to Application',
			field: 'inquiry_to_app_percentage'
		},
		{
			label: 'App to Completed App',
			field: 'app_to_completed_percentage'
		},
		{
			label: 'Completed App to Admitted',
			field: 'completed_app_to_admitted_percentage'
		},
		{
			label: 'Admitted to Enrolled',
			field: 'admits_to_enrolled_percentage'
		},
		{
			label: 'Inquired to Enrolled',
			field: 'inquiry_to_enrolled_percentage'
		}
	];
	participantsBreakdown: any = {};

	@ViewChild('download') download: ElementRef;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.cmsResult) {
			this.cmsResult.averages = this.cmsResult.averages.map((yearlyAverages: any) => {
				yearlyAverages.data = yearlyAverages.data.map((averages: any) => {
					if (averages.c_pro_staff && averages.c_pro_staff_direct_recruiting) {
						averages._professional_staff_overhead = averages.c_pro_staff - averages.c_pro_staff_direct_recruiting;
					}
					return averages;
				});
				return yearlyAverages;
			});
			this.rangeLength =
				this.RANGE_LENGTH_DEFAULT > this.cmsResult.completedAbsReports.length
					? this.cmsResult.completedAbsReports.length
					: this.RANGE_LENGTH_DEFAULT;
			this.rangeLengthShort =
				this.RANGE_LENGTH_SHORT_DEFAULT > this.cmsResult.completedAbsReports.length
					? this.cmsResult.completedAbsReports.length
					: this.RANGE_LENGTH_SHORT_DEFAULT;
			this.averagesSplit = [this.averagesFirstHalf, this.averagesLastHalf];
			this.averagesForCurrentYear = [this.averagesCurrentYearFirstHalf, this.averagesCurrentYearLastHalf];
			this.averageRange = take(this.cmsResult.averages, this.rangeLength).reverse();
			this.averageRangeShort = take(this.cmsResult.averages, this.rangeLengthShort).reverse();
			this.createParticipantBreakdown();
		}
	}

	createParticipantBreakdown() {
		const region = groupBy(this.cmsResult.participants, 'region');
		const regionSort = Object.keys(region).sort();
		const type = groupBy(this.cmsResult.participants, 'type');
		const typeSort = ['CCCU', 'Bible College', 'Other CLA'];
		const enrollment = groupBy(this.cmsResult.participants, 'enrollmentLabel');
		const enrollmentSort = Object.keys(enrollment).sort();
		this.participantsBreakdown = { region, type, enrollment, regionSort, typeSort, enrollmentSort };
	}

	getIncreaseOverPreviousYear(field: string, year: number) {
		const previousEntry = find(this.cmsResult.averages, result => result.year == year - 1);
		if (!previousEntry) {
			return undefined;
		}
		const currentEntry = find(this.cmsResult.averages, result => result.year == year);
		if (!currentEntry) {
			return undefined;
		}
		return currentEntry.data[0][field] / previousEntry.data[0][field] - 1;
	}

	getIncreaseTotal(field: string) {
		const mostRecentValue = get(this.cmsResult.averages, `0.data[0].${field}`);
		const firstValue = get(this.cmsResult.averages, `${this.cmsResult.averages.length - 1}.data[0].${field}`);
		return mostRecentValue / firstValue - 1;
	}

	onReportUpdate(filters: ReportFilters) {
		this.pageFilters = { ...this.pageFilters, id: filters.reportId };
		this.updatePage();
	}

	updatePage() {
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: this.pageFilters
		});
	}

	onSave(element: ElementRef, filename: string) {
		const saveData = (function() {
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.style.display = 'none';
			return function(blob: Blob, fileName: string) {
				const url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = fileName;
				a.click();
				window.URL.revokeObjectURL(url);
			};
		})();
		console.log('element', element, element.nativeElement);
		const nativeElement = element instanceof ElementRef ? element.nativeElement : element;
		html2canvas(nativeElement).then((canvas: any) => {
			canvas.toBlob((blob: Blob) => {
				saveData(blob, filename);
				// const link = window.URL.createObjectURL(blob);
				// window.open(link);
			});
		});
	}
}

interface Filters {
	id?: number;
}
