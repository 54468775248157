<div class="grid__row">
	<div class="grid__col--4 grid__col-offset--4">
		<div class="card">
			<form *ngIf="form && !saved" class="forgot__password__panel__form card__content" [formGroup]="form" (ngSubmit)="onSubmit()">
				<div class="forgot__password__panel__fields">
					<div>
						<label>Please enter your username</label>
						<input type="text" formControlName="email" required>
						<div *ngIf="form.controls.email.touched && form.controls.email.errors && form.controls.email.errors.required">Username is required</div>
					</div>
				</div>
				<div class="forgot__password__panel__action">
					<button class="btn btn--gold" type="submit" [disabled]="form.invalid && !isSubmitting">Submit</button>
				</div>
			</form>
		</div>
		<rd-alert type="danger" *ngIf="error">
			<h4>Error</h4>
			{{ error.message }}
		</rd-alert>
		<rd-alert type="info" *ngIf="saved">
			<h2>Success</h2>
			<p>If that username exists in our system, an email will be sent to the account holder with a new password.</p>
		</rd-alert>
	</div>
</div>