import { deserialize, inheritSerialization } from 'cerialize';
import { get } from 'lodash';

import { CmsEntryResultFunnelBase } from './cms-entry-result-funnel-base';

@inheritSerialization(CmsEntryResultFunnelBase)
export class CmsEntryResultFunnelLanding extends CmsEntryResultFunnelBase {
	@deserialize
	public report: any;

	@deserialize
	reportEntry: any;

	@deserialize
	public watchlist: any;

	@deserialize
	public paginatedReports: any;

	@deserialize
	public mostRecentReport: any;

	get isEntryVerified(): boolean {
		return get(this.reportEntry, 'status') == 2;
	}
}
