<div class="table__filters" [formGroup]="filters">
	<input *ngIf="includeSearch" class="table__filters--search" placeholder="Search for school" formControlName="search">
	<div class="table__filters--past">
		View Past Results
		<select name="filter_year" formControlName="year">
			<ng-container *ngIf="isAbs">
				<option *ngFor="let option of reports" [value]="option.id">{{ option.year }}</option>
			</ng-container>
			<ng-container *ngIf="!isAbs">
				<option *ngFor="let option of reportYears" [value]="option">{{ option }}</option>
			</ng-container>
		</select>

		<select *ngIf="!isAbs" name="filter_type" formControlName="report">

			<option [value]="null">
				<span>Select report</span>
			</option>

			<ng-container *ngFor="let option of getReportsForYear(filters.controls.year.value)">
				<option [value]="option.id">
					<span *ngIf="option.isFinalReport">Final</span>
					<span *ngIf="!option.isFinalReport">{{ option.monthLabel }}</span>
				</option>
			</ng-container>
		</select>
		<button *ngIf="!isAbs" class="btn btn--gray" (click)="sendUpdate()">Go</button>
	</div>
</div>
