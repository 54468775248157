import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'rd-control-errors',
	templateUrl: './control-errors.component.html',
	styleUrls: ['./control-errors.component.css']
})
export class ControlErrorsComponent implements OnInit {
	@Input()
	control: FormControl;

	constructor() {}

	ngOnInit() {}
}
