import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CmsEntryResult } from '@app/cms/results/cms-entry-result';
import { Account } from '@app/account/account';
import { AccountService } from '@app/account/account.service';

@Component({
	selector: 'rd-cms-entry-forgot-password',
	templateUrl: './cms-entry-forgot-password.component.html'
})
export class CmsEntryForgotPasswordComponent implements OnInit {
	@Input()
	cmsResult: CmsEntryResult | null = null;

	@Input()
	sessionUser: Account;

	form?: FormGroup;
	error: { message: string } | null = null;
	saved = false;
	isSubmitting = false;

	constructor(private formBuilder: FormBuilder, private accountService: AccountService) {
		this.createForm();
	}

	createForm() {
		this.form = this.formBuilder.group({
			email: ['', [Validators.required]]
		});
	}

	ngOnInit(): void {}

	onSubmit(): void {
		if (this.isSubmitting) {
			return;
		}
		if (this.form!.invalid) {
			this.error = { message: 'Fix errors and try again.' };
			return;
		}
		this.error = null;
		this.isSubmitting = true;
		this.accountService
			.forgotPassword(this.form!.value.email)
			.then((data: any) => {
				this.saved = true;
				this.isSubmitting = false;
			})
			.catch((data: any) => {
				this.error = { message: data.error.error.message };
				this.isSubmitting = false;
			});
	}
}
