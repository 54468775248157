import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { CmsEntryResultAbsSubmit } from '@app/cms/results/cms-entry-result-abs-submit';
import { Account } from '@app/account/account';
import { AccountService } from '@app/account/account.service';
import { AbsSubmitFormInterface } from './abs-submit-form.interface';
import { AbsEntryService } from './abs-entry.service';

@Injectable()
export abstract class AbsSubmitPartHelper implements AbsSubmitFormInterface {
	cmsResult: CmsEntryResultAbsSubmit;
	sessionUser: Account;

	form: FormGroup;
	isReadonlyMode: boolean;
	hasIncompleteData: boolean;
	isSubmitting: boolean;
	isSaved: boolean;
	error: any;
	orgId: number;

	constructor(
		public formBuilder: FormBuilder,
		public absEntryService: AbsEntryService,
		public router: Router,
		private accountService: AccountService
	) {}

	listenForFormChanges() {
		this.form.valueChanges.subscribe(values => {
			for (const field in values) {
				if (values[field] !== this.cmsResult[field as keyof CmsEntryResultAbsSubmit]) {
					this.accountService.hasUnsavedChanges = true;
					return;
				}
			}
			this.accountService.hasUnsavedChanges = false;
		});
	}

	onSaveSuccess() {
		this.accountService.hasUnsavedChanges = false;
	}

	canSubmit() {
		return (
			(this.hasWritePermission() && this.cmsResult.isOpen && this.cmsResult.existingStatus === 0) ||
			this.sessionUser.isAdmin === true
		);
	}

	hasWritePermission() {
		return (
			this.sessionUser.isAdmin || (this.cmsResult.existingStatus === 0 && this.sessionUser.orgId === this.getOrgId())
		);
	}

	hasReadPermission() {
		return (
			this.sessionUser.isAdmin || this.sessionUser.orgId === this.getOrgId() || this.cmsResult.isUserReportValidated()
		);
	}

	getOrgId(): number {
		return this.cmsResult.org.id;
	}
}
