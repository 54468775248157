<ng-container *ngIf="type == 'standalone'">
	<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
		<div class="form__element">
			<label class="label">Username</label>
			<input type="text" formControlName="email" required>
			<rd-alert *ngIf="email.touched && email.errors && email.errors.required" type="danger">Username is required</rd-alert>
		</div>
		<div class="form__element">
			<label class="label">Password</label>
			<input type="password" formControlName="password" required>
			<rd-alert *ngIf="password.touched && password.invalid" type="danger">Password is required</rd-alert>
		</div>
		<input type="submit" class="btn btn--gold submit" [disabled]="form.invalid" value="Login"/>
		<p><a [routerLink]="forgotPasswordUrl" class="login__forgot">Forgot password?</a></p>
		<rd-alert type="danger" *ngIf="error">
			<h4>Error</h4>
			{{ error.message }}
		</rd-alert>
	</form>
</ng-container>

<ng-container *ngIf="type == 'inline'">
	<div class="login__title">Already a Member?</div>
	<form [formGroup]="form" (ngSubmit)="onSubmit()" class="input--inline">
		<input type="text" formControlName="email" class="input" name="username" placeholder="username">
		<input type="password" formControlName="password" class="input" placeholder="password" minlength="" required>
		<button type="submit" class="btn btn--blue submit" [disabled]="form.invalid">Login</button>
	</form>
	<a [routerLink]="forgotPasswordUrl" class="login__forgot">Forgot password?</a>
	<rd-alert type="danger" *ngIf="error">
		<h4>Error</h4>
		{{ error.message }}
	</rd-alert>
</ng-container>