import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'rd-abs-submit-save-messages',
	templateUrl: './abs-submit-save-messages.component.html',
	styleUrls: ['./abs-submit-save-messages.component.css']
})
export class AbsSubmitSaveMessagesComponent implements OnInit {
	@Input()
	show = false;

	@Input()
	status: number;

	@Input()
	url: string;

	constructor() {}

	ngOnInit() {}
}
