<div class="grid__row">
	<div class="grid__col--12">
		<div class="card">
			<div class="card__content card__content--padded">
				<h2>{{ sessionUser.fullName }}</h2>
				<h3>{{ sessionUser.org }}</h3>
				<div [innerHtml]="cmsResult.mainContent"></div>
				<form *ngIf="form" [formGroup]="form" class="input--inline" (ngSubmit)="onSubmit()">
					<input type="text" class="input" placeholder="Username" formControlName="username">
					<input type="email" class="input" placeholder="E-mail" formControlName="email">
					<button type="submit" class="btn btn--gold" [disabled]="form.invalid">Save</button>
					<rd-alert type="danger" *ngIf="error">
						<h2>Error</h2>
						{{ error.message }}
					</rd-alert>
					<rd-alert type="success" *ngIf="saved">
						<h2>Success</h2>
						Your profile data was updated successfully.
					</rd-alert>
				</form>
				<hr class="hr--thick">
				<form *ngIf="passwordForm" [formGroup]="passwordForm" class="input--inline" (ngSubmit)="updatePassword()">
					<input type="password" class="input" placeholder="Old Password" formControlName="old_password">
					<input type="password" class="input" placeholder="New Password" formControlName="new_password">
					<input type="password" class="input" placeholder="Confirm New Password" formControlName="confirm_new_password">
					<button type="submit" class="btn btn--gold" [disabled]="passwordForm.invalid">Save</button>
					<rd-alert type="danger" *ngIf="passwordError">
						<h2>Error</h2>
						{{ passwordError.message }}
					</rd-alert>
					<rd-alert type="success" *ngIf="passwordSaved">
						<h2>Success</h2>
						Your password has been updated.
					</rd-alert>
				</form>
			</div>
		</div>
	</div>
</div>