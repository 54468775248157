import { get } from 'lodash';

export class Account {
	public raw: any;
	private _emailConfirm: string;
	private _passwordConfirm: string;

	constructor(protected json: any) {
		this.raw = json;
		this._emailConfirm = get(this.raw, 'email');
		this._passwordConfirm = get(this.raw, 'password');
	}

	get userId(): number {
		return get(this.raw, 'id');
	}

	get username(): string {
		return get(this.raw, 'username');
	}
	set username(username: string) {
		this.raw.username = username;
	}

	get firstName(): string {
		return get(this.raw, 'fname');
	}
	set firstName(name: string) {
		this.raw.fname = name;
	}

	get lastName(): string {
		return get(this.raw, 'lname');
	}
	set lastName(name: string) {
		this.raw.lname = name;
	}

	get fullName(): string {
		return get(this.raw, 'fullname');
	}

	get email(): string {
		return get(this.raw, 'email');
	}
	set email(email: string) {
		this.raw.email = email;
	}

	get emailConfirm(): string {
		return this._emailConfirm;
	}
	set emailConfirm(email: string) {
		this._emailConfirm = email;
	}

	get emailsMatch(): boolean {
		return this.email === this.emailConfirm;
	}

	get password(): string {
		return get(this.raw, 'password');
	}
	set password(password: string) {
		this.raw.password = password;
	}

	get passwordConfirm(): string {
		return this._passwordConfirm;
	}
	set passwordConfirm(password: string) {
		this._passwordConfirm = password;
	}

	get passwordsMatch(): boolean {
		return this.password === this.passwordConfirm;
	}

	get address(): string {
		return get(this.raw, 'address');
	}
	set address(address: string) {
		this.raw.address = address;
	}

	get city(): string {
		return get(this.raw, 'city');
	}
	set city(city: string) {
		this.raw.city = city;
	}

	get country(): string {
		return get(this.raw, 'country');
	}
	set country(country: string) {
		this.raw.country = country;
	}

	get state(): string {
		return get(this.raw, 'state');
	}
	set state(state: string) {
		this.raw.state = state;
	}

	get zip(): string {
		return get(this.raw, 'zip');
	}
	set zip(zip: string) {
		this.raw.zip = zip;
	}

	get phone(): string {
		return get(this.raw, 'phone');
	}
	set phone(phone: string) {
		this.raw.phone = phone;
	}

	get isAdmin(): boolean {
		return !!get(this.raw, 'isAdmin');
	}

	get isOrgSuperuser(): boolean {
		return !!get(this.raw, 'isOrgSuperuser');
	}

	get isEditor(): boolean {
		return !!get(this.raw, 'isEditor');
	}

	get orgId(): number {
		return get(this.raw, 'naccap_orgs_id');
	}

	get org(): string {
		return get(this.raw, 'org');
	}
}
