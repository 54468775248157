import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';

import { CmsEntryResult } from '@app/cms/results/cms-entry-result';
import { AccountService } from '@app/account/account.service';
import { Account } from '@app/account/account';

@Component({
	selector: 'rd-cms-entry-contact-us',
	templateUrl: './cms-entry-contact-us.component.html'
})
export class CmsEntryContactUsComponent implements OnInit {
	@Input()
	cmsResult: CmsEntryResult;

	@Input()
	sessionUser: Account;

	form?: FormGroup;
	error: { message: string } | null = null;
	attemptedSubmit = false;
	saved = false;
	isSending = false;

	questionTypes = [
		'I have a question about the study',
		'I have a question about the site',
		'I have a question about my account',
		'I need to create a new account',
		'I need to delete an account',
		'Other...'
	];

	constructor(private accountService: AccountService, private formBuilder: FormBuilder) {
		this.createForm();
	}

	createForm() {
		this.form = this.formBuilder.group({
			fullname: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
			question: ['', Validators.required],
			details: ['', Validators.required],
			new_fullname: [''],
			new_title: [''],
			new_institution: [''],
			new_email: [''],
			delete_fullname: [''],
			delete_institution: ['']
		});
		this.form.controls.question.valueChanges.subscribe(value => {
			const newValidators = value === 'I need to create a new account' ? [Validators.required] : null;
			const deleteValidators = value === 'I need to delete an account' ? [Validators.required] : null;
			['new_fullname', 'new_title', 'new_institution', 'new_email'].forEach(field => {
				this.form!.controls[field].setValidators(newValidators);
				this.form!.controls[field].updateValueAndValidity();
			});
			['delete_fullname', 'delete_institution'].forEach(field => {
				this.form!.controls[field].setValidators(deleteValidators);
				this.form!.controls[field].updateValueAndValidity();
			});
		});
	}

	doesControlHaveError(control: AbstractControl): boolean {
		return (control.touched || this.attemptedSubmit) && control.invalid;
	}

	ngOnInit() {}

	onSubmit() {
		this.attemptedSubmit = true;
		this.saved = false;
		if (this.form!.invalid) {
			this.error = { message: 'Fix errors and try again.' };
			return;
		}
		this.isSending = true;
		this.accountService
			.sendContactRequest(this.form!.value)
			.then(() => {
				this.saved = true;
				this.isSending = false;
				this.form!.reset();
				this.attemptedSubmit = false;
			})
			.catch((data: any) => {
				this.error = { message: data.error.error.message };
				this.isSending = false;
			});
	}
}
