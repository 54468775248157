import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class OrgService {
	constructor(private http: HttpClient) {}

	listAll(): Promise<any> {
		return this.http.get<any>(`${environment.apiUrl}/crud/org/list?show_all=1`).toPromise();
	}

	getOrgWatchlist(org_id: number): Promise<any> {
		return this.http.get<any>(`${environment.apiUrl}/crud/watchlist/org/${org_id}`).toPromise();
	}
}
