import { deserialize, inheritSerialization } from 'cerialize';
import { get } from 'lodash';

import { CmsEntryResult } from './cms-entry-result';

@inheritSerialization(CmsEntryResult)
export class CmsEntryResultHomepage extends CmsEntryResult {
	@deserialize
	public openFunnelReport: any;

	@deserialize
	public completedFunnelReports: any;

	@deserialize
	public openAbsReport: any;

	@deserialize
	public mostRecentAbsReport: any;

	@deserialize
	public completedAbsReports: any;

	get mainTitle(): string | null {
		return get(this.entry, 'entries.feature.0.title.value');
	}

	get title1(): string | null {
		return get(this.entry, 'entries.feature.0.title1.value');
	}

	get content1(): string | null {
		return get(this.entry, 'entries.feature.0.content1.value');
	}

	get title2(): string | null {
		return get(this.entry, 'entries.feature.0.title2.value');
	}

	get content2(): string | null {
		return get(this.entry, 'entries.feature.0.content2.value');
	}

	get title3(): string | null {
		return get(this.entry, 'entries.feature.0.title3.value');
	}

	get content3(): string | null {
		return get(this.entry, 'entries.feature.0.content3.value');
	}

	get testimonialTitle(): string | null {
		return get(this.entry, 'entries.testimonial.0.title.value');
	}

	get testimonialContent(): string | null {
		return get(this.entry, 'entries.testimonial.0.content.value');
	}

	get testimonialName(): string | null {
		return get(this.entry, 'entries.testimonial.0.name.value');
	}

	get testimonialOrg(): string | null {
		return get(this.entry, 'entries.testimonial.0.org.value');
	}

	get showTestimonial(): boolean {
		return get(this.entry, 'entries.testimonial.0.show.value', 'no') === 'yes';
	}

	get absTitle(): string | null {
		return get(this.entry, 'entries.abs.0.title.value');
	}

	get absContent(): string | null {
		return get(this.entry, 'entries.abs.0.content.value');
	}

	get absLinkText(): string | null {
		return get(this.entry, 'entries.abs.0.link_text.value', 'Learn More');
	}

	get funnelTitle(): string | null {
		return get(this.entry, 'entries.funnel.0.title.value');
	}

	get funnelContent(): string | null {
		return get(this.entry, 'entries.funnel.0.content.value');
	}

	get funnelLinkText(): string | null {
		return get(this.entry, 'entries.funnel.0.link_text.value', 'View Summary');
	}

	get funnelImage(): string | null {
		return get(this.entry, 'entries.funnel.0.image.value');
	}

	get showContactSection(): boolean {
		return get(this.entry, 'entries.contact.0.show.value', 'no') === 'yes';
	}

	get contactTitle(): string | null {
		return get(this.entry, 'entries.contact.0.title.value');
	}

	get contactContent(): string | null {
		return get(this.entry, 'entries.contact.0.content.value');
	}

	get footerLogo(): string | null {
		return get(this.entry, 'entries.footer.0.logo.value');
	}

	get footerCopyright(): string | null {
		return get(this.entry, 'entries.footer.0.copyright.value');
	}

	get footerPhone(): string {
		return get(this.entry, 'entries.footer.0.phone.value', '(336) 603-6046');
	}

	get footerPhoneUrl(): string {
		return 'tel:' + this.footerPhone.replace(/\D/g, '');
	}

	get footerRightTitle(): string | null {
		return get(this.entry, 'entries.footer.0.right_title.value');
	}

	get footerRightContent(): string | null {
		return get(this.entry, 'entries.footer.0.right_content.value');
	}

	get footerContactText(): string | null {
		return get(this.entry, 'entries.footer.0.contact_text.value');
	}
}
