import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { CmsEntryResultReportHistory } from '@app/cms/results/cms-entry-result-report-history';
import { AccountService } from '@app/account/account.service';
import { Account } from '@app/account/account';

@Component({
	selector: 'rd-cms-entry-report-history',
	templateUrl: './cms-entry-report-history.component.html'
})
export class CmsEntryReportHistoryComponent implements OnInit, OnChanges {
	@Input()
	cmsResult: CmsEntryResultReportHistory;

	@Input()
	sessionUser: Account;

	error: { message: string } | null = null;

	form: any = {};
	filledIds: any = {};
	allSubmitetReportsForOrg: any = {};

	constructor(private accountService: AccountService) {}

	ngOnInit() {
		this.form.org_id = this.sessionUser.orgId;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.cmsResult) {
			this.cmsResult.completedFunnelReports = this.cmsResult.completedFunnelReports.map((report: any) => {
				report.label = `${report.monthLabel}, ${report.year}`;
				return report;
			});
		}
	}

	export() {
		this.error = null;
		if (!this.form.org_id) {
			this.error = { message: 'Please select an organization.' };
			return;
		}
		if (!this.form.type) {
			this.error = { message: 'Please select a report type.' };
			return;
		}

		const report_ids = this.form.type == 'funnel_reports' ? this.form.funnel_reports : this.form.abs_reports;

		// allow export only for submited reports
		if (typeof report_ids !== 'undefined') {
			if (this.form.type == 'funnel_reports') {
				// filter out only reports for selected org
				this.allSubmitetReportsForOrg = this.cmsResult.funnelReportsData.filter(
					(item: any) => item.naccap_orgs_id == this.form.org_id
				);

				this.filledIds = this.allSubmitetReportsForOrg.map((report: any) => {
					return report.naccap_app_reports_id;
				});

				const check = this.containsValue(report_ids, this.filledIds);

				if (!check) {
					this.error = { message: 'You can only access data for studies where you have shared your data.' };
					return;
				}
			} else if (this.form.type == 'abs_reports') {
				this.filledIds = this.cmsResult.absReports.map((report: any) => {
					return report.id;
				});

				const check = this.containsValue(report_ids, this.filledIds);

				if (!check) {
					this.error = { message: 'You can only access data for studies where you have shared your data.' };
					return;
				}
			}
		}

		this.accountService.exportHistory(this.form.type, this.form.org_id, report_ids, this.form.include_watchlist);
	}

	containsValue(arrA: any[], arrB: any[]): boolean {
		return arrA.some(x => arrB.includes(x));
	}
}
