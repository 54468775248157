<div [innerHtml]="cmsResult.mainContent"></div>

<div class="grid__row">
	<div class="grid__col--5">
		<div class="table__filters">
			<h4>Summary Results <small>(Public)</small></h4>
			<div class="table__filters--past">
				<select name="public_results" id="public-results" [(ngModel)]="filters.publicReport">
					<option *ngFor="let report of cmsResult.completedAbsReports" [ngValue]="report">{{ report.year }}</option>
				</select>
			</div>
		</div>
		<div class="card">
			<div class="card__content">
				<div *ngIf="filters.publicReport">
					<h3>{{ filters.publicReport.year }} Results</h3>
					<ul>
						<li *ngIf="filters.publicReport.summary_doc"><a target="_blank" href="{{ filters.publicReport.summary_doc | fullResourcePath }}">Download {{ filters.publicReport.year }} Summary Report</a></li>
						<li><a (click)="accountService.exportCostToRecruit(filters.publicReport.id)">Download Cost to Recruit Summary</a></li>
						<li><a (click)="accountService.exportAbsFunnelSummary(filters.publicReport.id)">Download Funnel Summary</a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="sessionUser && sessionUser.userId" class="grid__col--5 grid__col-offset--1">
		<div class="table__filters">
			<h4>Member-by-Member Results <small>(Participants only)</small></h4>
			<div class="table__filters--past">
				<select name="public_results" id="public-results" [(ngModel)]="filters.memberReport">
					<option *ngFor="let report of getValidatedReportsForUser()" [ngValue]="report">{{ report.year }}</option>
				</select>
			</div>
		</div>
		<div class="card">
			<div class="card__content">
				<div *ngIf="filters.memberReport">
					<h3>{{ filters.memberReport.year }} Results</h3>
					<ul>
						<li><a [routerLink]="cmsResult.urls['abs-summary']" [queryParams]="{ survey_id: filters.memberReport.id }">Compare your data to your region, enrollment size, and the country</a></li>
						<li><a [routerLink]="cmsResult.urls['abs-list']" [queryParams]="{ id: filters.memberReport.id }">Browse school-by-school data</a></li>
						<li><a (click)="accountService.exportAbsBudget(filters.memberReport.id)">Download Budget Data Spreadsheet</a></li>
						<li><a (click)="accountService.exportAbsFunnel(filters.memberReport.id)">Download Funnel Data Spreadsheet</a></li>
					</ul>
					<ng-container *ngIf="sessionUser.isAdmin">
						<hr class="hr--thick">
						<h4>Admin Only</h4>
						<ul>
							<li><a (click)="accountService.exportAbsBudgetComplete(filters.memberReport.id)">Download Complete Budget Data Spreadsheet</a></li>
						</ul>
					</ng-container>
				</div>
				<p *ngIf="!filters.memberReport">
					You will be able to compare your results with other schools after you participate in an Admission Benchmarking Study.
				</p>
			</div>
		</div>
	</div>
</div>