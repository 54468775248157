import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Account } from '@app/account/account';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root'
})
export class AccountService {
	private _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public readonly loggedIn: Observable<boolean> = this._loggedIn.asObservable();

	// Use this variable to keep users from leaving a form with unsaved changes
	hasUnsavedChanges = false;

	constructor(private http: HttpClient) {
		this.http
			.get<any>(`${environment.apiUrl}/crud/session`)
			.toPromise()
			.then(user => {
				this._loggedIn.next(user.id != null);
			});
	}

	saveAccount(account: Account, isCreate: boolean): Promise<any> {
		let path = `${environment.apiUrl}/crud/user/${account.userId}`;
		const user: any = { username: account.username, email: account.email };
		const roles = { is_admin: account.isAdmin, is_editor: account.isEditor, is_org_superuser: account.isOrgSuperuser };
		if (isCreate) {
			path = `${environment.apiUrl}/crud/user`;
		} else {
			user.id = account.userId;
		}
		return this.http
			.post(path, { user, ...roles })
			.toPromise()
			.catch(this._handleError);
	}

	getSessionUser(): Promise<Account> {
		return this.http
			.get<any>(`${environment.apiUrl}/crud/user`)
			.toPromise()
			.then(res => new Account(res))
			.catch(this._handleError);
	}

	forgotPassword(email: string): Promise<any> {
		return this.http
			.post(`${environment.apiUrl}/crud/login/forgot`, { email: email })
			.toPromise()
			.catch(this._handleError);
	}

	updatePassword(
		user_id: number,
		old_password: string,
		new_password: string,
		new_password_confirm: string
	): Promise<any> {
		if (new_password !== new_password_confirm) {
			return Promise.reject(new Error('New password and confirmation password do not match.'));
		}
		return this.http
			.post(`${environment.apiUrl}/crud/user/${user_id}/password`, { old_password, new_password })
			.toPromise()
			.catch(this._handleError);
	}

	login(email: string, password: string): Promise<any> {
		return this.http
			.post<any>(`${environment.apiUrl}/crud/login`, { email, password })
			.toPromise()
			.then(data => {
				this._loggedIn.next(true);
				return this.http
					.get<any>(`${environment.apiUrl}/crud/session`)
					.toPromise()
					.then(user => {
						data.user = user;
						return data;
					});
			});
	}

	logout(): Promise<void> {
		return this.http
			.get(`${environment.apiUrl}/logout`)
			.toPromise()
			.then(() => this._loggedIn.next(false))
			.catch(this._handleError);
	}

	isLoggedIn(): Observable<boolean> {
		if (this._loggedIn.getValue() !== null) {
			return this.loggedIn;
		}
		return this.http.get<any>(`${environment.apiUrl}/crud/session`).pipe(
			map(user => {
				this._loggedIn.next(user.id != null);
				return this._loggedIn.getValue();
			})
		);
	}

	addOrgToWatchlist(user_id: number, naccap_orgs_id: number): Promise<any> {
		return this.http.post<any>(`${environment.apiUrl}/crud/watchlist`, { user_id, naccap_orgs_id }).toPromise();
	}

	removeOrgFromWatchlist(user_id: number, naccap_orgs_id: number): Promise<any> {
		return this.http.delete<any>(`${environment.apiUrl}/crud/watchlist/${user_id}/${naccap_orgs_id}`).toPromise();
	}

	sendContactRequest(data: any): Promise<any> {
		return this.http.post<any>(`${environment.apiUrl}/crud/email/contact`, data).toPromise();
	}

	exportHistory(type: string, org_id: number, report_ids?: number[], include_watchlist?: boolean) {
		const extraParams = [];
		if (report_ids && report_ids.length) {
			extraParams.push('&ids[]=' + report_ids.join('&ids[]='));
		}
		if (include_watchlist) {
			extraParams.push(`&include_watchlist=${include_watchlist}`);
		}
		window.location.href = `${environment.apiUrl}/export/history?type=${type}&org_id=${org_id}${extraParams.join()}`;
	}

	exportFunnel(report_id: number) {
		window.location.href = `${environment.apiUrl}/export/funnel/${report_id}`;
	}

	exportFunnelFinal(report_id: number) {
		window.location.href = `${environment.apiUrl}/export/funnel-final/${report_id}`;
	}

	exportCostToRecruit(report_id: number) {
		window.location.href = `${environment.apiUrl}/export/abs/cost-to-recruit/${report_id}`;
	}

	exportAbsFunnelSummary(report_id: number) {
		window.location.href = `${environment.apiUrl}/export/abs/funnel-summary/${report_id}`;
	}

	exportAbsFunnel(report_id: number) {
		window.location.href = `${environment.apiUrl}/export/abs/funnel/${report_id}`;
	}

	exportAbsBudget(report_id: number) {
		window.location.href = `${environment.apiUrl}/export/abs/budget/${report_id}`;
	}

	exportAbsBudgetComplete(report_id: number) {
		window.location.href = `${environment.apiUrl}/export/abs/budget-complete/${report_id}`;
	}

	exportAbsSummary(report_id: number) {
		window.location.href = `${environment.apiUrl}/export/abs/summary/${report_id}`;
	}

	exportAbsCustomSummary(report_id: number, org_id: number, org_ids?: number[]) {
		const extraParams = [];
		if (org_ids && org_ids.length) {
			extraParams.push('&org_ids[]=' + org_ids.join('&org_ids[]='));
		}
		window.location.href = `${
			environment.apiUrl
		}/export/abs/summary-custom/${report_id}/${org_id}?${extraParams.join()}`;
	}

	private _handleError(err: any): never {
		console.log('Account service error: ', err);
		throw err;
	}
}
