import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccountService } from '@app/account/account.service';

export interface ComponentCanDeactivate {
	canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
	constructor(private accountService: AccountService) {}
	canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
		if (!this.accountService.hasUnsavedChanges) {
			return true;
		}

		if (
			confirm(
				'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.'
			)
		) {
			this.accountService.hasUnsavedChanges = false;
			return true;
		}
		return false;
	}
}
