import { deserialize, inheritSerialization } from 'cerialize';
import { find } from 'lodash';

import { CmsEntryResult } from './cms-entry-result';

@inheritSerialization(CmsEntryResult)
export class CmsEntryResultFunnelBase extends CmsEntryResult {
	@deserialize
	public completedReports: any;

	isFinalReport(id: number): boolean {
		return this.getReportById(id).isFinalReport;
	}

	getReportYear(id: number): number {
		return this.getReportById(id).reportYear;
	}

	getReportById(id: number): any {
		return find(this.completedReports, report => report.id == id);
	}
}
