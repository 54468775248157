import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AccountService } from '@app/account/account.service';
import { environment } from '@env/environment';

@Component({
	selector: 'rd-login',
	templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
	@Input()
	type = 'standalone';

	@Input()
	forgotPasswordUrl: string;

	form?: FormGroup;
	error: { message: string } | null = null;

	private defaultBackLocation = '/';
	login = {
		back_location: '',
		email: '',
		password: ''
	};
	errors: string[] = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private accountService: AccountService,
		private formBuilder: FormBuilder
	) {
		this.createForm();
	}

	createForm() {
		this.form = this.formBuilder.group({
			email: ['', [Validators.required]],
			password: ['', Validators.required]
		});
	}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params: Params) => {
			const back = params['back_location'];
			this.login.back_location = back && back.length > 0 ? back : this.defaultBackLocation;
		});
	}

	get email() {
		return this.form!.get('email');
	}

	get password() {
		return this.form!.get('password');
	}

	redirectToAdmin(url: string): void {
		url = url == '/' ? '/admin' : url;
		if (environment.production) {
			window.location.href = url;
		} else {
			window.location.href = `http://localhost:3000${url}`;
		}
	}

	onSubmit(): void {
		if (this.form!.invalid) {
			this.error = { message: 'Please add a reason for placing this report on hold.' };
			return;
		}
		this.accountService
			.login(this.form!.value.email, this.form!.value.password)
			.then((data: any) => {
				if (
					data.user.isAdmin &&
					(this.login.back_location == this.defaultBackLocation || this.login.back_location.indexOf('/admin') > -1)
				) {
					this.redirectToAdmin(this.login.back_location);
				} else {
					this.router.navigate([this.login.back_location]);
				}
			})
			.catch((data: any) => {
				this.error = { message: data.error.error.message };
			});
	}
}
