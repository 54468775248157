import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CmsEntryResultFunnelSummary } from '@app/cms/results/cms-entry-result-funnel-summary';
import { Account } from '@app/account/account';
import { ReportFilters } from '@app/cms/funnel-report-filter/funnel-report-filter.component';

@Component({
	selector: 'rd-cms-entry-funnel-summary',
	templateUrl: './cms-entry-funnel-summary.component.html'
})
export class CmsEntryFunnelSummaryComponent implements OnInit {
	@Input()
	cmsResult: CmsEntryResultFunnelSummary;

	@Input()
	sessionUser: Account;

	defaults: ReportFilters = {};

	constructor(private router: Router, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.defaults = {
			reportId: this.route.snapshot.queryParams.id || this.cmsResult.mostRecentReport.id
		};
	}

	onFilterUpdate(filters: ReportFilters) {
		const id = filters.reportId;
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { id }
		});
	}
}
