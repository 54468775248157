<p>The data below was collected during the most recent iteration of the funnel report. Add schools to your watchlist to easily compare and contrast a smaller group of institutions.</p>

<rd-alert type="info" *ngIf="cmsResult.report.doc_url">
	<h4>Related Documents Now Available</h4>
	<a href="{{ cmsResult.report.doc_url | fullResourcePath }}" target="_blank">{{ cmsResult.report.doc_url | filename }}</a>
</rd-alert>

<div class="export-report">
	<!-- START:
	If report is for December, fix for displaying year of report
	-->
	<h2 *ngIf="!cmsResult.report.isFinalReport && cmsResult.report.month !== 12">{{ cmsResult.report.monthLabel }} {{ cmsResult.report.year }}
	vs
	{{ cmsResult.report.monthLabel }} {{ cmsResult.report.year - 1 }}</h2>
	<h2 *ngIf="!cmsResult.report.isFinalReport && cmsResult.report.month === 12">{{ cmsResult.report.monthLabel }} {{ cmsResult.report.year + 1 }}
		vs
		{{ cmsResult.report.monthLabel }} {{ cmsResult.report.year }}</h2>
	<h2 *ngIf="cmsResult.report.isFinalReport">Fall {{ cmsResult.report.year }} Enrollment Year, Final Report</h2>
	<!-- END:
	If report is for December, fix for displaying year of report
	-->

	<a *ngIf="sessionUser.isAdmin && cmsResult.report.isFinalReport" (click)="exportFinal()" class="btn btn--ghost btn--ghost--gold float--right">Export final report</a>
	<a *ngIf="isVerifiedToViewResults" (click)="export()" class="btn btn--gold float--right">Export report</a>
</div>

<rd-funnel-report-filter
	[reports]="cmsResult.completedReports"
	[includeSearch]="true"
	[defaults]="defaults"
	(update)="onFilterUpdate($event)"
></rd-funnel-report-filter>
<ng-container *ngIf="isVerifiedToViewResults">
	<table class="funnel--report table--striped">
		<thead class="funnel--report__header">
			<tr>
				<th class="funnel--report__leftcolumn">
					School<br>
					Select checkbox to add to watchlist
				</th>
				<th class="funnel--report__column--category" [attr.colspan]="cmsResult.report.isFinalReport ? 10 : 6">
					Freshmen
					<table>
						<tr>
							<td colspan="2" class="funnel--report__column--funnel">Applicants</td>
							<td colspan="2" class="funnel--report__column--funnel">Admits</td>
							<td *ngIf="!cmsResult.report.isFinalReport" colspan="2" class="funnel--report__column--funnel">Deposits</td>
							<td *ngIf="cmsResult.report.isFinalReport" colspan="2" class="funnel--report__column--funnel">Total Deposits Received</td>
							<td *ngIf="cmsResult.report.isFinalReport" colspan="2" class="funnel--report__column--funnel">Deposits Canceled After May 1</td>
							<td *ngIf="cmsResult.report.isFinalReport" colspan="2" class="funnel--report__column--funnel">Enrollments</td>
						</tr>
						<tr>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
							<td *ngIf="cmsResult.report.isFinalReport" class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td *ngIf="cmsResult.report.isFinalReport" class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
							<td *ngIf="cmsResult.report.isFinalReport" class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td *ngIf="cmsResult.report.isFinalReport" class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
					</table>
				</th>
				<th class="funnel--report__column--category" [attr.colspan]="cmsResult.report.isFinalReport ? 10 : 6">
					Transfer
					<table>
						<tr>
							<td colspan="2" class="funnel--report__column--funnel">Applicants</td>
							<td colspan="2" class="funnel--report__column--funnel">Admits</td>
							<td *ngIf="!cmsResult.report.isFinalReport" colspan="2" class="funnel--report__column--funnel">Deposits</td>
							<td *ngIf="cmsResult.report.isFinalReport" colspan="2" class="funnel--report__column--funnel">Total Deposits Received</td>
							<td *ngIf="cmsResult.report.isFinalReport" colspan="2" class="funnel--report__column--funnel">Deposits Canceled After May 1</td>
							<td *ngIf="cmsResult.report.isFinalReport" colspan="2" class="funnel--report__column--funnel">Enrollments</td>
						</tr>
						<tr>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
							<td *ngIf="cmsResult.report.isFinalReport" class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td *ngIf="cmsResult.report.isFinalReport" class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
							<td *ngIf="cmsResult.report.isFinalReport" class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td *ngIf="cmsResult.report.isFinalReport" class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
					</table>
				</th>
				<th class="funnel--report__column--category" [attr.colspan]="cmsResult.report.isFinalReport ? 10 : 6">
					Total
					<table>
						<tr>
							<td colspan="2" class="funnel--report__column--funnel">Applicants</td>
							<td colspan="2" class="funnel--report__column--funnel">Admits</td>
							<td *ngIf="!cmsResult.report.isFinalReport" colspan="2" class="funnel--report__column--funnel">Deposits</td>
							<td *ngIf="cmsResult.report.isFinalReport" colspan="2" class="funnel--report__column--funnel">Total Deposits Received</td>
							<td *ngIf="cmsResult.report.isFinalReport" colspan="2" class="funnel--report__column--funnel">Deposits Canceled After May 1</td>
							<td *ngIf="cmsResult.report.isFinalReport" colspan="2" class="funnel--report__column--funnel">Enrollments</td>
						</tr>
						<tr>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
							<td *ngIf="cmsResult.report.isFinalReport" class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td *ngIf="cmsResult.report.isFinalReport" class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
							<td *ngIf="cmsResult.report.isFinalReport" class="funnel--report__column--year">{{ cmsResult.report.reportYear }}</td>
							<td *ngIf="cmsResult.report.isFinalReport" class="funnel--report__column--year">{{ cmsResult.report.reportYear - 1 }}</td>
					</table>
				</th>
				<th class="funnel--report__column--last">
					<ng-container *ngIf="cmsResult.report.isFinalReport">
						{{ cmsResult.report.reportYear }} Actual
					</ng-container>
					<ng-container *ngIf="!cmsResult.report.isFinalReport">
						{{ cmsResult.report.reportYear }} Goal
					</ng-container>
				</th>
				<th class="funnel--report__column--last">
					<ng-container *ngIf="cmsResult.report.isFinalReport">
						{{ cmsResult.report.reportYear }} Goal
					</ng-container>
					<ng-container *ngIf="!cmsResult.report.isFinalReport">
						{{ cmsResult.report.reportYear - 1 }} Actual
					</ng-container>
				</th>
				<th class="funnel--report__column--last">Report Status</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let report of cmsResult.paginatedReports.items">
				<td class="funnel--report__leftcolumn">
					<label class="label checkbox">{{ report.org }}
						<ng-container *ngIf="report.naccap_orgs_id != sessionUser.orgId">
							<input type="checkbox" [(ngModel)]="report._is_on_watchlist" name="watchlist[]" (change)="onWatchlistChanged(report)">
							<span class="checkmark"></span>
						</ng-container>
					</label>
				</td>
				<td>{{ report.fr_app_curr }}</td>
				<td>{{ report.fr_app_prev }}</td>
				<td>{{ report.fr_adm_curr }}</td>
				<td>{{ report.fr_adm_prev }}</td>
				<td>{{ report.fr_dep_curr }}</td>
				<td>{{ report.fr_dep_prev }}</td>
				<td *ngIf="cmsResult.report.isFinalReport">{{ report.fr_dep_canceled_curr }}</td>
				<td *ngIf="cmsResult.report.isFinalReport">{{ report.fr_dep_canceled_prev }}</td>
				<td *ngIf="cmsResult.report.isFinalReport">{{ report.fr_enr_curr }}</td>
				<td *ngIf="cmsResult.report.isFinalReport">{{ report.fr_enr_prev }}</td>

				<td>{{ report.tr_app_curr }}</td>
				<td>{{ report.tr_app_prev }}</td>
				<td>{{ report.tr_adm_curr }}</td>
				<td>{{ report.tr_adm_prev }}</td>
				<td>{{ report.tr_dep_curr }}</td>
				<td>{{ report.tr_dep_prev }}</td>
				<td *ngIf="cmsResult.report.isFinalReport">{{ report.tr_dep_canceled_curr }}</td>
				<td *ngIf="cmsResult.report.isFinalReport">{{ report.tr_dep_canceled_prev }}</td>
				<td *ngIf="cmsResult.report.isFinalReport">{{ report.tr_enr_curr }}</td>
				<td *ngIf="cmsResult.report.isFinalReport">{{ report.tr_enr_prev }}</td>

				<td>{{ report.tns_app_curr }}</td>
				<td>{{ report.tns_app_prev }}</td>
				<td>{{ report.tns_adm_curr }}</td>
				<td>{{ report.tns_adm_prev }}</td>
				<td>{{ report.tns_dep_curr }}</td>
				<td>{{ report.tns_dep_prev }}</td>
				<td *ngIf="cmsResult.report.isFinalReport">{{ report.tns_dep_canceled_curr }}</td>
				<td *ngIf="cmsResult.report.isFinalReport">{{ report.tns_dep_canceled_prev }}</td>
				<td *ngIf="cmsResult.report.isFinalReport">{{ report.tns_enr_curr }}</td>
				<td *ngIf="cmsResult.report.isFinalReport">{{ report.tns_enr_prev }}</td>

				<td>{{ report.curr_goal }}</td>
				<td>{{ report.prev_actual }}</td>
				<td>{{ report.statusLabel }}</td>
			</tr>
		</tbody>
	</table>

	<rd-pagination *ngIf="cmsResult.paginatedReports" [perPage]="cmsResult.paginatedReports.per_page" [page]="cmsResult.paginatedReports.page" [numItems]="cmsResult.paginatedReports.count[0].total" (onPageChanged)="onPageChanged($event)"></rd-pagination>
</ng-container>

<rd-alert type="warning" *ngIf="!isVerifiedToViewResults">
	<h2>Not Authorized</h2>
	<p>You must have your organization’s data verified for this report to view this page.</p>
	<p>You may view previous reports for which you provided verified data using the filters above.</p>
</rd-alert>