import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CmsEntryResultAbsList } from '@app/cms/results/cms-entry-result-abs-list';
import { AccountService } from '@app/account/account.service';
import { Account } from '@app/account/account';
import { ReportFilters } from '@app/cms/funnel-report-filter/funnel-report-filter.component';

@Component({
	selector: 'rd-cms-entry-abs-list',
	templateUrl: './cms-entry-abs-list.component.html'
})
export class CmsEntryAbsListComponent implements OnInit {
	@Input()
	cmsResult: CmsEntryResultAbsList;

	@Input()
	sessionUser: Account;

	page = 1;

	filters: ReportFilters = {};
	defaults: ReportFilters = {};

	constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService) {}

	ngOnInit() {
		this.defaults = {
			reportId: this.route.snapshot.queryParams.id || this.cmsResult.report.id,
			search: this.route.snapshot.queryParams.search
		};
		this.page = this.route.snapshot.queryParams.page;
	}

	get reportId(): number {
		return this.filters.reportId || this.defaults.reportId!;
	}

	get search(): string {
		return this.filters.search || '';
	}

	get isVerifiedToViewResults(): boolean {
		return this.sessionUser.isAdmin || this.cmsResult.isEntryVerified;
	}

	isVerifiedToViewPartC(orgId: number): boolean {
		return this.cmsResult.isVerifiedToViewPartC(this.sessionUser, orgId);
	}

	export() {
		this.accountService.exportAbsBudget(this.cmsResult.report.id);
	}

	onPageChanged(page: number) {
		this.page = page;
		this.updatePage();
	}

	onFilterUpdate(filters: ReportFilters) {
		this.filters = filters;
		this.page = 1;
		this.updatePage();
	}

	updatePage() {
		const page = this.page;
		const search = this.search;
		const id = this.reportId;
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { page, search, id }
		});
	}
}
