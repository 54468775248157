import { Component, OnInit, Input } from '@angular/core';

import { CmsEntryResultAbsSubmit } from '@app/cms/results/cms-entry-result-abs-submit';
import { Account } from '@app/account/account';

@Component({
	selector: 'rd-cms-entry-abs-submit',
	templateUrl: './cms-entry-abs-submit.component.html'
})
export class CmsEntryAbsSubmitComponent implements OnInit {
	@Input()
	cmsResult: CmsEntryResultAbsSubmit;

	@Input()
	sessionUser: Account;

	constructor() {}

	ngOnInit() {}
}
