import { Component, Input, OnInit, AfterViewChecked } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { CmsEntryResult } from '@app/cms/results/cms-entry-result';
import { Account } from '@app/account/account';

@Component({
	selector: 'rd-cms-entry-subpage',
	templateUrl: './cms-entry-subpage.component.html'
})
export class CmsEntrySubpageComponent implements OnInit, AfterViewChecked {
	@Input()
	cmsResult: CmsEntryResult;

	@Input()
	sessionUser: Account;

	@Input()
	type: string | null = null; // specify a subtype, such as the search page to make tweaks.
	safeContent: SafeHtml | null = null;
	googleSearch: SafeHtml | null = null;
	private fragment: string | null = null;

	constructor(
		// private sanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit(): void {
		this.route.fragment.subscribe(fragment => (this.fragment = fragment));
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				const urlTree = this.router.parseUrl(this.router.url);
				this.resolveFragment(urlTree.fragment!);
			}
		});
	}

	ngAfterViewChecked(): void {
		this.resolveFragment(this.fragment!);
	}

	ngOnChanges(): void {
		// let content = this.cmsResult!.mainContent || '';
		// this.safeContent = this.sanitizer.bypassSecurityTrustHtml(content);
	}

	private resolveFragment(fragment: string) {
		if (fragment) {
			const element = document.querySelector('#' + fragment);
			if (element) {
				element.scrollIntoView(true);
			}
		}
	}
}
