import { inheritSerialization } from 'cerialize';

import { Account } from '@app/account/account';
import { CmsEntryResult } from './cms-entry-result';

@inheritSerialization(CmsEntryResult)
export class CmsEntryResultAbsBase extends CmsEntryResult {
	isVerifiedToViewPartC(user: Account, orgId: number): boolean {
		return user.isAdmin || (user.orgId === orgId && user.isOrgSuperuser);
	}
}
