import { deserialize, inheritSerialization } from 'cerialize';
import { get } from 'lodash';

import { CmsEntryResultAbsBase } from './cms-entry-result-abs-base';

@inheritSerialization(CmsEntryResultAbsBase)
export class CmsEntryResultAbsList extends CmsEntryResultAbsBase {
	@deserialize
	public completedAbsReports: any;

	@deserialize
	public report: any;

	@deserialize
	public paginatedReports: any;

	@deserialize
	public reportEntry: any;

	get isEntryVerified(): boolean {
		return get(this.reportEntry, 'status') == 2;
	}
}
