import { Component, OnInit, Input } from '@angular/core';

import { CmsEntryResultFunnelSummary } from '@app/cms/results/cms-entry-result-funnel-summary';

@Component({
	selector: 'rd-funnel-summary-section',
	templateUrl: './funnel-summary-section.component.html',
	styleUrls: ['./funnel-summary-section.component.css']
})
export class FunnelSummarySectionComponent implements OnInit {
	constructor() {}

	@Input()
	public cmsResult: CmsEntryResultFunnelSummary;

	@Input()
	public field: string;

	@Input()
	public title: string;

	@Input()
	public type: string;

	ngOnInit() {}
}
