import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { get, find } from 'lodash';

import { CmsEntryResultAbsLanding } from '@app/cms/results/cms-entry-result-abs-landing';
import { Account } from '@app/account/account';
import { AccountService } from '@app/account/account.service';

@Component({
	selector: 'rd-cms-entry-abs-landing',
	templateUrl: './cms-entry-abs-landing.component.html'
})
export class CmsEntryAbsLandingComponent implements OnInit, OnChanges {
	constructor(public accountService: AccountService) {}

	@Input()
	cmsResult: CmsEntryResultAbsLanding;

	@Input()
	sessionUser: Account;

	@Input()
	urls: any;

	filters: any = {
		publicReport: null,
		memberReport: null
	};

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if ((get(changes, 'cmsResult', false) || get(changes, 'sessionUser', false)) && this.cmsResult) {
			const reportId = get(changes, 'cmsResult.currentValue.report.id');
			const publicReport = find(
				get(changes, 'cmsResult.currentValue.completedAbsReports', []) as any[],
				report => report.id == reportId
			);
			this.filters.publicReport = publicReport;

			const memberReport = find(this.getValidatedReportsForUser(), (report: any) => report.id == reportId);
			this.filters.memberReport = memberReport;
		}
	}

	getValidatedReportsForUser(): any {
		if (get(this.sessionUser, 'isAdmin', false)) {
			return get(this.cmsResult, 'completedAbsReports', []);
		}

		return get(this.cmsResult, 'verifiedAbsReportsForUser', []);
	}
}
