import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'rd-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
	constructor() {}

	@Input()
	type: string;

	ngOnInit() {}
}
