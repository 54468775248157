import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
import { trim } from 'lodash';

import { CmsEntryResultAbsSubmit } from '@app/cms/results/cms-entry-result-abs-submit';
import { Account } from '@app/account/account';
import { AbsEntryService } from '@app/cms/abs-submit/abs-entry.service';

@Component({
	selector: 'rd-abs-submit-comment',
	templateUrl: './abs-submit-comment.component.html'
})
export class AbsSubmitCommentComponent implements OnChanges {
	@Input()
	cmsResult: CmsEntryResultAbsSubmit;

	@Input()
	user: Account;

	@Input()
	entryId: number;

	@Input()
	part: string; // a, b, c or d

	newComment: any = '';
	error: { message: string } | null = null;
	comments: any;

	constructor(private absEntryService: AbsEntryService) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.entryId) {
			this.getComments();
		}
		if (changes.part) {
			this.getComments();
		}
	}

	async getComments() {
		if (!this.entryId) {
			return;
		}
		if (!this.part) {
			return;
		}
		this.comments = await this.absEntryService.getComments(this.part, this.entryId);
	}

	addComment() {
		this.error = null;
		const isCommentEmpty = trim(this.stripTags(this.newComment));
		if (!isCommentEmpty) {
			this.error = { message: 'Please add a comment first.' };
			return;
		}
		this.absEntryService
			.addComment(this.part, this.user.fullName, this.newComment, this.user.userId, this.entryId)
			.then(() => {
				if (this.user.isAdmin) {
					this.absEntryService.sendCommentToOrg(
						this.cmsResult.orgId,
						this.cmsResult.surveyId,
						this.part,
						this.newComment
					);
				} else {
					this.absEntryService.sendCommentToAdmin(
						this.user.userId,
						this.cmsResult.surveyId,
						this.part,
						this.newComment
					);
				}
				this.getComments();
				this.newComment = '';
			})
			.catch((data: any) => {
				this.error = { message: data.error.error.message };
			});
	}

	stripTags(str: string) {
		const html = str;
		const div = document.createElement('div');
		div.innerHTML = html;
		return div.textContent || div.innerText || '';
	}
}
