<div class="comments">
	<h2>Comments</h2>
	<p>Use this field to enter any comments about this portion of the form, e.g., explanations for missing or irregular data.</p>
	<div class="comments--previous" *ngIf="comments">
		<div class="comment--entry" *ngFor="let comment of comments">
			<div class="comment--title">{{ comment.name }}</div>
			<div class="comment--date">{{ comment.created_at | date:'mediumDate':'utc' }}</div>
			<div class="comment--content" [innerHtml]="comment.comment"></div>
		</div>
	</div>
	<ng-container *ngIf="entryId && part">
		<quill-editor [(ngModel)]="newComment"></quill-editor>
		<p class="text--right">
			<a (click)="addComment()" class="btn btn--gold">Comment</a>
		</p>
	</ng-container>
	<rd-alert type="warning" *ngIf="!entryId">
		<p>In order to add a comment, please save the form first.</p>
	</rd-alert>
	<rd-alert type="danger" *ngIf="error">
		<h4>Error</h4>
		{{ error.message }}
	</rd-alert>
</div>