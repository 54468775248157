import { Component, OnChanges, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';

const MAX_NUM_OF_PAGES = 11;

@Component({
	selector: 'rd-pagination',
	templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnChanges {
	@Input()
	page: number;

	@Input()
	perPage: number;

	@Input()
	numItems: number;

	@Output()
	onPageChanged = new EventEmitter<number>();

	pageLast: number;
	pages: number[];
	showFirst: boolean;
	showLast: boolean;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		this.refresh();
	}

	refresh() {
		const total = Math.ceil(this.numItems / this.perPage);
		this.pageLast = total;
		this.pages = [];
		let first = 1;
		let last = total;
		if (total > 1) {
			if (total > MAX_NUM_OF_PAGES) {
				if (this.page >= total - 5) {
					first = total - MAX_NUM_OF_PAGES;
				} else if (this.page - 5 > 0) {
					first = this.page - 5;
				}
				last = this.page < 6 ? 10 : first + MAX_NUM_OF_PAGES;
			}
			for (let i = first; i <= last; i++) {
				this.pages.push(i);
			}
		}
		this.showFirst = first > 1;
		this.showLast = last < total;
	}

	onNext() {
		if (this.page >= this.pageLast) {
			this.page = this.pageLast;
			return;
		}
		this.page++;
		this.refresh();
		this.onPageChanged.emit(this.page);
	}

	onPrev() {
		if (this.page <= 1) {
			this.page = 1;
			return;
		}
		this.page--;
		this.refresh();
		this.onPageChanged.emit(this.page);
	}

	onPage(page: number) {
		this.page = page;
		this.refresh();
		this.onPageChanged.emit(this.page);
	}
}
