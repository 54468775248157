<hr class="hr--thick">
<h2>Admin</h2>
<ng-container *ngIf="!savedVerification && !savedReject">
	<p>You may now verify the data using the buttons below.</p>
	<input name="submit_verify" class="btn btn--gold" type="submit" (click)="verify()" [disabled]="isSubmitting" value="Verify Data" />
	<input name="submit_reject" class="btn btn--ghost btn--ghost--gold" type="submit" (click)="reject()" [disabled]="isSubmitting" value="Place data on Hold" />
	<quill-editor placeholder="Reason for placing data on hold..." name="reject_reason" id="reject-reason" [(ngModel)]="rejectReason"></quill-editor>

	<rd-alert type="danger" *ngIf="error">
		<h4>Error</h4>
		{{ error.message }}
	</rd-alert>
</ng-container>
<rd-alert type="success" *ngIf="savedVerification">
	<h4>Success</h4>
	You have successfully validated this report.
</rd-alert>
<rd-alert type="warning" *ngIf="savedReject">
	<h4>Success</h4>
	You have successfully placed this report on hold. An email will be sent to the users of this organization letting them know that their report has been reopened.
</rd-alert>