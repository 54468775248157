<div class="card">
	<div class="card__content">
		<h3>Build a Custom Report</h3>
		<h4>Admin Only</h4>
		<form>
			<div class="form__element">
				<label class="label">Select an organization</label>
				<ng-select 
					class="Select an organization"
					placeholder="Select an organization"
					name="org_id"
					[items]="orgs"
					bindLabel="org"
					bindValue="id"
					[(ngModel)]="form.org_id"
					(change)="onOrgChanged()"
					>
				</ng-select>
			</div>

			<div class="form__element">
				<label class="label">Select one or more organizations for comparison</label>
				<ng-select 
					class="export--report-combobox"
					placeholder="Select one or more organizations"
					name="comparison_schools"
					[items]="orgs"
					bindLabel="org"
					bindValue="id"
					[closeOnSelect]="false"
					[multiple]="true"
					[(ngModel)]="form.comparison_schools">
				</ng-select>
			</div>

			<button class="btn btn--gold" (click)="sendUpdate()" [disabled]="!form.org_id">Go</button>
			&nbsp;
			<button class="btn btn--ghost btn--ghost--gold" (click)="reset()">Reset</button>
		</form>
	</div>
</div>