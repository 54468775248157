import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
@Pipe({ name: 'filename' })
export class Filename implements PipeTransform {
	transform(path: string): string {
		return getFilenameFromPath(path);
	}
}

export function getFilenameFromPath(path: string): string {
	if (!path) {
		return path;
	}
	return path.replace(/^.*[\\\/]/, '');
}
