<div class="homepage">
	<div class="feature__image">
		<div class="feature__image--inner" [style.background-image]="'url(' + (cmsResult.headerImage | fullResourcePath) + ')'"></div>
	</div>
	<div class="container content">
		<div class="feature__hero">
			<h1>NACCAP Enrollment Research Project</h1>
			<div class="grid__row">
				<div class="grid__col--6 grid__col-offset--1">
					<div *ngIf="cmsResult.title1 || cmsResult.content1" class="feature__item feature__item--status">
						<h2 *ngIf="cmsResult.title1">{{ cmsResult.title1 }}</h2>
						<div [innerHtml]="cmsResult.content1"></div>
					</div>
					<div *ngIf="cmsResult.title2 || cmsResult.content2" class="feature__item feature__item--news">
						<h2 *ngIf="cmsResult.title2">{{ cmsResult.title2 }}</h2>
						<div [innerHtml]="cmsResult.content2"></div>
					</div>
				</div>
				<div *ngIf="cmsResult.title3 || cmsResult.content3" class="grid__col--3 grid__col-offset--1">
					<h2 *ngIf="cmsResult.title3">{{ cmsResult.title3 }}</h2>
					<div [innerHtml]="cmsResult.content3"></div>
				</div>
			</div>
		</div>
		
		
		<!-- IF LOGGED OUT -->
		<ng-container *ngIf="cmsResult.showTestimonial && (!sessionUser || !sessionUser.userId)">
			<div class="testimony">
				<h4 class="testimony__title">{{ cmsResult.testimonialTitle }}</h4>
				<div class="testimony__content" [innerHtml]="cmsResult.testimonialContent"></div>
				<img src="/assets/images/quote_icon.svg" class="quote--icon">
				<div class="testimony__name">{{ cmsResult.testimonialName }}</div>
				<div class="testimony__institution">{{ cmsResult.testimonialOrg }}</div>
			</div>
			<hr class="hr">
		</ng-container>
		<!-- END OF LOGGED OUT -->
				
		<div class="abs-report grid__row">
			<div class="grid__col--4 grid__col-offset--1">
				<h2>{{ cmsResult.absTitle }}</h2>
				<div [innerHtml]="cmsResult.absContent"></div>
				<div *ngIf="sessionUser && sessionUser.userId">
					<div *ngIf="cmsResult.openAbsReport">
						<p>{{ cmsResult.openAbsReport.year }} study now open for submission!</p>
						<a [routerLink]="cmsResult.urls['abs-submit']" class="btn btn--gold">Submit Data</a>
					</div>
				
					<div *ngIf="!cmsResult.openAbsReport">
						<div *ngIf="cmsResult.mostRecentAbsReport.status == 2; else absClosed">
							<p>{{ cmsResult.mostRecentAbsReport.year }} study results available!</p>
							<a [routerLink]="cmsResult.urls['abs-landing']" class="btn btn--gold">View Data</a>
						</div>
						<ng-template #absClosed>
							<p>{{ cmsResult.mostRecentAbsReport.year }} study submission closed. Results coming soon!</p>
						</ng-template>
					</div>
					<p>ABS participant? <a [routerLink]="cmsResult.urls['abs-landing']">View participant-only data &rsaquo;</a></p>
				</div>
				<div *ngIf="!sessionUser || !sessionUser.userId">
					<a [routerLink]="cmsResult.urls['abs-landing']" class="btn btn--gold">{{ cmsResult.absLinkText }}</a>
				</div>
			</div>
			<div class="grid__col--5 grid__col-offset--1 card">
				<div class="table__filters table__filters--skinny">
					<div class="table__filters--title">{{ filters.absReport.year }} Results</div>
					<div class="table__filters--past">
						Sort by year
						<select name="filter_year" id="abs-results-filter" [(ngModel)]="filters.absReport">
							<option *ngFor="let report of cmsResult.completedAbsReports" [ngValue]="report">{{ report.year }}</option>
						</select>
					</div>
				</div>
				<div class="card__content">
					<ul>
						<li *ngIf="filters.absReport.summary_doc"><a target="_blank" href="{{ filters.absReport.summary_doc | fullResourcePath }}">Download {{ filters.absReport.year }} Summary Report</a></li>
						<li><a (click)="accountService.exportCostToRecruit(filters.absReport.id)">Download Cost to Recruit Summary</a></li>
						<li><a (click)="accountService.exportAbsFunnelSummary(filters.absReport.id)">Download Funnel Summary</a></li>
					</ul>
				</div>
			</div>
		</div>
		
		<div class="funnel-report grid__row">
			<!-- For mobile only, to ensure Funnel report title comes before table -->
			<div class="grid__col--4 grid__col-offset--1 funnel-report--mobile">	
				<h2>{{ cmsResult.funnelTitle }}</h2>
				<div>
					<div [innerHtml]="cmsResult.funnelContent"></div>
					<a [routerLink]="cmsResult.urls['funnel-summary']" class="btn btn--gold">{{ cmsResult.funnelLinkText }}</a>
				</div>
			</div>
			<!-- end of For mobile only...  -->			
			
			<div *ngIf="sessionUser && sessionUser.userId" class="grid__col--6 grid__col-offset--1 card">
				<div class="table__filters table__filters--skinny">
					<div class="table__filters--title"><span *ngIf="filters.funnelReport && filters.funnelReport[0]">{{ filters.funnelReport[0].reportYear }}</span> Results</div>
					<div class="table__filters--past">
						Sort by enrollment year
						<select name="filter_year" id="funnel-results-filter" [(ngModel)]="filters.funnelReport">
							<option *ngFor="let reportGroup of funnelReportGroups" [ngValue]="reportGroup">{{ reportGroup[0].reportYear }}</option>
						</select>
					</div>
				</div>
				<div class="table--responsive">
					<table class="table table--striped" *ngIf="filters.funnelReport">
						<thead>
							<tr>
								<th>Report</th>
								<th>Results</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let report of filters.funnelReport">
								<th>
									<span *ngIf="report.isFinalReport">Final Report</span>
									<span *ngIf="!report.isFinalReport">{{ report.monthLabel }}</span>
								</th>
								<td>
									<span *ngIf="report.isOpen">
										Data collection in progress <a [routerLink]="cmsResult.urls['funnel-report']" [queryParams]="{ id: report.id }">Submit</a>
									</span>
									<span *ngIf="!report.isOpen">
										Study Complete.
										<a [routerLink]="cmsResult.urls['funnel-landing']" [queryParams]="{ id: report.id }">View Data</a>&nbsp;|&nbsp;
										<a [routerLink]="cmsResult.urls['funnel-summary']" [queryParams]="{ id: report.id }">View Summary</a>
										<ng-container *ngIf="report.doc_url">&nbsp;|&nbsp;<a target="_blank" href="{{ report.doc_url | fullResourcePath }}">View PDF</a></ng-container>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div *ngIf="cmsResult.funnelImage && (!sessionUser || !sessionUser.userId)" class="grid__col--5 grid__col-offset--1">
				<img [src]="cmsResult.funnelImage | fullResourcePath" class="mobile--reports">
			</div>
			<div class="grid__col--4 grid__col-offset--1 funnel-report--mobile-second">	
				<h2>{{ cmsResult.funnelTitle }}</h2>
				<div>
					<div [innerHtml]="cmsResult.funnelContent"></div>
					<a [routerLink]="cmsResult.urls['funnel-summary']" class="btn btn--gold">{{ cmsResult.funnelLinkText }}</a>
				</div>
			</div>
		</div>
		<!-- END OF LOGGED IN -->
	</div> 
	<!-- IF LOGGED OUT -->
	<div *ngIf="cmsResult.showContactSection && (!sessionUser || !sessionUser.userId)" class="join-study">
		<div class="container content">
			<div class="grid__row">
				<div class="grid__col--5 grid__col-offset--1">	
					<h2>{{ cmsResult.contactTitle }}</h2>
					<div [innerHtml]="cmsResult.contactContent"></div>
				</div>
				<div class="grid__col--4 grid__col-offset--1">
					<form [formGroup]="form" (ngSubmit)="onContactSubmit()" class="label--uppercase">
						<div class="form__element">
							<label for="contact-new-name" class="label">Full Name</label>
							<input type="text" id="contact-new-name" formControlName="new_fullname">
							<rd-alert *ngIf="doesControlHaveError(form.controls.new_fullname)" type="danger">This field is required.</rd-alert>
						</div>
						<div class="form__element">
							<label for="contact-new-title" class="label">Title</label>
							<input type="text" id="contact-new-title" formControlName="new_title">
							<rd-alert *ngIf="doesControlHaveError(form.controls.new_title)" type="danger">This field is required.</rd-alert>
						</div>
						<div class="form__element">
							<label for="contact-new-institution" class="label">Institution</label>
							<input type="text" id="contact-new-institution" formControlName="new_institution">
							<rd-alert *ngIf="doesControlHaveError(form.controls.new_institution)" type="danger">This field is required.</rd-alert>
						</div>
						<div class="form__element">
							<label for="contact-new-work-email" class="label">Email Address</label>
							<input type="email" id="contact-new-work-email" formControlName="new_email">
							<rd-alert *ngIf="doesControlHaveError(form.controls.new_email)" type="danger">This field is required.</rd-alert>
						</div>
						<input type="submit" class="btn btn--gold submit" value="Submit for Review" [disabled]="isSending"/>
						<rd-alert type="danger" *ngIf="error">
							<h4>Error</h4>
							{{ error.message }}
						</rd-alert>
						<rd-alert type="success" *ngIf="saved">
							<h4>Sent</h4>
							Thank you, your message has been sent.
						</rd-alert>
					</form>		
				</div>
			</div>
		</div>
	</div>
	<!-- END OF LOGGED OUT -->
</div>