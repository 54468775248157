import { deserialize, inheritSerialization } from 'cerialize';

import { CmsEntryResult } from './cms-entry-result';

@inheritSerialization(CmsEntryResult)
export class CmsEntryResultReportHistory extends CmsEntryResult {
	@deserialize
	public completedAbsReports: any;

	@deserialize
	public completedFunnelReports: any;

	@deserialize
	public funnelReports: any;

	@deserialize
	public funnelReportsData: any;

	@deserialize
	public absReports: any;

	@deserialize
	public orgs: any;
}
