<div class="grid__row">
	<div class="grid__col--6 grid__col-offset--3">
		<div class="card">
			<div class="card__content">
				<form name="historicalSpreadsheets" id="historical-spreadsheets">
					<div class="form__element">
						<label class="label">Select an organization</label>
						<select required name="org_id" [(ngModel)]="form.org_id">
							<option value=""></option>
							<option *ngFor="let org of cmsResult.orgs" value="{{ org.id }}">{{ org.org }}</option>
						</select>
					</div>
					<div class="form__element">
						<label class="label">Select report type</label>
						<select required name="report" [(ngModel)]="form.type">
							<option value=""></option>
							<option value="abs_budget">ABS Budget Data</option>
							<option value="abs_funnel">ABS Funnel Data</option>
							<option value="funnel_reports">Funnel Reports</option>
						</select>
					</div>
					<div class="form__element checkbox">
						<label>
							<input type="checkbox" name="include_watchlist" [(ngModel)]="form.include_watchlist" />
							Include the organization's watchlist?
						</label>
					</div>
					<ng-container *ngIf="form.type">
						<h3>Select the Reports for Export</h3>
						<div class="form__element">
							<ng-container *ngIf="form.type == 'abs_budget' || form.type == 'abs_funnel'">
								<label class="label">Select one or more reports</label>
								<ng-select 
									class="export--report-combobox"
									placeholder="Select one or more reports"
									name="abs_reports"
									[items]="cmsResult.completedAbsReports"
									bindLabel="year"
									bindValue="id"
									[closeOnSelect]="false"
									[multiple]="true"
									[(ngModel)]="form.abs_reports">
								</ng-select>
							</ng-container>
							<ng-container *ngIf="form.type == 'funnel_reports'">
								<label class="label">Select one or more reports</label>
								<ng-select 
									class="export--report-combobox"
									placeholder="Select one or more reports"
									name="funnel_reports"
									[items]="cmsResult.completedFunnelReports"
									bindLabel="label"
									bindValue="id"
									groupBy="reportYear"
									[selectableGroup]="true"
									[selectableGroupAsModel]="false"
									[closeOnSelect]="false"
									[multiple]="true"
									[(ngModel)]="form.funnel_reports">
								</ng-select>
							</ng-container>
							<br>
							<rd-alert type="info">Leave blank to export all verified reports.</rd-alert>
						</div>
					</ng-container>
					<input name="submit" type="submit" id="submit" class="btn btn--gold" value="Export" (click)="export()" />
					<rd-alert type="danger" *ngIf="error">
						<h2>Error</h2>
						{{ error.message }}
					</rd-alert>
				</form>
			</div>
		</div>
	</div>
</div>