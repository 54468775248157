import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
@Pipe({ name: 'fullResourcePath' })
export class FullResourcePath implements PipeTransform {
	transform(path: string): string {
		return transformFullResourcePath(path);
	}
}

export function transformFullResourcePath(path: string): string {
	if (!path) {
		return path;
	}
	const resourceRoot = 'https://s3.amazonaws.com/naccap';
	if (path.indexOf(resourceRoot) === 0) {
		return path;
	}
	return resourceRoot + path;
}
