import { deserialize, inheritSerialization } from 'cerialize';

import { CmsEntryResultFunnelBase } from './cms-entry-result-funnel-base';

import { ChartData } from '@app/core/chart/chart.component';

@inheritSerialization(CmsEntryResultFunnelBase)
export class CmsEntryResultFunnelSummary extends CmsEntryResultFunnelBase {
	@deserialize
	public report: any;

	@deserialize
	public summaryData: any;

	@deserialize
	public mostRecentReport: any;

	public getChartDataByField(field: string): ChartData {
		const data: number[] = [];
		const labels: string[] = [];
		if (this.summaryData[`${field}_more`] > 0) {
			data.push(this.summaryData[`${field}_more`]);
			labels.push('More');
		}
		if (this.summaryData[`${field}_fewer`] > 0) {
			data.push(this.summaryData[`${field}_fewer`]);
			labels.push('Fewer');
		}
		if (this.summaryData[`${field}_less`] > 0) {
			data.push(this.summaryData[`${field}_less`]);
			labels.push('Less');
		}
		if (this.summaryData[`${field}_even`] > 0) {
			data.push(this.summaryData[`${field}_even`]);
			labels.push('Even');
		}
		if (field === 'vs_goal' && this.summaryData[`vs_goal_no_goal`] > 0) {
			data.push(this.summaryData[`vs_goal_no_goal`]);
			labels.push('No Goal');
		} else if (this.summaryData[`${field}_nc`] > 0) {
			data.push(this.summaryData[`${field}_nc`]);
			labels.push('No Comp.');
		}
		return { data, labels };
	}
}
