import { AfterContentInit, ContentChildren, Directive, ElementRef, HostListener, QueryList } from '@angular/core';
import { Router } from '@angular/router';

/**
 * This component is derived from https://github.com/kamens/jQuery-menu-aim.
 * For a detailed discussion of the implementation see
 * http://bjk5.com/post/44698559168/breaking-down-amazons-mega-dropdown
 */

export interface IDropdownMouseLocation {
	x: number;
	y: number;
}

const MOUSE_LOCS_TRACKED = 3;
const DELAY = 300;

@Directive({
	selector: '[rdDropdown]'
})
export class DropdownDirective implements AfterContentInit {
	@ContentChildren('dropdown',{descendants: true})
	dropdownChildren: QueryList<any>;
	@ContentChildren('dropdownTrigger', {descendants: true})
	triggerChildren: QueryList<any>;

	activeDropdown: Element | null = null;
	mouseLocations: IDropdownMouseLocation[] = [];
	lastDelayLocation: IDropdownMouseLocation | null = null;
	timeoutId: any = null;

	constructor(private _elementRef: ElementRef<HTMLElement>, private router: Router) {
		this.router.events.subscribe(() => {
			this.deactivate();
		});
	}


	ngAfterContentInit() {
		this.triggerChildren.changes.subscribe(changes => {
			this._addTriggerEventListeners(changes);
		});
		this._addTriggerEventListeners(this.triggerChildren);

	}

	private _addTriggerEventListeners(triggers: QueryList<ElementRef<HTMLElement>>) {
		this.triggerChildren.forEach(trigger => {

			trigger.nativeElement.addEventListener('mouseenter', (e: any) => {
				if (this.timeoutId) {
					clearTimeout(this.timeoutId);
				}
				const target = e.target as HTMLElement;
				this.possiblyActivate(target!);
			});
			trigger.nativeElement.addEventListener('click', () => this.activate(trigger.nativeElement));
		});
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
		if (this.activeDropdown) {
			this.timeoutId = setTimeout(() => {
				this.deactivate();
				this.activeDropdown = null;
			}, DELAY);
		}
	}

	@HostListener('document:mousemove', ['$event.pageX', '$event.pageY'])
	onMouseMove(x: number, y: number) {
		this.mouseLocations.push({ x, y });
		if (this.mouseLocations.length > MOUSE_LOCS_TRACKED) {
			this.mouseLocations.shift();
		}
	}

	possiblyActivate(dropdown: Element) {
		const delay = this.activationDelay();
		if (delay) {
			this.timeoutId = setTimeout(() => {
				this.possiblyActivate(dropdown);
			}, delay);
		} else {
			this.activate(dropdown);
		}
	}

	activate(dropdown: Element) {
		if (dropdown === this.activeDropdown) {
			return;
		}
		if (this.activeDropdown) {
			this.deactivate();
		}
		this.toggleDropdown(dropdown, true);
		this.activeDropdown = dropdown;
	}

	deactivate() {
		if (!this.activeDropdown) {
			return;
		}
		this.toggleDropdown(this.activeDropdown, false);
	}

	activationDelay() {
		if (!this.activeDropdown) {
			return 0;
		}

		const tolerance = 75;
		const native = this._elementRef.nativeElement;
		const { height: menuHeight, width: menuWidth } = native.getBoundingClientRect();
		const lowerLeft = {
				x: native.offsetLeft,
				y: native.offsetTop + menuHeight + tolerance
			},
			lowerRight = {
				x: native.offsetLeft + menuWidth,
				y: lowerLeft.y
			},
			location = this.mouseLocations[this.mouseLocations.length - 1];

		let prevLocation = this.mouseLocations[0];

		if (!location) {
			return 0;
		}

		if (!prevLocation) {
			prevLocation = location;
		}

		if (
			prevLocation.x < native.offsetLeft ||
			prevLocation.x > lowerRight.x ||
			prevLocation.y < native.offsetTop ||
			prevLocation.y > lowerRight.y
		) {
			return 0;
		}

		if (this.lastDelayLocation && location.x === this.lastDelayLocation.x && location.y === this.lastDelayLocation.y) {
			return 0;
		}

		const slope = (a: IDropdownMouseLocation, b: IDropdownMouseLocation) => (b.y - a.y) / (b.x - a.x);

		const decreasingCorner = lowerRight,
			increasingCorner = lowerLeft;

		const decreasingSlope = slope(location, decreasingCorner),
			increasingSlope = slope(location, increasingCorner),
			prevDecreasingSlope = slope(prevLocation, decreasingCorner),
			prevIncreasingSlope = slope(prevLocation, increasingCorner);

		if (decreasingSlope < prevDecreasingSlope && increasingSlope > prevIncreasingSlope) {
			this.lastDelayLocation = location;
			return DELAY;
		}

		this.lastDelayLocation = null;
		return 0;
	}

	protected toggleDropdown(dropdown: Element | null, show: boolean) {
		const index = this.triggerChildren
			.toArray()
			.map(ref => ref.nativeElement)
			.findIndex(el => el === dropdown);
		const classList = this.dropdownChildren.toArray()[index].nativeElement.classList;
		if (show) {
			classList.add('show');
		} else {
			classList.remove('show');
		}
	}
}
