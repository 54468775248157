import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';

import { OrgService } from '@app/org/org.service';

@Component({
	selector: 'rd-admin-abs-summary-filters',
	templateUrl: './admin-abs-summary-filters.component.html'
})
export class AdminAbsSummaryFiltersComponent implements OnInit, OnChanges {
	@Input()
	defaults: SummaryFilters;

	@Output()
	update = new EventEmitter<SummaryFilters>();

	orgs: any;
	form: any = {
		org_id: null,
		comparison_schools: null
	};

	constructor(private orgService: OrgService) {}

	ngOnInit() {
		this.orgService.listAll().then((data: any) => (this.orgs = data.items));
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.defaults) {
			this.initDefaults();
		}
	}

	initDefaults() {
		this.form.org_id = this.defaults.org_id || undefined;
		this.form.comparison_schools = this.defaults.comparison_schools || undefined;
	}

	onOrgChanged() {
		this.orgService.getOrgWatchlist(this.form.org_id).then((data: any) => (this.form.comparison_schools = data));
	}

	sendUpdate() {
		this.update.emit(this.form);
	}

	reset() {
		this.form = {
			org_id: null,
			comparison_schools: null
		};
		this.sendUpdate();
	}
}

export interface SummaryFilters {
	org_id?: number;
	comparison_schools?: number[];
}
