import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { CmsEntryResultAbsSubmit } from '@app/cms/results/cms-entry-result-abs-submit';

@Component({
	selector: 'rd-abs-submit-field',
	templateUrl: './abs-submit-field.component.html',
	styleUrls: ['./abs-submit-field.component.scss']
})
export class AbsSubmitFieldComponent implements OnInit {
	@Input()
	cmsResult: CmsEntryResultAbsSubmit;

	@Input()
	field: string;

	@Input()
	control: FormControl;

	@Input()
	showLastYear = true;

	@Input()
	lastYearValue: string;

	@Input()
	isReadonly = false;

	@Input()
	fieldType: string;

	constructor() {}

	helpText: string;

	ngOnInit() {
		this.helpText = this.cmsResult.getHelpText(this.field);
		if (!this.lastYearValue) {
			const key = `previous_${this.field}` as keyof CmsEntryResultAbsSubmit;
			this.lastYearValue = this.cmsResult[key];
		}
	}

	toggleHelp() {
		this.cmsResult.activeInput = this.field;
	}
}
