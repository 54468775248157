import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CmsEntryService } from '@app/cms/cms-entry.service';
import { AccountService } from '@app/account/account.service';
import { Account } from '@app/account/account';
import { FunnelEntryService } from '@app/funnel/funnel-entry.service';
import { AbsEntryService } from '@app/cms/abs-submit/abs-entry.service';
import { CmsEntryResultHomepage } from '@app/cms/results/cms-entry-result-homepage';
import { environment } from '@env/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	navigation: any = [];
	navigationIncludingHidden: any = [];
	now: Date = new Date();
	urls: any = {};
	sessionUser: Account;
	environment = environment;
	funnelReport: any;
	absLatestReport: any;
	absOpenReport: any;
	homepageEntry: CmsEntryResultHomepage;
	isMenuExpanded = false;

	constructor(
		private cmsEntryService: CmsEntryService,
		private accountService: AccountService,
		private funnelEntryService: FunnelEntryService,
		private absEntryService: AbsEntryService,
		private router: Router
	) {
		this.cmsEntryService.navigation$.subscribe((navigation: any) => {
			this.navigation = navigation;
		});
		this.cmsEntryService.navigationIncludingHidden$.subscribe((navigationIncludingHidden: any) => {
			this.navigationIncludingHidden = navigationIncludingHidden;
		});
		this.accountService.loggedIn.subscribe(async (loggedIn: boolean) => {
			this.sessionUser = await this.accountService.getSessionUser();
		});
		this.router.events.subscribe(() => {
			this.isMenuExpanded = false;
		});
	}

	async ngOnInit() {
		this.cmsEntryService.getSiteNavigation();
		this.cmsEntryService.getSiteNavigationIncludingHidden();
		this.cmsEntryService.getSiteUrlMap().then(urls => (this.urls = urls));
		let entry;
		[this.sessionUser, this.funnelReport, this.absOpenReport, this.absLatestReport, entry] = await Promise.all([
			this.accountService.getSessionUser(),
			this.funnelEntryService.getLatestOpenReport(),
			this.absEntryService.getOpenReport(),
			this.absEntryService.getLatestOpenReport(),
			this.cmsEntryService.getEntryByUrl('/index')
		]);
		if (entry instanceof CmsEntryResultHomepage) {
			this.homepageEntry = entry;
		}
	}

	logout() {
		this.accountService.logout().then(() => {
			window.location.reload();
		});
	}

	navigate(url: string) {
		this.router.navigate([url]);
	}

	toggleMobileNav() {
		this.isMenuExpanded = !this.isMenuExpanded;
	}
}
