<rd-abs-submit-wrapper
	[cmsResult]="cmsResult"
	subtitle="Part A: {{ cmsResult.report.year - 1 }}-{{ cmsResult.report.year }} Budget"
>
	<rd-abs-submit-save-messages [show]="hasReadPermission() && (isSaved || cmsResult.existingStatus > 0)" [status]="cmsResult.existingStatus" [url]="cmsResult.urls['abs-landing']"></rd-abs-submit-save-messages>
	<div class="before__grid" *ngIf="hasReadPermission() && !isSaved">
		<rd-alert *ngIf="hasWritePermission()" type="info"><p>Note: All dollar values will be rounded to the nearest dollar. You may enter commas if you wish, but they are not required.</p></rd-alert>
	</div>
	<ng-container *ngIf="hasReadPermission() && !isSaved" [formGroup]="form">
		<div class="grid__row column--labels">
			<div class="column--labels__last--year grid__col grid__col--3 grid__col-offset--8">
				Last Year
			</div>
		</div>
		<rd-abs-submit-field
			class="input--currency"
			field="a_prof_salaries"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_prof_salaries">
			<input rdCurrencyInput type="text" name="a_prof_salaries" formControlName="a_prof_salaries" maxlength="9" (focus)="cmsResult.activeInput = 'a_prof_salaries'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_support_salaries"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_support_salaries">
			<input rdCurrencyInput type="text" name="a_support_salaries" formControlName="a_support_salaries" maxlength="9" (focus)="cmsResult.activeInput = 'a_support_salaries'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			[isReadonly]="true"
			field="a_fringe_benefit"
			[showLastYear]="false"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_fringe_benefit">
			<div (click)="cmsResult.activeInput = 'a_fringe_benefit'">$ {{ form.controls.a_fringe_benefit.value|currency:0 }}</div>
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_student_wages"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_student_wages">
			<input rdCurrencyInput type="text" name="a_student_wages" formControlName="a_student_wages" maxlength="9" (focus)="cmsResult.activeInput = 'a_student_wages'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			[isReadonly]="true"
			field="a_salary_subtotal"
			[showLastYear]="false"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_salary_subtotal">
			<div (click)="cmsResult.activeInput = 'a_salary_subtotal'">$ {{ form.controls.a_salary_subtotal.value|currency:0 }}</div>
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_advertising"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_advertising">
			<input rdCurrencyInput type="text" name="a_advertising" formControlName="a_advertising" maxlength="9" (focus)="cmsResult.activeInput = 'a_advertising'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_publications"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_publications">
			<input rdCurrencyInput type="text" name="a_publications" formControlName="a_publications" maxlength="9" (focus)="cmsResult.activeInput = 'a_publications'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_elec_media"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_elec_media">
			<input rdCurrencyInput type="text" name="a_elec_media" formControlName="a_elec_media" maxlength="9" (focus)="cmsResult.activeInput = 'a_elec_media'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_direct_mail"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_direct_mail">
			<input rdCurrencyInput type="text" name="a_direct_mail" formControlName="a_direct_mail" maxlength="9" (focus)="cmsResult.activeInput = 'a_direct_mail'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_travel"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_travel">
			<input rdCurrencyInput type="text" name="a_travel" formControlName="a_travel" maxlength="9" (focus)="cmsResult.activeInput = 'a_travel'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_oncampus_ent"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_oncampus_ent">
			<input rdCurrencyInput type="text" name="a_oncampus_ent" formControlName="a_oncampus_ent" maxlength="9" (focus)="cmsResult.activeInput = 'a_oncampus_ent'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_phone"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_phone">
			<input rdCurrencyInput type="text" name="a_phone" formControlName="a_phone" maxlength="9" (focus)="cmsResult.activeInput = 'a_phone'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_postage"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_postage">
			<input rdCurrencyInput type="text" name="a_postage" formControlName="a_postage" maxlength="9" (focus)="cmsResult.activeInput = 'a_postage'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_office_supplies"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_office_supplies">
			<input rdCurrencyInput type="text" name="a_office_supplies" formControlName="a_office_supplies" maxlength="9" (focus)="cmsResult.activeInput = 'a_office_supplies'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_outside_assistance"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_outside_assistance">
			<input rdCurrencyInput type="text" name="a_outside_assistance" formControlName="a_outside_assistance" maxlength="9" (focus)="cmsResult.activeInput = 'a_outside_assistance'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_athletics_recruiting"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_athletics_recruiting">
			<input rdCurrencyInput type="text" name="a_athletics_recruiting" formControlName="a_athletics_recruiting" maxlength="9" (focus)="cmsResult.activeInput = 'a_athletics_recruiting'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_crm"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_crm">
			<input rdCurrencyInput type="text" name="a_crm" formControlName="a_crm" maxlength="9" (focus)="cmsResult.activeInput = 'a_crm'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
				class="input--currency"
				field="a_professional_development"
				[showLastYear]="true"
				fieldType="currency"
				[cmsResult]="cmsResult"
				[control]="form.controls.a_professional_development">
			<input rdCurrencyInput type="text" name="a_professional_development" formControlName="a_professional_development" maxlength="9" (focus)="cmsResult.activeInput = 'a_professional_development'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
				class="input--currency"
				field="a_swag_giveaways"
				[showLastYear]="true"
				fieldType="currency"
				[cmsResult]="cmsResult"
				[control]="form.controls.a_swag_giveaways">
			<input rdCurrencyInput type="text" name="a_swag_giveaways" formControlName="a_swag_giveaways" maxlength="9" (focus)="cmsResult.activeInput = 'a_swag_giveaways'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			class="input--currency"
			field="a_other_budget"
			[showLastYear]="true"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_other_budget">
			<input rdCurrencyInput type="text" name="a_other_budget" formControlName="a_other_budget" maxlength="9" (focus)="cmsResult.activeInput = 'a_other_budget'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			*ngIf="form.controls.a_other_budget.value > 0"
			field="a_other_breakdown"
			[showLastYear]="false"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_other_breakdown">
			<textarea rows="5" name="a_other_breakdown" formControlName="a_other_breakdown" (focus)="cmsResult.activeInput = 'a_other_breakdown'" (blur)="cmsResult.activeInput = ''"></textarea>
		</rd-abs-submit-field>
		<rd-abs-submit-field
			[isReadonly]="true"
			field="a_nonsalary_subtotal"
			[showLastYear]="false"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_nonsalary_subtotal">
			<div (click)="cmsResult.activeInput = 'a_nonsalary_subtotal'">$ {{ form.controls.a_nonsalary_subtotal.value|currency:0 }}</div>
		</rd-abs-submit-field>
		<rd-abs-submit-field
			[isReadonly]="true"
			field="a_grand_total"
			[showLastYear]="false"
			fieldType="currency"
			[cmsResult]="cmsResult"
			[control]="form.controls.a_grand_total">
			<div (click)="cmsResult.activeInput = 'a_grand_total'">$ {{ form.controls.a_grand_total.value|currency:0 }}</div>
		</rd-abs-submit-field>

		<rd-alert type="danger" *ngIf="error && error.message">
			<h4>Error</h4>
			<p>{{ error.message }}</p>
		</rd-alert>


		<ng-container *ngIf="canSubmit()">
			<p>&nbsp;</p>
			<p>Click the button below to save this part of the form and move on to Part B. You can always return to this part of the form to make adjustments before submitting your final data. When you are satisfied with all data fields and ready to submit to NACCAP for validation, click through to Part D, where you’ll find the Submit Final Data button. You will not be able to alter your data for this study after final submission.</p>
			<p><input class="btn btn--gold" type="submit" name="submit" tabindex="16" [disabled]="isSubmitting" value="Save and Continue" (click)="save()" /> </p>
		</ng-container>

		<rd-abs-submit-comment part="a" [entryId]="cmsResult.entryId" [user]="sessionUser" [cmsResult]="cmsResult"></rd-abs-submit-comment>
	</ng-container>
	<rd-alert type="warning" *ngIf="!hasReadPermission()">
		<h4>Your Data for this Study Has Not Been Validated</h4>
		<p>You cannot view other schools' data unless your own data has been validated.</p>
	</rd-alert>
</rd-abs-submit-wrapper>
