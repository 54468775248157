<div class="funnel--summary">
	<rd-funnel-report-filter
		[reports]="cmsResult.completedReports"
		[includeSearch]="false"
		[defaults]="defaults"
		(update)="onFilterUpdate($event)"
	></rd-funnel-report-filter>
	
	<div class="card">
		<div class="card__content card__content--padded">
			<rd-alert *ngIf="!cmsResult.report.isOpen && sessionUser.isAdmin" type="success">
				<h4>Summary Complete</h4>
				App report summary has been finalized, and is now visible to eligible schools. Below is the final summary data.
			</rd-alert>
			<rd-alert type="info" *ngIf="cmsResult.report.doc_url">
				<h4>Related Documents Now Available</h4>
				<a href="{{ cmsResult.report.doc_url | fullResourcePath }}" target="_blank">{{ cmsResult.report.doc_url | filename }}</a>
			</rd-alert>
			<div class="grid__row">
				<div class="grid__col--6">
					<h3>
						<span *ngIf="!cmsResult.report.isFinalReport">{{ cmsResult.report.monthLabel }} {{ cmsResult.report.year }}
						vs
						{{ cmsResult.report.monthLabel }} {{ cmsResult.report.year - 1 }}</span>
						<span *ngIf="cmsResult.report.isFinalReport">Fall {{ cmsResult.report.year }} Enrollment Year, Final Report</span>
					</h3>
					<h4 *ngIf="!cmsResult.report.isFinalReport">Fall {{ cmsResult.report.reportYear }} Enrollment Year, {{ cmsResult.report.periodLabel }} Report</h4>
					<h4>Open for submission: {{ cmsResult.report.open|date:'mediumDate':'utc' }} to {{ cmsResult.report.close|date:'mediumDate':'utc' }}</h4>
				</div>
				
				<!-- First-Year -->
				<rd-funnel-summary-section
					[cmsResult]="cmsResult"
					title="First-Year Student Applications"
					field="fr_app"
					type="applicants"
				></rd-funnel-summary-section>
				
				<rd-funnel-summary-section
					[cmsResult]="cmsResult"
					title="First-Year Student Admits"
					field="fr_adm"
					type="admits"
				></rd-funnel-summary-section>
				
				<rd-funnel-summary-section
					[cmsResult]="cmsResult"
					title="First-Year Student Deposits"
					field="fr_dep"
					type="deposits"
				></rd-funnel-summary-section>
				
				<rd-funnel-summary-section
					*ngIf="cmsResult.report.isFinalReport"
					[cmsResult]="cmsResult"
					title="First-Year Student Enrollments"
					field="fr_enr"
					type="enrollments"
				></rd-funnel-summary-section>
				
				<!-- Transfer -->
				<rd-funnel-summary-section
					[cmsResult]="cmsResult"
					title="Transfer Student Applications"
					field="tr_app"
					type="applicants"
				></rd-funnel-summary-section>
				
				<rd-funnel-summary-section
					[cmsResult]="cmsResult"
					title="Transfer Student Admits"
					field="tr_adm"
					type="admits"
				></rd-funnel-summary-section>
				
				<rd-funnel-summary-section
					[cmsResult]="cmsResult"
					title="Transfer Student Deposits"
					field="tr_dep"
					type="deposits"
				></rd-funnel-summary-section>
				
				<rd-funnel-summary-section
					*ngIf="cmsResult.report.isFinalReport"
					[cmsResult]="cmsResult"
					title="Transfer Student Enrollments"
					field="tr_enr"
					type="enrollments"
				></rd-funnel-summary-section>
				
				<!-- Total -->
				<rd-funnel-summary-section
					[cmsResult]="cmsResult"
					title="Total New Student Applications"
					field="tns_app"
					type="applicants"
				></rd-funnel-summary-section>
				
				<rd-funnel-summary-section
					[cmsResult]="cmsResult"
					title="Total New Student Admits"
					field="tns_adm"
					type="admits"
				></rd-funnel-summary-section>
				
				<rd-funnel-summary-section
					[cmsResult]="cmsResult"
					title="Total New Student Deposits"
					field="tns_dep"
					type="deposits"
				></rd-funnel-summary-section>
				
				<rd-funnel-summary-section
					*ngIf="cmsResult.report.isFinalReport"
					[cmsResult]="cmsResult"
					title="Total New Student Enrollments"
					field="tns_enr"
					type="enrollments"
				></rd-funnel-summary-section>
				
				<hr class="hr hr--shadow">
					
				<div *ngIf="cmsResult.report.isFinalReport" class="grid__row">
					<div class="grid__col--6">
						<h3>Fall {{ cmsResult.report.year }} Actual New Student Enrollment vs. Fall {{ cmsResult.report.year }} Goal</h3>
						<table class="table table--striped" id="data-tns_app">
							<thead>
								<tr>
									<th>Result</th>
									<th><abbr title="Number of schools reporting their final enrollment compared to the previous year">#</abbr></th>
									<th><abbr title="Percentage of schools reporting this result">% of Total</abbr></th>
									<th><abbr title="Minimum and maximum discrete values for this result">Range</abbr></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th><abbr title="Schools reporting that they exceeded their {{ cmsResult.report.year }} enrollment goal">More</abbr></th>
									<td>{{ cmsResult.summaryData.vs_goal_more }}</td>
									<td>{{ (cmsResult.summaryData.vs_goal_more / cmsResult.summaryData.responses * 100)|number:'0.2-2' }}%</td>
									<td>{{ cmsResult.summaryData.vs_goal_more_min }} to {{ cmsResult.summaryData.vs_goal_more_max }}</td>
								</tr>
								<tr>
									<th><abbr title="Schools reporting that they hit their {{ cmsResult.report.year }} enrollment goal on the nose">Even</abbr></th>
									<td>{{ cmsResult.summaryData.vs_goal_even }}</td>
									<td>{{ (cmsResult.summaryData.vs_goal_even / cmsResult.summaryData.responses * 100)|number:'0.2-2' }}%</td>
									<td>&nbsp;</td>
								</tr>
								<tr>
									<th><abbr title="Schools reporting that they plan did not meet their {{ cmsResult.report.year }} enrollment goal">Fewer</abbr></th>
									<td>{{ cmsResult.summaryData.vs_goal_less }}</td>
									<td>{{ (cmsResult.summaryData.vs_goal_less / cmsResult.summaryData.responses * 100)|number:'0.2-2' }}%</td>
									<td>{{ cmsResult.summaryData.vs_goal_less_min }} to {{ cmsResult.summaryData.vs_goal_less_max }}</td>
								</tr>
								<tr>
									<th><abbr title="Schools that did not report goal data">No Goal Listed</abbr></th>
									<td>{{ cmsResult.summaryData.vs_goal_no_goal }}</td>
									<td>{{ (cmsResult.summaryData.vs_goal_no_goal / cmsResult.summaryData.responses * 100)|number:'0.2-2' }}%</td>
									<td>&nbsp;</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="grid__col grid__col--5 grid__col-offset--1">
						<rd-chart [id]="cmsResult.report.id" [base]="cmsResult.getChartDataByField('vs_goal')" type="pie"></rd-chart>
					</div>
				
					<div class="extremeStats" *ngIf="cmsResult.report.isFinalReport">
						<p>
							<b>Largest % Increase:</b> <span>{{ (cmsResult.summaryData.vs_goal_max_margin * 100)|number:'0.2-2' }}%</span>
						</p>
						<p>
							<b>Largest % Decrease:</b> <span>{{ (cmsResult.summaryData.vs_goal_max_deficit * 100)|number:'0.2-2' }}%</span>
						</p>
					</div>
				</div>
				
				<div *ngIf="!cmsResult.report.isFinalReport" class="grid__row">
					<div class="grid__col grid__col--6">
						<h3>Fall {{ cmsResult.report.reportYear }} New Student Enrollment Goal vs. Fall {{ cmsResult.report.reportYear - 1 }} Actual</h3>
						<table class="table table--striped" id="data-tns_app">
							<thead>
								<tr>
									<th>Result</th>
									<th><abbr title="Number of schools reporting their final enrollment compared to the previous year">#</abbr></th>
									<th><abbr title="Percentage of schools reporting this result">% of Total</abbr></th>
									<th><abbr title="Minimum and maximum discrete values for this result">Range</abbr></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th><abbr title="Schools reporting that they plan to enroll more than they did the previous year">Enroll More</abbr></th>
									<td>{{ cmsResult.summaryData.vs_goal_more }}</td>
									<td>{{ (cmsResult.summaryData.vs_goal_more / cmsResult.summaryData.responses * 100)|number:'0.2-2' }}%</td>
									<td>{{ cmsResult.summaryData.vs_goal_more_min }} to {{ cmsResult.summaryData.vs_goal_more_max }}</td>
								</tr>
								<tr>
									<th><abbr title="Schools reporting that they plan to enroll the same number of students as the previous year">Remain Even</abbr></th>
									<td>{{ cmsResult.summaryData.vs_goal_even }}</td>
									<td>{{ (cmsResult.summaryData.vs_goal_even / cmsResult.summaryData.responses * 100)|number:'0.2-2' }}%</td>
									<td>&nbsp;</td>
								</tr>
								<tr>
									<th><abbr title="Schools reporting that they plan to enroll less than they did previous year">Enroll Fewer</abbr></th>
									<td>{{ cmsResult.summaryData.vs_goal_less }}</td>
									<td>{{ (cmsResult.summaryData.vs_goal_less / cmsResult.summaryData.responses * 100)|number:'0.2-2' }}%</td>
									<td>{{ cmsResult.summaryData.vs_goal_less_min }} to {{ cmsResult.summaryData.vs_goal_less_max }}</td>
								</tr>
								<tr>
									<th><abbr title="Schools that did not report goal data">No Goal Listed</abbr></th>
									<td>{{ cmsResult.summaryData.vs_goal_no_goal }}</td>
									<td>{{ (cmsResult.summaryData.vs_goal_no_goal / cmsResult.summaryData.responses * 100)|number:'0.2-2' }}%</td>
									<td>&nbsp;</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="grid__col grid__col--5 grid__col-offset--1">
						<rd-chart [id]="cmsResult.report.id" [base]="cmsResult.getChartDataByField('vs_goal')" type="pie"></rd-chart>
					</div>
				</div>
			</div> 
		</div> <!-- end of card__content -->
	</div> 
</div> <!-- end of funnel--summary -->