import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { get } from 'lodash';

import { CmsEntryResultAbsSubmit } from '@app/cms/results/cms-entry-result-abs-submit';
import { Account } from '@app/account/account';
import { AbsSubmitPartHelper } from './abs-submit-part-helper';
import { parseCurrency } from '@app/core/currency-input.directive';
import * as moment from 'moment';

@Component({
	selector: 'rd-cms-entry-abs-submit-part-a',
	templateUrl: './cms-entry-abs-submit-part-a.component.html'
})
export class CmsEntryAbsSubmitPartAComponent extends AbsSubmitPartHelper implements OnInit {
	@Input()
	cmsResult: CmsEntryResultAbsSubmit;

	@Input()
	sessionUser: Account;

	fringeAllowance = 0.3;

	ngOnInit() {
		this.orgId = this.cmsResult.orgId || this.sessionUser.orgId;
		const currencyValidators = [Validators.max(8000000), Validators.maxLength(9), Validators.pattern(/^[0-9]*$/)];
		this.form = this.formBuilder.group({
			a_prof_salaries: [this.cmsResult.a_prof_salaries, currencyValidators],
			a_support_salaries: [this.cmsResult.a_support_salaries, currencyValidators],
			a_fringe_benefit: { value: this.cmsResult.a_fringe_benefit, disabled: true },
			a_student_wages: [this.cmsResult.a_student_wages, currencyValidators],
			a_salary_subtotal: { value: this.cmsResult.a_salary_subtotal, disabled: true },
			a_advertising: [this.cmsResult.a_advertising, currencyValidators],
			a_publications: [this.cmsResult.a_publications, currencyValidators],
			a_elec_media: [this.cmsResult.a_elec_media, currencyValidators],
			a_direct_mail: [this.cmsResult.a_direct_mail, currencyValidators],
			a_travel: [this.cmsResult.a_travel, currencyValidators],
			a_oncampus_ent: [this.cmsResult.a_oncampus_ent, currencyValidators],
			a_phone: [this.cmsResult.a_phone, currencyValidators],
			a_postage: [this.cmsResult.a_postage, currencyValidators],
			a_office_supplies: [this.cmsResult.a_office_supplies, currencyValidators],
			a_outside_assistance: [this.cmsResult.a_outside_assistance, currencyValidators],
			a_athletics_recruiting: [this.cmsResult.a_athletics_recruiting, currencyValidators],
			a_crm: [this.cmsResult.a_crm, currencyValidators],
			a_professional_development: [this.cmsResult.a_professional_development, currencyValidators],
			a_other_budget: [this.cmsResult.a_other_budget, currencyValidators],
			a_other_breakdown: [this.cmsResult.a_other_breakdown],
			a_nonsalary_subtotal: {
				value: this.cmsResult.a_nonsalary_subtotal,
				disabled: true,
				validators: [Validators.max(8000000)]
			},
			a_grand_total: { value: this.cmsResult.a_grand_total, disabled: true, validators: [Validators.max(16777215)] },
			// Part C Items
			c_pro_staff_avg_sal: { value: this.cmsResult.c_pro_staff_avg_sal, disabled: true },
			c_support_staff_avg_sal: { value: this.cmsResult.c_support_staff_avg_sal, disabled: true },
			c_stud_labor_avg_wage: { value: '', disabled: true },
			a_swag_giveaways: [this.cmsResult.a_swag_giveaways],
		});

		if (!this.hasWritePermission()) {
			this.form.disable();
		}

		this.updateTotals('a_fringe_benefit', ['a_prof_salaries', 'a_support_salaries'], (total: number) => {
			return Math.round(total * this.fringeAllowance);
		});
		this.updateTotals('a_salary_subtotal', [
			'a_prof_salaries',
			'a_support_salaries',
			'a_fringe_benefit',
			'a_student_wages'
		]);
		this.updateTotals('a_nonsalary_subtotal', [
			'a_advertising',
			'a_publications',
			'a_elec_media',
			'a_direct_mail',
			'a_travel',
			'a_oncampus_ent',
			'a_phone',
			'a_postage',
			'a_office_supplies',
			'a_other_budget',
			'a_outside_assistance',
			'a_athletics_recruiting',
			'a_crm',
			'a_professional_development',
			'a_swag_giveaways'
		]);
		this.updateTotals('a_grand_total', ['a_salary_subtotal', 'a_nonsalary_subtotal']);

		// Update Part C items that are based on Part A items
		this.updateTotals('c_pro_staff_avg_sal', ['a_prof_salaries'], (totals: number) => {
			if (!this.cmsResult.c_pro_staff) {
				return 0;
			}
			return Math.round(+this.form.controls.a_prof_salaries.value / parseFloat(this.cmsResult.c_pro_staff));
		});
		this.updateTotals('c_support_staff_avg_sal', ['a_support_salaries'], (totals: number) => {
			if (!this.cmsResult.c_support_staff) {
				return 0;
			}
			totals = Math.round(+this.form.controls.a_support_salaries.value / parseFloat(this.cmsResult.c_support_staff));
			return isNaN(totals) ? 0 : totals;
		});
		this.updateTotals('c_stud_labor_avg_wage', ['a_student_wages'], (totals: number) => {
			if (!this.cmsResult.c_stud_labor) {
				return 0;
			}
			totals = +this.form.controls.a_student_wages.value / (30 * parseFloat(this.cmsResult.c_stud_labor));
			return isNaN(totals) ? 0 : totals;
		});

		this.listenForFormChanges();
	}

	updateTotals(updateField: string, fields: string[], tweakTotal: Function | null = null): void {
		const _updateTotals = () => {
			let total = 0;
			fields.forEach((field: string) => {
				total += parseCurrency(this.form.controls[field].value || 0);
			});
			if (tweakTotal) {
				total = tweakTotal(total);
			} else {
				total = Math.round(total);
			}
			this.form.controls[updateField].setValue(total);
		};
		fields.forEach((field: string) => {
			this.form.controls[field].valueChanges.subscribe(_updateTotals);
		});
		_updateTotals(); // trigger an update just in case legacy data wasn't saved properly
	}

	async save(): Promise<any> {
		if (this.form!.invalid) {
			this.error = { message: 'Please fill out all required fields, fix any errors and try again.' };
			return;
		}
		if (this.isSubmitting) {
			return;
		}

		const data = this.form.getRawValue();
		if (!this.sessionUser.isAdmin) {
			data.status = 0; // status should always be 0 unless an admin is saving, in which case we keep the status unchanged
		}
		data.naccap_abs_surveys_id = this.cmsResult.report.id;
		data.naccap_orgs_id = this.cmsResult.orgId || this.sessionUser.orgId;
		data.user_id = this.sessionUser.userId;
		data.a_last_user = this.sessionUser.userId;
		data.a_last_update = moment().format('YYYY-MM-DD HH:mm:ss');
		const entryId = this.cmsResult.entryId;
		if (entryId) {
			data.id = entryId;
		}
		this.isSubmitting = true;
		this.error = {};
		try {
			if (data.id) {
				await this.absEntryService.update(data);
			} else {
				const result = await this.absEntryService.create(data);
				this.form.patchValue({ id: result.insertId });
			}
			this.isSaved = true;
			this.onSaveSuccess();
		} catch (err) {
			if (err && typeof err === 'object' && 'error' in err) {
				const errorObject = err as { error: { error: string } };
				this.error = errorObject.error.error;
			}
		}
		this.isSubmitting = false;
		this.cmsResult.existingStatus = data.status || this.cmsResult.existingStatus || 0;
		setTimeout(() => {
			const url = get(this.cmsResult.urls, 'abs-submit-part-b');
			this.router.navigate([url], { queryParamsHandling: 'merge' });
		}, 2000);
	}
}
