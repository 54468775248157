<div class="grid__row grid__row--striped" [class.subtotal]="isReadonly">
	<label class="grid__col grid__col--5 budget--categories">
		<div [innerHtml]="cmsResult.getLabel(field)"></div>
		<ng-content select=".label__footer"></ng-content>
	</label>
	<div class="grid__col grid__input" [class.grid__col--3]="showLastYear" [class.grid__col--6]="!showLastYear">
		<ng-content></ng-content>
	</div>
	<div class="grid__col grid__col--3 last--year" *ngIf="showLastYear">
		<span *ngIf="fieldType == 'currency' && lastYearValue">$ {{ lastYearValue | currency:0 }}</span>
		<span *ngIf="fieldType != 'currency'">{{ lastYearValue }}</span>
	</div>
	<a *ngIf="helpText" class="grid__col grid__col--1 help__icon" (click)="toggleHelp()">
		<svg viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<title>Question</title>
			<g id="Question" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="Group-2" transform="translate(5.000000, 5.000000)">
					<circle id="question-oval" [class.question__oval--active]="cmsResult.activeInput == field" fill="#C8C8C8" cx="14" cy="14" r="14"></circle>
					<path d="M14.6941053,7.76631579 C15.5881448,7.76631579 16.35126,7.90999856 16.9834737,8.19736842 C17.6156874,8.48473828 18.091437,8.87108529 18.4107368,9.35642105 C18.7300367,9.84175681 18.8896842,10.3781725 18.8896842,10.9656842 C18.8896842,11.489336 18.7970886,11.936349 18.6118947,12.3067368 C18.4267008,12.6771247 18.2063872,12.977262 17.9509474,13.2071579 C17.6955075,13.4370538 17.3570547,13.7052616 16.9355789,14.0117895 C16.4246992,14.3566333 16.047931,14.6663495 15.8052632,14.9409474 C15.5625953,15.2155452 15.4412632,15.5699627 15.4412632,16.0042105 L15.4412632,16.4831579 L12.6825263,16.4831579 L12.6825263,15.8892632 C12.6825263,15.2889795 12.7751219,14.7813003 12.9603158,14.3662105 C13.1455097,13.9511207 13.3690162,13.6254398 13.6308421,13.3891579 C13.892668,13.152876 14.2215419,12.894247 14.6174737,12.6132632 C15.0006335,12.3578233 15.2816131,12.1343167 15.4604211,11.9427368 C15.639229,11.7511569 15.7286316,11.5148786 15.7286316,11.2338947 C15.7286316,10.8890509 15.6104924,10.6208431 15.3742105,10.4292632 C15.1379286,10.2376833 14.8090547,10.1418947 14.3875789,10.1418947 C13.5829433,10.1418947 12.8357929,10.5314347 12.1461053,11.3105263 L10.2111579,9.81621053 C11.3734093,8.4496072 12.8677102,7.76631579 14.6941053,7.76631579 Z M14.1002105,18.2265263 C14.5727743,18.2265263 14.978279,18.3957527 15.3167368,18.7342105 C15.6551947,19.0726684 15.8244211,19.4781731 15.8244211,19.9507368 C15.8244211,20.4360726 15.6551947,20.8479632 15.3167368,21.1864211 C14.978279,21.5248789 14.5727743,21.6941053 14.1002105,21.6941053 C13.6276468,21.6941053 13.222142,21.5248789 12.8836842,21.1864211 C12.5452264,20.8479632 12.376,20.4360726 12.376,19.9507368 C12.376,19.4781731 12.5452264,19.0726684 12.8836842,18.7342105 C13.222142,18.3957527 13.6276468,18.2265263 14.1002105,18.2265263 Z" id="question-mark" fill="#FFFFFF"></path>
				</g>
			</g>
		</svg>
	</a>
	<div *ngIf="helpText" class="help__card card__content" [class.help__card--inactive]="cmsResult.activeInput != field">
		<h3 [innerHtml]="cmsResult.getLabel(field)"></h3>
		<div [innerHtml]="helpText"></div>
	</div>
</div>
<rd-control-errors [control]="control"></rd-control-errors>