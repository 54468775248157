<rd-alert type="danger" *ngIf="control.touched && control.errors?.required">
	This field is required.
</rd-alert>
<rd-alert type="danger" *ngIf="control.errors?.maxlength">
	The max length for this field is {{ control.errors.maxlength.requiredLength }}.
</rd-alert>
<ng-container *ngIf="control.errors?.pattern">
	<ng-container *ngIf="control.errors.pattern.requiredPattern == '/^\\d+(?:\\.\\d{1,2})?$/'; else defaultPattern">
		<rd-alert *ngIf="control.touched" type="danger">
			Please enter a proper decimal value. You cannot end the value with a period. Please ensure that you've removed the period or added the proper decimal digits. i.e. 1. is invalid, but 1.0 or 1 is valid.
		</rd-alert>
	</ng-container>
	<ng-template #defaultPattern>
		<rd-alert type="danger">
			Please only enter numbers into this field (commas and decimals are acceptable, but will be rounded). i.e. 12,345.67 will be rounded to 12,346.
		</rd-alert>
	</ng-template>
</ng-container>
<rd-alert type="danger" *ngIf="control.errors?.max">
	The max value for this field is {{ control.errors.max.max|currency }}.
</rd-alert>