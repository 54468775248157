import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { uniq, find } from 'lodash';

export interface ReportFilters {
	reportId?: number;
	year?: number;
	search?: string;
}

@Component({
	selector: 'rd-funnel-report-filter',
	templateUrl: './funnel-report-filter.component.html',
	styleUrls: ['./funnel-report-filter.component.css']
})
export class FunnelReportFilterComponent implements OnInit, OnChanges {
	constructor(private formBuilder: FormBuilder) {
		this.filters = this.formBuilder.group({
			search: '',
			report: '',
			year: ''
		});
	}

	@Input()
	reports: any;

	@Input()
	includeSearch = false;

	@Input()
	isAbs = false;

	@Input()
	defaults: ReportFilters;

	@Output()
	update = new EventEmitter<ReportFilters>();

	public filters: FormGroup;

	public reportYears: number[];

	public reportsMap: { [index: number]: any };

	ngOnInit() {
		this.filters
			.get('search')!
			.valueChanges.pipe(debounceTime(250))
			.subscribe(search => {
				console.log('search', search);
				this.sendUpdate();
			});

		this.filters.get('year')!.valueChanges.subscribe(year => {
			if (this.isAbs) {
				this.sendUpdate();
			}
			// TODO: find a matching report for the new year
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.reports) {
			this.initCompletedReportsMap(changes.reports.currentValue);
		}
		if (changes.defaults) {
			if (!this.filters.controls.report.value) {
				if (this.isAbs) {
					this.filters.controls.year.setValue(this.defaults.reportId, { emitEvent: false });
				} else {
					this.filters.controls.year.setValue(this.getReportYear(this.defaults.reportId), { emitEvent: false });
					this.filters.controls.report.setValue(this.defaults.reportId, { emitEvent: false });
				}
			}
			if (!this.filters.controls.search.value) {
				this.filters.controls.search.setValue(this.defaults.search, { emitEvent: false });
			}
		}
	}

	getReportsForYear(year: number): any {
		if (!this.reportsMap) {
			return [];
		}
		return this.reportsMap[year];
	}

	sendUpdate() {
		if (this.isAbs) {
			const search = this.filters.controls.search.value;
			const reportId = this.filters.controls.year.value;
			this.update.emit({ search, reportId });
		} else {
			const search = this.filters.controls.search.value;
			const reportId = this.filters.controls.report.value;
			const year = this.filters.controls.year.value;

			// if placeholder "Select report" is selected, no action
			if (reportId !== 'null') {
				this.update.emit({ search, reportId, year });
			}
		}
	}

	private initCompletedReportsMap(reports: any) {
		if (!reports) {
			return;
		}

		this.reportsMap = {};
		this.reportYears = [];

		reports.forEach((report: any) => {
			const year: number = report.reportYear;
			let map = this.reportsMap[year];
			if (!map) {
				map = [];
			}
			map.push(report);
			this.reportsMap[year] = map;
			this.reportYears.push(year);
		});

		this.reportYears = uniq(this.reportYears);
	}

	private getReportYear(id: number | undefined): number | undefined {
		if (this.isAbs) {
			return this.reports;
		}
		if (!id) {
			return;
		}
		const report = this.getReportById(id);
		return report ? report.reportYear : undefined;
	}

	private getReportById(id: number): any | null {
		return find(this.reports, report => report.id == id);
	}
}
