<rd-abs-submit-wrapper
	[cmsResult]="cmsResult"
	subtitle="Part B Enrollment Data"
>
	<rd-abs-submit-save-messages [show]="hasReadPermission() && (isSaved || cmsResult.existingStatus > 0)" [status]="cmsResult.existingStatus" [url]="cmsResult.urls['abs-landing']"></rd-abs-submit-save-messages>
	<ng-container *ngIf="hasReadPermission() && !isSaved" [formGroup]="form">
		<div class="grid__row column--labels">
			<div class="column--labels__last--year grid__col grid__col--3 grid__col-offset--8">
				Last Year
			</div>
		</div>
		<rd-abs-submit-field
			[class.error]="form.errors?.maleFemale" 
			field="b_total_enr"
			[showLastYear]="true"
			[control]="form.controls.b_total_enr"
			[cmsResult]="cmsResult">
			<input rdNumberInput type="text" name="b_total_enr" formControlName="b_total_enr" maxlength="5" (focus)="cmsResult.activeInput = 'b_total_enr'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			field="b_ftns_fall"
			[showLastYear]="true"
			[control]="form.controls.b_ftns_fall"
			[cmsResult]="cmsResult">
			<input rdNumberInput type="text" name="b_ftns_fall" formControlName="b_ftns_fall" maxlength="5" (focus)="cmsResult.activeInput = 'b_ftns_fall'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			field="b_ftns_spring"
			[showLastYear]="true"
			[control]="form.controls.b_ftns_spring"
			[cmsResult]="cmsResult">
			<input rdNumberInput type="text" name="b_ftns_spring" formControlName="b_ftns_spring" maxlength="5" (focus)="cmsResult.activeInput = 'b_ftns_spring'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>
		<rd-abs-submit-field
			[isReadonly]="true"
			field="b_ftns_total"
			[showLastYear]="false"
			[control]="form.controls.b_ftns_total"
			[cmsResult]="cmsResult">
			<div (click)="cmsResult.activeInput = 'b_ftns_total'">{{ form.controls.b_ftns_total.value }}</div>
		</rd-abs-submit-field>
		<rd-abs-submit-field
			field="b_recruited_athletes"
			[showLastYear]="true"
			[control]="form.controls.b_recruited_athletes"
			[cmsResult]="cmsResult">
			<input [rdNumberInput]="{ allowDecimals: true }" type="text" name="b_recruited_athletes" formControlName="b_recruited_athletes" maxlength="5" (focus)="cmsResult.activeInput = 'b_recruited_athletes'" (blur)="cmsResult.activeInput = ''">
		</rd-abs-submit-field>

		<rd-alert type="warning" *ngIf="form.errors?.maleFemale">
			<h4>Caution</h4>
			<p>The sum of Male and Female students does not equal the Total Enrollment given in the first field. You may still save your data in progress, but please correct this before submitting your final data after Part D, or use the comment box below to explain the discrepancy.</p>
		</rd-alert>

		<rd-alert type="danger" *ngIf="error && error.message">
			<h4>Error</h4>
			<p>{{ error.message }}</p>
		</rd-alert>

		<ng-container *ngIf="canSubmit()">
			<p>&nbsp;</p>
			<p>Click the button below to save this part of the form and move on to Part C. You can always return to this part of the form to make adjustments before submitting your final data. When you are satisfied with all data fields and ready to submit to NACCAP for validation, click through to Part D, where you’ll find the Submit Final Data button. You will not be able to alter your data for this study after final submission.</p>
			<p><input class="btn btn--gold" type="submit" name="submit" tabindex="16" [disabled]="isSubmitting" value="Save and Continue" (click)="save()" /></p>
		</ng-container>

		<rd-abs-submit-comment part="b" [entryId]="cmsResult.entryId" [user]="sessionUser" [cmsResult]="cmsResult"></rd-abs-submit-comment>
	</ng-container>
	<rd-alert type="warning" *ngIf="!hasReadPermission()">
		<h4>Your Data for this Study Has Not Been Validated</h4>
		<p>You cannot view other schools' data unless your own data has been validated.</p>
	</rd-alert>
</rd-abs-submit-wrapper>