import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { combineLatest } from 'rxjs';
import { keyBy } from 'lodash';

import { CmsEntryResult } from '@app/cms/results/cms-entry-result';
import { CmsEntryService } from '@app/cms/cms-entry.service';
import { AccountService } from '@app/account/account.service';
import { Account } from '@app/account/account';

@Component({
	selector: 'rd-cms-entry',
	templateUrl: './cms-entry.component.html'
})
export class CmsEntryComponent implements OnInit {
	cmsResult: CmsEntryResult | null = null;
	sessionUser: Account;
	urls: any;
	breadcrumbs: any;
	navigation: any;
	navigationFlat: any;

	constructor(
		private route: ActivatedRoute,
		private cmsEntryService: CmsEntryService,
		private accountService: AccountService
	) {
		this.accountService.loggedIn.subscribe(async (loggedIn: boolean) => {
			this.sessionUser = await this.accountService.getSessionUser();
		});
		this.cmsEntryService.navigationIncludingHidden$.subscribe((navigation: any) => {
			this.navigation = navigation;
			this.navigationFlat = keyBy(this._flattenNavigation(navigation), 'id');
			this._setBreadcrumbs();
		});
	}

	async ngOnInit(): Promise<void> {
		this.sessionUser = await this.accountService.getSessionUser();
		this.urls = await this.cmsEntryService.getSiteUrlMap();
		combineLatest(this.route.url, this.route.queryParams).subscribe(async ([urlParts, queryParams]) => {
			const url = this.route.snapshot.url.join('/');
			this.cmsResult = await this.getCmsEntry(url, this.route.snapshot.queryParams);
			this._setBreadcrumbs();
		});
	}

	getCmsEntry(url: string, params: Params): Promise<CmsEntryResult> {
		if (url === '') {
			url = '/index';
		}
		if (!params) {
			params = this.route.snapshot.queryParams;
		}
		return this.cmsEntryService.getEntryByUrl(url, params);
	}

	private _flattenNavigation(navigation: any) {
		return navigation.reduce((results: any, item: any) => {
			results.push(item);
			if (item.children) {
				results = results.concat(this._flattenNavigation(item.children));
			}
			return results;
		}, []);
	}

	private _setBreadcrumbs() {
		if (!this.navigation) {
			return;
		}
		if (!this.cmsResult) {
			return;
		}

		const entry = this.navigationFlat[this.cmsResult.entry.id];
		if (!entry) {
			return (this.breadcrumbs = []);
		}

		const _breadcrumbs = [entry];
		const _attachParentEntry = (entry: any, breadcrumbs: any): any => {
			if (!entry.parent_id) {
				return breadcrumbs;
			}
			const parentEntry = this.navigationFlat[entry.parent_id];
			breadcrumbs.unshift(parentEntry);
			return _attachParentEntry(parentEntry, breadcrumbs);
		};
		_attachParentEntry(entry, _breadcrumbs);
		this.breadcrumbs = _breadcrumbs;
	}
}
